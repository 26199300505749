import React, { useContext } from 'react';
import { Modal } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../context';
import { makePatchCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { cookieService } from '../../services';
import { cookieServiceParam } from '../../utils/constants';

interface ConfirmSubscriptionProps {
    isShow: boolean;
    onClose?: () => void;
}

const ConfirmSubscription = (props: ConfirmSubscriptionProps) => {
  const {updateUserData} = useContext(MyContext)
  const onSelect = () =>{
    props?.onClose;
    makePatchCall(endPoints.PROFILE, {account_completed: true})
    .then((res)=>{
      updateUserData(res)
      navigate('/schedule');
      //cookieService.removeCookie('subs_id')
      cookieService.saveCookie(cookieServiceParam.account_completed , true);
    })
    .catch((err)=>{})
  }
  const navigate = useNavigate();
    return (
      <Modal
      centered
      scrollable
      className=" addProductionCrew modal-body-scroll-part"
      show={props?.isShow}
      //onHide={props?.onClose}
     > 
     <div className="d-flex flex-column align-items-center justify-content-center">
        <img src={"/assets/images/success-upgrade.svg"} alt=""/>
        <h5 style={{fontWeight:'600', paddingTop:'2rem'}}> Subscription Active!</h5>
        <p style={{textAlign:'center', marginTop:'10px' }}>Your Sixth Degree subscription is now active</p>
        <button className={'btn btn-warning w-100 with-after-shadow shadow-none'} onClick={()=>{onSelect()}} > Dismiss</button>
      </div>
   </Modal>
    )
}

export default ConfirmSubscription