import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PERMISSION_TYPE, ROLE_TYPE, USER_ACTION_TYPE } from '../../types'
import { getPrmsnText } from '../../utils'
import { buttonsText } from '../../utils/constants'
import Logo from '../../components/commonComponent/ProfileImage'

interface ProfileInfoProps {
    userData?: any
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({ userData }) => {

    const logo = (userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) ? userData?.owner_profile?.company_logo : userData?.supplier_profile?.company_logo
    const name = (userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) ? userData?.owner_profile?.company_name : userData?.supplier_profile?.company_name

    const navigate = useNavigate();

    const handleNavigate = () => {
        const role = (userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) ? 'owner' : 'supplier';
        navigate(`/manage-profile/${role}/${USER_ACTION_TYPE.TYPE_UPDATE}/1`)
    }

    return (
        <div className="card-header bg-transparent border-0">
            <button className="rigth-button perple-button">{getPrmsnText(userData?.permission_type, userData?.user_type)}</button>
            <div className="edit-profile-cont">
                <div className="img-border">
                    {logo ? <img className="logo-icon" src={logo} alt="" />
                        :
                        <Logo style={{ width: '93px', height: '93px' }} data={name?.[0]} />}
                </div>
                <h3>{name || ''}</h3>
                {(userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER || (userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER)) &&
                    <button className="edit-profile-btn" onClick={handleNavigate} ><img src={"/assets/images/edit.svg"} alt="" />{buttonsText.editProfile}</button>
                }
            </div>
        </div>
    )
}

export default ProfileInfo