import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import Button from '../../global/ui/Button';
import { BUTTON_TYPE } from '../../types';
import { emptyFunction } from '../../utils';
import { buttonsText } from '../../utils/constants';

interface ActionModalProps {
    isShow: boolean;
    onClose: () => void;
    onSubmit: () => void;
    title?: string;
    showCloseIcon?: boolean;
    btnTxt?: any;
    isLoading?: boolean;
    children: React.ReactNode;
    bodyStyle?: React.CSSProperties
    isStripeModal?: boolean;
}

const ActionModal = (props: ActionModalProps) => {
    return (
        <Modal
            centered
            scrollable
            show={props.isShow}
            onHide={emptyFunction}>
            {(props?.title || props?.showCloseIcon) &&
                <ModalHeader className="p-0 border-0 text-center mb-3">
                    {props?.showCloseIcon && <button className="close-btn-de shadow-none" onClick={props.onClose}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>}
                    {props?.title && <ModalTitle className="fw-bold mx-auto pt-0 ms-3">{props?.title}</ModalTitle>}
                </ModalHeader>
            }
            <ModalBody style={props.bodyStyle} className="p-0 ">
                {props.children}
            </ModalBody>
            <ModalFooter className="p-0 border-0 mt-4 pt-3">
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt={props?.btnTxt || buttonsText.next}
                    style={{ marginTop: "0px", width: "auto" }}
                    onClick={props.onSubmit}
                    isLoading={props?.isLoading}
                    className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                />
               {props?.isStripeModal ? <div className="stripe-logo"> <img src={"/assets/images/stripe-logo.svg"} alt="" /></div> : ''}
            </ModalFooter>
        </Modal>
    )
}

export default ActionModal