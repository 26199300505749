import React from "react";
interface LoaderProps {
    style?: object;
    imgStyle?: React.CSSProperties
}

const Loader: React.FC<LoaderProps> = ({ style, imgStyle }) => {

    return (
        <div className="lds-ring" style={style}>
            <img style={{ width: "20px", height: "20px", marginTop: '0px', zIndex: 999, ...imgStyle }} src='/assets/images/spinner.gif' alt='loader' />
        </div>
    );
};
export default Loader;
