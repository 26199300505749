import React from 'react'
import { getExpiryFormat } from '../../utils';
import { card } from '../../utils/constants';

interface CardItemsProps {
    item: any;
    onDelete?: () => void;
    onEdit?: () => void;
    onSetDefault?: () => void
}

const CardItems: React.FC<CardItemsProps> = (props) => {
    return (
        <div className='payments-card text-white'>
            <div className='payments-card-header d-flex align-items-center justify-content-between gap-3'>
                {props?.item?.default
                    ?
                    <p className='mb-0'><span></span>{card.defaultCard}</p>
                    :
                    <p className='mb-0 cursor-style' onClick={props?.onSetDefault}>{card.makeDefault}</p>
                }
                {/* <button type="button" onClick={props?.onEdit} className='btn-edit-top'><img src="/assets/images/edit.svg" alt="" /></button> */}
                {!props?.item?.default &&
                    <button
                        type="button"
                        className='btn-delete-only-icon'
                        onClick={props?.onDelete}>
                        <img src="/assets/images/delete-btn-red.svg" alt="" />
                    </button>
                }
            </div>
            <div className='payments-card-number d-flex align-items-center gap-3'>
                <span>****</span>
                <span>****</span>
                <span>****</span>
                <span>{props?.item?.last4}</span>
            </div>
            <div className='payments-card-footer d-flex align-items-center'>
                <div className='card-holder'>
                    <p>{card.cardHolder}</p>
                    <h6 style={{ textTransform: 'capitalize' }}>{props?.item?.name}</h6>
                </div>
                <div className='card-holder'>
                    <p>{card.expDate}</p>
                    <h6>{getExpiryFormat(props.item?.exp_month, props.item?.exp_year)}</h6>
                </div>
            </div>
        </div>
    )
}

export default CardItems