import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import { BUTTON_TYPE } from '../../types'
import Button from "../../global/ui/Button";
import InputField from '../../lib/input';
import { labels, placeholder } from '../../utils/constants';
import { apiConstants } from '../../utils/apiConstants';
import { PASS_VALIDATION, REQUIRED_VALIDATION } from '../../lib/input/validation-rules';
import { FormProvider, useForm } from 'react-hook-form';
import { changePassFormData } from '../../data/formData';

interface ChangePasswordProps {
  isShow: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  hideClose?: boolean
}

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {

  // useForm hook and set default behavior/values
  const { ...methods } = useForm({
    mode: 'all',
    defaultValues: { ...changePassFormData() },
    reValidateMode: 'onBlur',
  });

  const [isSecure, setIsSecure] = useState<any>(true);
  const [isNewPassSecure, setIsNewPassSecure] = useState<any>(true);

  useEffect(() => {
    if (props.isShow) {
      methods.reset()
    }
  }, [props.isShow])

  return (
    <Modal
      centered
      scrollable
      show={props.isShow}
      onHide={props.onClose}>
      <ModalHeader className="p-0 pb-4 border-0 mb-3 align-items-start">
        {!props?.hideClose && <button className="close-btn-de shadow-none" onClick={props.onClose}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>}
        <ModalTitle className="fw-bold me-auto ps-3 text-start">
          <h4 className='h4 text-start mb-0 mt-0'>Change Password</h4>
          <p className="mb-0 text-start">Enter your old password to create a new password</p>
        </ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0 text-start">
        <FormProvider {...methods}>
          <div>
            <InputField
              label={labels.old_password}
              placeholder={placeholder.enter_old_password}
              name={apiConstants.old_password}
              type={isSecure ? 'password' : 'text'}
              className={'mb-4 black-input'}
              rules={REQUIRED_VALIDATION(labels.old_password)}
              rightIconPress={() => setIsSecure((prvState: any) => !prvState)}
              rightIcon={isSecure ? '/assets/images/eye-off-black.svg' : '/assets/images/eye-on-black.svg'}
              containerStyle={{ marginTop: '25px', }}
            />
            <InputField
              label={labels.new_password}
              placeholder={placeholder.enter_new_password}
              name={apiConstants.password}
              type={isNewPassSecure ? 'password' : 'text'}
              rules={PASS_VALIDATION('New Password')}
              className={'mb-4 black-input'}
              rightIconPress={() => setIsNewPassSecure((prvState: any) => !prvState)}
              rightIcon={isNewPassSecure ? '/assets/images/eye-off-black.svg' : '/assets/images/eye-on-black.svg'}
              containerStyle={{ marginTop: '25px', }}
            />
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter className="p-0 border-0" style={{ marginTop: '60px' }}>
        <Button
          type={BUTTON_TYPE.TYPE_BUTTON}
          btnTxt='Change Password'
          style={{ marginTop: "0px", width: "auto" }}
          onClick={methods.handleSubmit(props.onSubmit)}
          className={'btn btn-warning w-100 with-after-shadow shadow-none m-0'}
        />
      </ModalFooter>
    </Modal>
  )
}

export default ChangePassword;
