import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { makeDeleteCall, makeGetCall } from '../effects/requests';
import { cookieService } from '../services';
import { saveUserData } from '../utils';
import { cookieServiceParam } from '../utils/constants';
import { endPoints } from '../utils/endpoints';

export const MyContext = React.createContext({
  userData: null as any,
  updateUserData: (res: any) => { },
  onLogOut: () => { },
  globalSocketNotification: false,
  updateGlobalSocketNotification: (globalSocketNotificationReceived: boolean) => { },
  subscriptionSelected: null as any,
  updateSelectedSubscription: (res: any) => { }
});

const Context = (props: any) => {

  const navigate = useNavigate();

  const [userData, setUserData] = useState<any>(null);
  const [subscriptionSelected, setSubscriptionSelected] = useState<any>(null)
  const [globalSocketNotification, setGlobalSocketNotification] = useState(false)

  const onLogOut = () => makeDeleteCall(endPoints.LOGOUT, { device_type: 'WEB' }).then(clearAll).catch(clearAll)
  useEffect(() => {
    if (cookieService.getCookie(cookieServiceParam.token)) {
      makeGetCall(endPoints.PROFILE, null, false, true)
        .then((res) => {
          setUserData(res?.data)
        })
        .catch((err) => {

        })
    }
  }, [])

  const clearAll = () => {
    cookieService.removeAllCookies();
    navigate('/sign-in');
  }

  const updateSelectedSubscription = (res: any) => {
    //setSubscriptionSelected(res);
  }
  const updateData = (res: any) => {
    setUserData(res?.data);
    saveUserData(res || null);
  }
  const updateGlobalSocketNotification = (globalSocketNotificationReceived: boolean) => {
    setGlobalSocketNotification(globalSocketNotificationReceived)
  }

  return (
    <MyContext.Provider value={{
      userData: userData, updateUserData: updateData, onLogOut: onLogOut, globalSocketNotification: globalSocketNotification, updateGlobalSocketNotification: updateGlobalSocketNotification, subscriptionSelected: subscriptionSelected, updateSelectedSubscription: updateSelectedSubscription
    } as never}>
      {props?.children}
    </MyContext.Provider>
  )
}

export default Context