import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logout from '../../components/modal/logout';
import { MyContext } from '../../context';
import { makeDeleteCall, makeGetCall, makePatchCall } from '../../effects/requests';
import { cookieService } from '../../services';
import { EXPLORE_TYPE, PERMISSION_TYPE, ROLE_TYPE, USER_TYPE } from '../../types';
import { defaultJobFilter, defaultSupplierFilter, viewProfileTab } from '../../utils/constants';
import { endPoints } from '../../utils/endpoints';

interface SideBarProps {
    children?: React.ReactNode
}

const SideBarLayout = (props: SideBarProps) => {

    const [showMenu, setShowMenu] = useState(false);
    const [isLogOut, setIsLogOut] = useState(false);
    const { userData, updateUserData } = useContext(MyContext)
    const [showProdDropdown, setShowProdDropdown] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [productionHousesList, setProductionHousesList] = useState([])


    const location = useLocation();
    const navigate = useNavigate();

    const pathName = location?.pathname || "";

    const onLogOut = () => {
        makeDeleteCall(endPoints.LOGOUT, { device_type: 'WEB' })
            .then(clearAll)
            .catch(clearAll)
    }

    const callProfile = () => {
        makeGetCall(endPoints.PROFILE)
        .then((res)=>{
            updateUserData(res)
        })
        .catch((err)=>{

        })
    }
    const clearAll = () => {
        updateUserData(null)
        cookieService.removeAllCookies();
        navigate('/sign-in');
    }

    useEffect(() => {
        if (userData?.user_type !== ROLE_TYPE.TYPE_SUPPLIER) {
            getCompanyNames()
        }
    }, [JSON.stringify(userData)])

    const getCompanyNames = () => {
        setCompanyName((userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) ? userData?.owner_profile?.company_name : userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? userData?.supplier_profile?.company_name : userData?.production_company_name)
        makeGetCall(endPoints.USER_PRODUCTION, null, false, true).then((res) => { setProductionHousesList(res?.results) }).catch((err) => { })
    }

    const isSuperUser = userData?.user_type === USER_TYPE.TYPE_CREW ? true : false

    const selectProduction = (item: any) => {
        const urls = `${endPoints.USER_PRODUCTION}${item?.id}/`
        makePatchCall(urls, { is_active: true })
            .then((res) => {
                makeGetCall(endPoints.PROFILE)
                    .then((res) => {
                        updateUserData(res)
                    })
                    .catch((err) => { })
            })
            .catch((err) => {

            })
    }
    useEffect(()=>{
        if(!pathName.includes('/notifications')){
            callProfile()
        }
    },[pathName])

    return (
        <div>
            <div className="view-profile-layout">
                <div className={`left-menu-design text-center ${showMenu ? "show-menu" : ""}`}>
                    <div className="top-menu">
                        <div className='mobile-menu' onClick={() => setShowMenu(!showMenu)}>
                            <span></span>
                        </div>
                        <img style={{ cursor: 'pointer' }} onClick={() => navigate('/schedule/')} src={"/assets/images/yellow-6th.png"} alt="" />
                        <div className="bottom-menu-colleps">
                            {isSuperUser && <div><button className='text-center justify-content-center' onClick={() => setShowProdDropdown(!showProdDropdown)}>
                                {companyName}   {(productionHousesList.length > 1) && <img src={"/assets/images/down-white-arrow.svg"} alt="" style={{ width: '10px', height: '10px', marginLeft: '10px' }} />}
                            </button>
                                {showProdDropdown && (productionHousesList.length > 1) && <div className='multi-prod'>
                                    {productionHousesList.map((item: any) => {
                                        return (
                                            item.is_active ? <div className='d-flex justify-content-between'><p className='selected-prod other-event-title'>{item?.production_company?.company_name} </p>
                                                <img style={{ width: '20px', height: '20px', paddingLeft: '5px' }} src={"/assets/images/check-purple.svg"} alt="" /></div> :
                                                <p className='other-event-title' onClick={() => { selectProduction(item); setCompanyName(item?.production_company?.company_name); setShowProdDropdown(!showProdDropdown) }}>{item?.production_company?.company_name}</p>
                                        )
                                    })}
                                </div>}</div>}
                            <ul className="top-menu-link mb-0">
                                <li className={pathName.includes('/schedule') ? 'active' : ''}>
                                    <Link to={`/schedule/`}>
                                        <img src={"/assets/images/menu-icon/Schedule.svg"} alt="" />
                                        <img src={"/assets/images/menu-icon/Schedule-active.svg"} alt="" />
                                        {viewProfileTab.tab1}</Link>
                                </li>
                                {isSuperUser &&
                                    <li className={pathName.includes('/suppliers') ? 'active' : ''}>
                                        <Link to='/suppliers' state={defaultSupplierFilter}>
                                            <img src={"/assets/images/menu-icon/Suppliers.svg"} alt="" />
                                            <img src={"/assets/images/menu-icon/Suppliers-active.svg"} alt="" />
                                            {viewProfileTab.tab2}</Link>
                                    </li>}
                                {isSuperUser &&
                                    <li className={pathName.includes('/explore') ? 'active' : ''}>
                                        <Link to={`/explore/?type=${EXPLORE_TYPE.EX_CREW}&search=&department=&location=&limit=10&role=&joining_date_current_company=&start_date=&end_date=&permission_type=`}>
                                            <img src={"/assets/images/menu-icon/Crew.svg"} alt="" />
                                            <img src={"/assets/images/menu-icon/Crew-active.svg"} alt="" />
                                            {viewProfileTab.tab3}</Link>
                                    </li>
                                }
                                {isSuperUser &&
                                    <li className={pathName.includes('/jobs') ? 'active' : ''}>
                                        <Link
                                            to='/jobs'
                                            state={defaultJobFilter(userData?.owner_profile?.company_name || '')}
                                        >
                                            <img src={"/assets/images/menu-icon/Jobs.svg"} alt="" />
                                            <img src={"/assets/images/menu-icon/Jobs-active.svg"} alt="" />
                                            {viewProfileTab.tab4}</Link>
                                    </li>
                                }
                                <li className={pathName.includes('/messages') ? 'active' : ''}>
                                    <Link to={`/messages/?search=&type=${(userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) ? USER_TYPE.TYPE_SUPPLIER : USER_TYPE.TYPE_PRIMARY}`}>
                                        <img src={"/assets/images/menu-icon/Messages.svg"} alt="" />
                                        <img src={"/assets/images/menu-icon/Messages-active.svg"} alt="" />
                                        {viewProfileTab.tab5}</Link>
                                </li>
                                <li className={`${userData?.unread_notification_count && !pathName.includes('/notifications') ? 'notify-notification ' : ' '}${pathName.includes('/notifications') ? 'active' : ''}`}>
                                    <Link to="/notifications">
                                        <img src={"/assets/images/menu-icon/Notifications.svg"} alt="" />
                                        <img src={"/assets/images/menu-icon/Notifications-active.svg"} alt="" />
                                        {viewProfileTab.tab6}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bottom-menu">
                        <ul className="top-menu-link mb-0">
                            <li className={(pathName.includes('/view-profile')) ? 'active' : ''}>
                                <Link to="/view-profile">
                                    <img src={"/assets/images/menu-icon/Profile.svg"} alt="" />
                                    <img src={"/assets/images/menu-icon/Profile-active.svg"} alt="" />
                                    {viewProfileTab.tab8}</Link>
                            </li>
                            <li>
                                <Link onClick={() => setIsLogOut(true)} to="#">
                                    <img src={"/assets/images/menu-icon/Log-Out.svg"} alt="" />
                                    <img src={"/assets/images/menu-icon/Log-Out-active.svg"} alt="" />
                                    {viewProfileTab.tab9}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {props?.children}
                <Logout
                    show={isLogOut}
                    closePopup={() => setIsLogOut(false)}
                    onLogOut={() => {
                        setIsLogOut(false)
                        onLogOut?.()
                    }}
                />
            </div>
        </div>
    )
}

export default SideBarLayout