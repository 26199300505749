import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AuthSection from '../../global/layout/authSection'
import { makeGetCall } from '../../effects/requests'
import { ApiResponseType, LegalsType } from '../../types'
import { LegalHeader } from './LegalHeader'
import { endPoints } from '../../utils/endpoints'

const Legals: React.FC<any> = () => {

    const parmas = useParams();

    const [legals, setLegals] = useState<string | null>();

    const getTncAndPP = (url: string) => {
        makeGetCall(url).then((res: ApiResponseType<LegalsType>) => {
            setLegals(res.data?.text || null)
        }).catch((error) => console.log(error))
    }

    useEffect(() => {
        const url = parmas?.type === 'pp' ? endPoints.PP : endPoints.TERMS;
        getTncAndPP(url)
    }, [])


    const title = parmas?.type === 'pp' ? 'Privacy Policy' : 'Terms & Conditions'

    return (
        <AuthSection>
            <div>
                <div className='inner-content policy-page text-center ms-auto'>
                    <LegalHeader
                        title={title}
                    />
                    <div className='policy-page-data text-start'>
                        <div dangerouslySetInnerHTML={{ __html: legals || '' }} className='page-scroll' />
                    
                    </div>
                </div>
            </div>
        </AuthSection>
    )
}

export default Legals;
