import React, { useContext, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import { currencyData } from '../../data/data';
import CurrencyItem from './CurrencyItem';
import { buttonsText, cookieServiceParam, errorMessage } from '../../utils/constants';
import { ApiResponseType, BUTTON_TYPE, CURRENCY_TYPE, INTERVAL_TYPE, ROLE_TYPE } from '../../types';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../context';
import { cookieService } from '../../services';
import { endPoints } from '../../utils/endpoints';
import { makeGetCall } from '../../effects/requests';
import SubscriptionItem from './SubscriptionItem';
import NoData from '../../global/ui/NoData';
import { showToast } from '../../utils';
import Button from '../../global/ui/Button';

const SubscriptionUpgrade = (props:any) => {
    const [currency, setCurrency] = useState('GBP')
    const naviagte = useNavigate();
    const {updateSelectedSubscription} = useContext(MyContext)
    const [isShowCardModal, setIsShowCardModal] = useState(false);
    const [subscriptionListData, setSubscriptionListData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_TYPE.TYPE_GBP);
    const [selectedSubs, setSelectedSubs] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [isShow, setIsShow] = useState(false);
    const {userData} = useContext(MyContext);
    //const userType = cookieService.getCookie(cookieServiceParam.user)?.[apiConstants.user_type] || ROLE_TYPE.TYPE_SUPPLIER
    const userType = cookieService.getCookie(cookieServiceParam.userType)
    const getSubsData = () => {
        setIsLoading(true)
        makeGetCall(endPoints.SUBSCRIPTION).then((res: ApiResponseType<any>) => {
            setIsLoading(false)
            if (res.status?.code == 200) {
                const filterArr = filterListAsPerCurrency(res.data?.data?.length > 0 ? res.data?.data : [], selectedCurrency);
                setAllData(res.data?.data)
                setSubscriptionListData(filterArr as never)

                // //pre select first element by default
                // if (filterArr?.length > 0) {
                //     const supFilterArr = filterArr.filter((item: any) => item?.recurring?.interval != INTERVAL_TYPE.TYPE_WEEK);
                //     setSelectedSubs(supFilterArr?.length > 0 ? supFilterArr?.[0] : filterArr?.[0])
                // }
            }
        }).catch((err) => setIsLoading(false))
    }

    const filterListAsPerCurrency = (data: Array<any>, currentCurrency: any) => data.filter((item) => item?.currency === currentCurrency)

    useEffect(getSubsData, []);
    const getSymbol = () => {
      let sym = '£';
      if (selectedCurrency === CURRENCY_TYPE.TYPE_EUR) {
          sym = '€'
      }
      if (selectedCurrency === CURRENCY_TYPE.TYPE_USD) {
          sym = '$'
      }
      return sym;
  }
  return (
    <Modal
    centered
      scrollable
      className="max-width-526 sub-upgrade modal-body-scroll-part"
      show={props?.isShow}
      //onHide={close}
      >
    <ModalHeader className="p-0 border-0 mb-4 align-items-start">
        {/* <button className="close-btn-de shadow-none" onClick={()=>close()}>
          <img src={"/assets/images/cancel-button.svg"} alt="" />
        </button> */}
        <ModalTitle className="fw-bold mx-auto ps-3 text-center">
          <img src={"/assets/images/logo.png"} alt="" style={{width:'90px'}}  className='mx-auto d-block'/>
        </ModalTitle>
        <div>
        </div>
      </ModalHeader>
      <ModalBody style={{marginBottom:'0'}}>
           <div className='d-flex flex-column align-items-center'>
            <h4 className="h4 text-start mb-0 mt-0" style={{fontWeight:'600', fontSize:'24px'}}> Subscription Upgrade </h4> 
           <h6  style={{marginTop:'30px'}}>Your free period is over. It’s time to renew your membership</h6>
            </div> 
           <form>
                        <div className='slect-radio-like-tab label-bg-black ms-auto mt-4'>
                            {currencyData.map((item, index) => <CurrencyItem
                                key={index}
                                item={item}
                                selectedCurrency={selectedCurrency}
                                onClick={(value: any) => {
                                    const temp = filterListAsPerCurrency(allData, value);
                                    setSubscriptionListData(temp as never);
                                    setSelectedCurrency(value);
                                    setSelectedSubs(null)
                                }}
                            />)}
                        </div>
                        <div className='full-radio-buttons mb-5 pb-3'>
                            {subscriptionListData?.length > 0 ?
                                subscriptionListData.map((item: any, index) => {
                                    console.log("item",item,userType)
                                    if (userType === ROLE_TYPE.TYPE_SUPPLIER && item?.recurring?.interval !== INTERVAL_TYPE.TYPE_YEAR) return null
                                    if (userType === ROLE_TYPE.TYPE_CREW && item?.recurring?.interval !== INTERVAL_TYPE.TYPE_WEEK) return null
                                    //if (item?.recurring?.interval === INTERVAL_TYPE.TYPE_MONTH) return null
                                    return (
                                        <SubscriptionItem
                                            item={item}
                                            key={index}
                                            selectedId={selectedSubs?.id}
                                            onClick={(data: any) => {
                                                //updateSelectedSubscription(data);
                                                cookieService.saveCookie(cookieServiceParam.subscription_id, data?.id);
                                                setSelectedSubs(data)}}
                                            currencyIcon={getSymbol()}
                                            className='upgrade-plans'
                                        />
                                    )
                                })
                                :
                                <>
                                    {isLoading ? 'Loading...' : <NoData />}
                                </>
                            }
                        </div>
                        <Button
                            type={BUTTON_TYPE.TYPE_BUTTON}
                            btnTxt={buttonsText.continue}
                            style={{ marginTop: "20px", width: "100%" }}
                            onClick={() => {
                                if (!selectedSubs?.id) {
                                    showToast(errorMessage.subs_not_selected, 'error');
                                    return
                                }
                                //if(userType === ROLE_TYPE.TYPE_SUPPLIER){
                                    
                                    props?.onDone();
                                    //props?.onClose();
                                    //naviagte(`/manage-profile/supplier/add/1`)
                                    return
                                //}
                                //setIsShowCardModal(true)
                                //setIsShow(true)   -> For free subscription case
                            }}
                            className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                        />
                    </form>
      </ModalBody>
    </Modal>
  )
}

export default SubscriptionUpgrade
