import React, { useContext, useEffect, useState } from 'react'
import DropDown from '../../global/ui/dropDown';
import Search from '../../global/ui/Search'
import { setDropdownSelectedDate, setDropdownSelectedValue, showToast } from '../../utils';
import { preConfigDeptData, rolesList, yearGap } from '../../data/data';
import { labels, placeholder } from '../../utils/constants';
import { EXPLORE_TYPE, PERMISSION_TYPE } from '../../types';
import { MyContext } from '../../context';
import DatePicker from '../../global/ui/DatePicker';
import { DateUtils } from '../../utils/dateUtils';
import moment from 'moment';

interface CrewMemFilterProps {
    count?: any;
    updateValue?: (key: any, value: any) => void;
    data: any;
    showing?: any;
    onClear: () => void;
}

const CrewMemFilter: React.FC<CrewMemFilterProps> = (props) => {

    const { userData } = useContext(MyContext);
    const [allDept, setAllDept] = useState(preConfigDeptData)

    useEffect(() => {
        if (userData?.permission_type !== PERMISSION_TYPE.TYPE_PROD_OWNER && userData?.crew_member_profile?.company_department?.length > 0) {
            userData?.crew_member_profile?.company_department?.map((item: any) => {
                item.label = item?.dep;
                item.value = item?.dep;
            })
            setAllDept(userData?.crew_member_profile?.company_department)
        }
    }, [userData])

    const roles = setDropdownSelectedValue(allDept, props?.data?.department)?.roles?.length > 0 ? setDropdownSelectedValue(allDept, props?.data?.department)?.roles : []

    return (
        <div className="table-filter-setup mb-3 mb-lg-0">
            <div className="row align-items-center justify-content-between">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3 order-1 order-xxl-1">
                    <div className="liket-active-tab mb-4">
                        <ul className="list-unstyled d-flex align-item-center mb-0">
                            <li onClick={() => props?.updateValue?.('type', EXPLORE_TYPE.MY_PROD)} className={props.data?.type === EXPLORE_TYPE.MY_PROD ? "active" : ''}>My Production</li>
                            <li onClick={() => props?.updateValue?.('type', EXPLORE_TYPE.EX_CREW)} className={props.data?.type === EXPLORE_TYPE.EX_CREW ? "active" : ''}>Explore Crew</li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3 order-3 order-sm-2 order-xxl-3">
                    <div className="right-side-showing d-flex align-items-center justify-content-center justify-content-lg-end gap-3 mb-4 mt-3 mt-sm-0">
                        <div className="clear-all" onClick={props?.onClear}>
                            Clear all</div>
                        <p className="mb-0 fw-bold">{`Showing ${props?.showing} of ${props?.count || 0} results`}</p>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 order-2 order-sm-3 order-xxl-2">
                <div className="select-fileter d-grid d-sm-flex align-items-center gap-4 mb-4">
                    <DropDown
                        className="arrow-fill w-100 mb-0 mb-sm-4 drop-down-over-vis"
                        data={allDept}
                        placeholder='All departments'
                        label='Filter by department'
                        selectedItems={setDropdownSelectedValue(allDept, props?.data?.department)}
                        onSelect={(data) => props?.updateValue?.('department', data?.label)}
                        style={{ width: '221px' }}
                    />
                    {/* {props.data?.type === EXPLORE_TYPE.EX_CREW ? */}
                    <DropDown
                        className="arrow-fill w-100 mb-0 mb-sm-4 drop-down-over-vis"
                        data={roles}
                        onSelect={(data) => props?.updateValue?.('role', data?.label)}
                        placeholder={placeholder.allRole}
                        selectedItems={setDropdownSelectedValue(roles, props?.data?.role)}
                        label={labels.filterByRole}
                        style={{ width: '221px' }}
                    />
                    {props.data?.type === EXPLORE_TYPE.EX_CREW ? '' : <DropDown
                        className="arrow-fill w-100 mb-0 mb-sm-4 drop-down-over-vis"
                        data={rolesList}
                        onSelect={(data) => props?.updateValue?.('permission_type', data?.value)}
                        placeholder={placeholder.allType}
                        selectedItems={setDropdownSelectedValue(rolesList, props?.data?.permission_type)}
                        label={labels.filterByType}
                        style={{ width: '221px' }}
                    />}
                    {props.data?.type === EXPLORE_TYPE.EX_CREW ?
                        <>
                            <div style={{ maxWidth: '' }} className="form_group w-100 black-input">
                                <div style={{ width: '221px' }} className="input_box">
                                    <label htmlFor="country">Location</label>
                                    <div className="search-button d-flex align-items-center gap-3">
                                        <Search
                                            id='country'
                                            placeholder='City or Postcode'
                                            value={props?.data?.location}
                                            onChange={(value: any) => props?.updateValue?.('location', value)}
                                            imgStyle={{ backgroundColor: 'transparent' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <DatePicker
                                date={props.data?.start_date ? moment(props.data?.start_date) : null}
                                onDateChange={(date) => props?.updateValue?.('start_date', DateUtils.formatDateTime(date, 'YYYY-MM-DD'))}
                                label='Availability'
                                displayFormat='YYYY-MM-DD'
                                allowPastDate={true}
                                openDirection='down'
                                placeholder='From'
                                style={{ width: '221px' }}
                                className="black-input"
                            />
                            <DatePicker
                                date={props.data?.end_date ? moment(props.data?.end_date) : null}
                                onDateChange={(date) => {
                                    if (DateUtils.isDateTimeIsBefore(date, moment(props?.data?.start_date), 'YYYY-MM-DD')) {
                                        showToast('To date can not be lesser than from date.', 'error', Math.random())
                                        return
                                    }
                                    props?.updateValue?.('end_date', DateUtils.formatDateTime(date, 'YYYY-MM-DD'))
                                }}
                                label='Availability'
                                displayFormat='YYYY-MM-DD'
                                allowPastDate={true}
                                openDirection='down'
                                placeholder="To"
                                style={{ width: '221px' }}
                                className="black-input date-colender-bottom-right-0 arrow-setup"
                            />
                        </>
                        :
                        <DropDown
                            className="w-100 arrow-fill mb-0 drop-down-over-vis"
                            data={yearGap}
                            onSelect={(data) => props?.updateValue?.('joining_date_current_company', data?.date)}
                            placeholder='All statuses'
                            selectedItems={setDropdownSelectedDate(yearGap, props?.data?.joining_date_current_company)}
                            label='Employment Duration'
                            style={{ width: '221px' }}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default CrewMemFilter