import Button from '../../global/ui/Button';
import AuthSection from '../../global/layout/authSection';
import { pageHeading } from '../../utils/constants';
import { BUTTON_TYPE } from '../../types';
import { useNavigate } from "react-router-dom";
const ResetPassword = (props: any) => {
  let navigate = useNavigate();

  return (
    <AuthSection>
      <div className='inner-content text-center ms-auto'>
        <div className='top-content'>
          <img src={"/assets/images/bell-mail-icon.svg"} alt="" />
          <h3>{pageHeading.resetPassword}</h3>
          <p className='mb-0'>{pageHeading.resetPasswordContent}</p>
        </div>
        <form>

          <Button
            type={BUTTON_TYPE.TYPE_BUTTON}
            btnTxt='Open Mail'
            //isLoading={isLoading}
            // disabled={isLoading}
            style={{ marginTop: "66px", width: "100%" }}
            onClick={() => alert('Hi')}
            className={'btn btn-warning w-100 with-after-shadow shadow-none'}
          />
        </form>
        <div className='move-login-signup' onClick={() => navigate('/sign-in')}>
          <p className='mb-0'>Go to Login</p>
        </div>
      </div>
    </AuthSection>
  )
}
export default ResetPassword;