import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import { BUTTON_TYPE } from '../../types'
import Button from "../../global/ui/Button";
import { ChangeEvent, useRef, useState } from 'react';
import { makePostCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { showToast } from '../../utils';
import React from 'react';
const ImportCSV = (props: any) => {

  const [file, setFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (e:any) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      showToast("File selected successfully", "info")
    }
  };
  const resetFile = (e:any) => {
    if (e.target.files) {
      e.target.value =null;
    }
  };
  const handleUploadClickLocal = () => {
    inputRef.current?.click();
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }
    const formdata = new FormData();
    formdata.append('file', file)
    makePostCall(endPoints.DOWNLOAD_TEMPLATE, formdata)
      .then((res) => {
        if (res?.status?.code === 200) {
          showToast(res?.data, "success")
          props.onClick({ isShowManual: false, isShowBulk: false });
          props.setIsShoow(false);
          props.closePopup();
          setFile(undefined)
        }
        else {
          showToast(res?.data, "error")
        }
      })
      .catch((err) => {
        showToast(err, "error")
      })
  }
  return (
    <Modal
      centered
      scrollable
      className='max-width-496 addProductionCrew modal-body-scroll-part'
      show={props.show}
      onHide={props.closePopup}>
      <ModalHeader className="p-0 pb-4 border-0 mb-4 align-items-start">
        <button className="close-btn-de shadow-none" onClick={props.closePopup}><img src={"/assets/images/black-arrow-left-side.svg"} alt="" /></button>
        <ModalTitle className="fw-bold me-auto ps-3 text-start">
          <h4 className='h4 text-start mb-0 mt-0'>Import CSV </h4>
          <p className="mb-0 text-start">Import the csv sheet to bulk import users</p>
        </ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0 text-start">
        <input type="file" onChange={handleFileChange}  onClick ={resetFile} ref={inputRef} id="bulkUpload" style={{ display: 'none' }} accept="text/csv" />
        <div className='add-user-type-list d-grid gap-4 mb-3' onClick={handleUploadClickLocal} >
          <div className='add-user-desing dotted-border text-center'>
            <img src="/assets/images/import-csv-fill-dark.svg" alt="" />
            <h3>Import Data</h3>
            <p className='mb-0'>Import CSV to continue</p>
          </div>
        </div>
        {file? 
        <div className='px-4 mx-2'>
          <div className='import-csv-data-list d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center gap-3'>
              <img src="/assets/images/file_black-icon.svg" alt="" />
              <p className='mb-0'>{file?.name}</p>
            </div>
            <div>
              <img src="/assets/images/delete-fill-inner-red.svg" alt="" onClick={()=> {
                setFile(undefined); }} />
            </div>
          </div>
        </div> : ''}
      </ModalBody>
      <ModalFooter className="p-0 border-0" style={{ marginTop: '125px' }}>
        <Button
          type={BUTTON_TYPE.TYPE_BUTTON}
          btnTxt='Continue'
          style={{ marginTop: "0px", width: "auto" }}
          onClick={() => { handleUploadClick() }}
          className={'btn btn-warning w-100 with-after-shadow shadow-none m-0'}
        />
      </ModalFooter>
    </Modal>
  )
}

export default ImportCSV
