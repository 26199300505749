import React from 'react'

interface HeaderTabProps {
    data: Array<any>;
    selectedTab?: any;
    onClick?: (idx: any) => void;
}

const HeaderTab: React.FC<HeaderTabProps> = (props) => {
    return (
        <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center justify-content-center">
            {props.data?.map((item, idx) => {
                const tempIdx = idx + 1
                return (
                    <li style={{ cursor: 'pointer' }} onClick={() => props?.onClick?.(tempIdx)} key={idx} className={props?.selectedTab >= tempIdx ? "active" : ''}><span>{tempIdx}</span>{item}</li>
                )
            })}
        </ul>
    )
}

export default HeaderTab