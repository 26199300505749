import Button from '../../../global/ui/Button';
import { BUTTON_TYPE, USER_ACTION_TYPE } from '../../../types';
import { buttonsText, labels, placeholder } from '../../../utils/constants';
import { useForm, FormProvider } from "react-hook-form";
import { ownerRateFormData } from '../../../data/formData';
import { apiConstants } from '../../../utils/apiConstants';
import { REQUIRED_VALIDATION } from '../../../lib/input/validation-rules';
import InputField from '../../../lib/input';
import { generateFormData } from '../../../utils';

interface RateProps {
  userData: any;
  onSubmit: (value: any) => void;
  actionType?: any
}

const Rate = (props: RateProps) => {

  const { ...methods } = useForm({
    mode: 'all',
    defaultValues: { ...ownerRateFormData(props.userData) },
    reValidateMode: 'onBlur',
  });

  const onSubmit = (values: any) => {
    const apiPayload: any = { ...values }
    //apiPayload.account_completed = true
    const fd = generateFormData(apiPayload);
    props.onSubmit(fd)
  }

  return (
    <FormProvider {...methods} >
      <form action="" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <div className="all-form" style={{ marginTop: '9px' }}>
          <InputField
            label={labels.rate6}
            placeholder={placeholder.rate}
            name={apiConstants.sixth_day_earning_rates}
            type='number'
            rules={REQUIRED_VALIDATION(labels.rate6, true)}
            containerStyle={{ marginTop: '0', }}
            className='black-input mb-4'
          />
          <InputField
            label={labels.rate7}
            placeholder={placeholder.rate}
            name={apiConstants.seventh_day_earning_rates}
            type='number'
            rules={REQUIRED_VALIDATION(labels.rate7, true)}
            containerStyle={{ marginTop: '0', }}
            className='black-input mb-4'
          />
        </div>
        <div className="main-button">
          <Button
            type={BUTTON_TYPE.TYPE_SUBMIT}
            btnTxt={props?.actionType === USER_ACTION_TYPE.TYPE_UPDATE ? buttonsText.saveAndUpdate : buttonsText.continue}
            className={"btn btn-warning w-100 with-after-shadow shadow-none"}
          />
        </div>
      </form>
    </FormProvider>
  )
}
export default Rate;