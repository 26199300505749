import React from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { createFullName } from "../../utils";
import moment from "moment";
import Logo from "../../components/commonComponent/ProfileImage";

const AssignEvent = (props: any) => {
  console.log(props)
  return (
    <Modal
      centered
      scrollable
      className="max-width-496 modal-body-scroll-part"
      show={props.isShow}
      onHide={props.onClose}
    >
         <ModalHeader className="p-0 border-0 align-items-start">
         <button className="close-btn-de shadow-none" onClick={props.onClose} style={{marginRight:'10px'}}>
          <img src={"/assets/images/cancel-button.svg"} alt="" />
         </button>
            <ModalTitle className="fw-bold me-auto text-start">
                        <h4 className='h4 text-start mb-0 mt-0'>Allocate to Event</h4>
                        <div className=" d-flex gap-2 ">
                          {props?.nameData?.profile_image ?
                            <img className="align-self-baseline user-profile-img" 
                            src={  props.nameData?.profile_image} alt="Image"/>
                           : 
                           <Logo style={{ width: '70px', height: '70px' }} data={props.nameData?.first_name?.[0]}/> }
                            <div>
                            {/* {createFullName(props.item?.first_name, props.item?.last_name) || ''} */}
                            <p className="mutiple-event-name">{createFullName(props.nameData?.first_name, props.nameData?.last_name) || ''}</p>
                            {/* <p className="mutiple-event-job-title">{props?.nameData?.job_title? props?.nameData?.job_title: <p style={{color:'#BDBDBD', fontSize:'12px'}}>No Job Assigned</p>}</p> */}
                        </div>
                        </div>
            </ModalTitle>
                <img className="" src={"/assets/images/purple-cross.svg"} alt="" style={{margin:'6px'}}/>
                <p className="assign-newevent">New Event</p>
            </ModalHeader>
            <p className="mutiple-event-name">Select Event</p>
            <div className="d-flex align-item-center" style={{ width: '400px' }}>
              <img
                style={{ cursor: "pointer" }}
                // onClick={() => {
                //   if (calendarType === 'Day')
                //     setSelectedDate(DateUtils.getPreviousDay(selectedDate))
                //   if (calendarType === 'Week')
                //     setSelectedDate(DateUtils.getPreviousWeek(selectedDate))
                //   if (calendarType === 'Month') {
                //     setSelectedDate(DateUtils.getPreviousMonth(selectedDate))
                //     if (currentMonth === 0) {
                //       setCurrentMonth(11);
                //       setCurrentYear(currentYear - 1);
                //     } else {
                //       setCurrentMonth(currentMonth - 1);
                //     }
                //   }
                // }
                // }
                src="/assets/images/left-arrow.svg"
                alt=""
              />  
              {<p className="mb-0 extra-padding-top">{moment(props?.dateSelected).format('DD MMMM')}</p>}
              <img
                style={{ cursor: "pointer" }}
                // onClick={() => {
                //   if (calendarType === 'Day')
                //     setSelectedDate(DateUtils.getNextDay(selectedDate))
                //   if (calendarType === 'Week')
                //     setSelectedDate(DateUtils.getNextWeek(selectedDate))
                //   if (calendarType === 'Month') {
                //     setSelectedDate(DateUtils.getNextMonth(selectedDate))
                //     if (currentMonth === 11) {
                //       setCurrentMonth(0);
                //       setCurrentYear(currentYear + 1);
                //     } else {
                //       setCurrentMonth(currentMonth + 1);
                //     }
                //   }
                // }
                // }
                src="/assets/images/play-icon-dark.svg"
                alt=""
              /></div>
            <ModalBody>
            </ModalBody>
          {/* <h6
            className="h4 text-start mb-0 mt-0"
            style={{ fontSize: "20px", padding: "4px" }}
          >{`${DateUtils.formatDateTime(
            props?.eventData[0]?.event_start_date,
            "dddd, Do MMMM"
          )}`}</h6> */}
    </Modal>
  );
};

export default AssignEvent;
