import Cookies from "js-cookie";
import { toast, TypeOptions } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { CookiesKeysType, DateTypes, PERMISSION_TYPE, ROLE_TYPE, TimeTypes, UserDetailsType, USER_ACTION_TYPE } from "../types";
import moment from "moment";
import { cookieService } from "../services";
import { buttonsText, cookieServiceParam, defaultCountryOptions, defaultISD } from "./constants";
import { countryList, defaultDepRoles, rolesList } from "../data/data";
const _ = require('lodash');
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { isValidPhoneNumber } from "libphonenumber-js/max";
import axios from "axios";

export const emptyFunction = () => { };

export const generateFormData = (obj: any) => {
    let formData = new FormData();
    for (let [key, value] of Object.entries(obj)) {
        if (Array.isArray(value)) {
            formData.append(`${key}`, `${value}`);
        } else {
            formData.append(`${key}`, `${value}`);
        }
    }
    return formData;
};

export const setCookie = (key: CookiesKeysType, value: string | any) =>
    Cookies.set(key, value);

export const getCookie = (key: CookiesKeysType) => Cookies.get(key);

export const removeCookie = (key: CookiesKeysType) => Cookies.remove(key);

export const isUserAuthenticated = () => cookieService.getCookie(cookieServiceParam.token)

export const resetAllCookie = () => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("user");
};

export const showToast = (
    message: string | any,
    type: TypeOptions,
    toastId?: any
) => {
    toast(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        type,
        toastId: toastId || undefined,
    });
};

export const convertB64DataToByteArray = (b64Data: any) => {
    const encodedData = window.btoa(b64Data); // encode a string
    const byteCharacters = window.atob(encodedData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return byteArray;
};

export const createGuid = () => uuidv4();

export const userFullName = (
    firstName: string,
    midName?: string,
    lastName?: string
) => {
    const name = `${firstName ? firstName : ""} ${midName ? midName : ""} ${lastName ? lastName : ""
        }`;
    return name;
};

export const formatDateTime = (
    timeStamp: Date | any,
    formatTo?: DateTypes | TimeTypes
) => {
    return timeStamp
        ? moment(timeStamp).format(formatTo)
        : moment().format(formatTo);
};

export const getRoute = (data: any) => {
    let route = '/schedule';
    if (!data?.subscription_id) {
        if (data?.user_type === ROLE_TYPE.TYPE_SUPPLIER) {
            return '/expire-subscription'
        }
        else if (data?.user_type === ROLE_TYPE.TYPE_CREW && data?.permission_type !== PERMISSION_TYPE.TYPE_CREW_MEMBER && data?.permission_type !== PERMISSION_TYPE.TYPE_PROD_OWNER) {
            return '/view-profile'
        }
        else {
            return '/subscription'
        }
    }
    if (!data?.account_completed) {
        const supplierStep = 1;
        const supplierUrl = `/manage-profile/supplier/${USER_ACTION_TYPE.TYPE_ADD}/${supplierStep}`;
        const ownerStep = !data?.owner_profile?.company_name ? 1 : !data?.country ? 2 : 3;
        const ownerUrl = `/manage-profile/owner/${USER_ACTION_TYPE.TYPE_ADD}/${ownerStep}`
        return (data?.user_type === ROLE_TYPE.TYPE_CREW && data?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) ? ownerUrl : supplierUrl;
    }

    return route;
};

export const saveUserData = (payload: any) => {
    if (payload?.token?.access) {
        cookieService.saveCookie(cookieServiceParam.token, payload?.token?.access || "");
    }
    if (payload?.token?.refresh) {
        cookieService.saveCookie(cookieServiceParam.refresh, payload?.token?.refresh || "");
    }
    if (payload?.data) {
        cookieService.saveCookie(cookieServiceParam.user, payload?.data);
    }
};

export const getUserData = () => {
    const user = cookieService.getCookie(cookieServiceParam.user);
    return user || null
}

export const getInitialRouteName = (userData: UserDetailsType) => {
    let route = '/schedule';
    if (!userData?.subscription_id) {
        route = '/subscription'
    }
    return route;
};

export const createFullName = (firstName: any, lastName: any) => {
    if (firstName !== "" && lastName !== "") {
        return `${firstName} ${lastName}`;
    }
    if (firstName) {
        return firstName;
    }
    if (lastName) {
        return lastName;
    }
};

export const breakFullname = (fullname: string): string[] => {
    let firstName: string;
    let lastName: any;
    const completeName = fullname.split(" ");

    if (completeName.length > 1) {
        lastName = completeName.pop();
        firstName = completeName.map((name) => name).join(" ");
    } else {
        firstName = completeName[0] || "";
    }

    return [firstName, lastName || ""];
};

export const setDropdownSelectedValue = (listArr: Array<any>, value: string) => listArr.find((el) => (el.value === value || el.label === value)) || null;

export const setDropdownSelectedDate = (listArr: Array<any>, value: string) => {
    var diff = moment().diff(value, 'days')
    listArr.find((el) => (el.difference === diff) ? el.label : '')
}

export const setDropdownSelectedEvent = (listArr: Array<any>, value: string) => {
    let a = listArr.find((el) => (el.value === value) || null)
    return a;
}
export const setDropdownSelectedEventTime = (listArr: Array<any>, value: string) => {
    let a = listArr.find((el) => (el.startTime === value) || null)
    return a;
}
export const onRoleSelect = (data: any, selectedDepartment: any, selectedDepIdx: any) => {
    const dep = _.cloneDeep(selectedDepartment);
    const role: any = _.cloneDeep(dep?.[selectedDepIdx]?.selectedRole)
    const idx = role.findIndex((el: any) => el.value === data.value);
    if (idx === -1) {
        role.push(data);
    } else {
        role.splice(idx, 1)
    }
    dep[selectedDepIdx].selectedRole = role
    return dep
}

export const onDepSelect = (data: any, selectedDepartment: any, selectedDepIdx: any) => {
    const tempDep = _.cloneDeep(selectedDepartment)
    let tempRole: any = [];
    const idx = tempDep.findIndex((el: any) => el.value === data.value);
    if (idx === -1) {
        tempDep[selectedDepIdx] = { ...data, selectedRole: [] };
        tempRole = data.roles
    } else {
        tempDep.splice(idx, 1);
        if (tempDep?.length === 0) {
            tempDep.push(defaultDepRoles)
        }
    }
    return [tempDep, tempRole]
}


export const getMaskInputFormat = (num: any) => {
    const number = `( ${num.substring(0, 3)} )  0${num.substring(3, 6)}  ${num.substring(6, num.length)}`;
    return number
    // const code = mask(num, mastFormat || apiConstants.mast_format);
    // return code;
};
export const getExpiryFormat = (month: any, yr: any) => {
    const a = month;
    const b = yr?.substr(-2);
    const num = Number(a + b)
    var s = num + "";
    while (s.length < 4) s = "0" + s;
    return [s.slice(0, 2), '/', s.slice(2)].join('');
};

/** Phone Utils */
export const getNumberWithIsd = (mobileNum: any) => {
    if (!mobileNum) {
        return;
    }

    //this module is used to make country code separate from combined mobile number
    const parsedNumber = parsePhoneNumberFromString(mobileNum)
        ?.format("INTERNATIONAL")
        ?.split(" ");

    const isd = parsedNumber?.shift() || defaultISD;

    return [isd, mobileNum.substring(isd.length)];
};

export const validatePhoneNumber = (mobileNum: string | any) => {
    return isValidPhoneNumber(mobileNum);
};

export const getSelectedIsd = (isd: string) => countryList.find((el) => (el.dial_code == isd)) || defaultCountryOptions;

export const getSelectedCountry = (country: string) => countryList.find((el) => (el.name == country)) || null;

export const getPrmsnText = (permissionType: any, type?: any,) => {
    if (type === ROLE_TYPE.TYPE_CREW && permissionType === PERMISSION_TYPE.TYPE_PROD_OWNER) {
        //type === ROLE_TYPE.TYPE_OWNER
        return buttonsText.productionCompany
    }
    if (type === ROLE_TYPE.TYPE_SUPPLIER) {
        return buttonsText.btnSupplier
    }
    if (permissionType === PERMISSION_TYPE.TYPE_ADMIN) {
        return 'Admin'
    }
    if (permissionType === PERMISSION_TYPE.TYPE_HOD) {
        return 'HOD'
    }
    if (permissionType === PERMISSION_TYPE.TYPE_SUPERVISOR) {
        return 'Supervisor'
    }
    if (permissionType === PERMISSION_TYPE.TYPE_CREW_MEMBER) {
        return 'Crew'
    }
}

export const getRolesAsPerPrmsn = (prmsnType: any) => {
    const data = rolesList.filter((item) => item?.permissions?.includes(prmsnType));
    return data
}
export function handleConvertToImageFile(base64String: string, filename: string, mimeType: string) {
    // Extract the data and mime type from the base64 string
    const imageData = atob(base64String.split(',')[1]);
    mimeType = mimeType || base64String.split(';')[0].split(':')[1];

    // Convert the binary data to a Blob object
    let bytes = new Uint8Array(imageData.length);
    for (let i = 0; i < imageData.length; i++) {
        bytes[i] = imageData.charCodeAt(i);
    }
    let blob = new Blob([bytes.buffer], { type: mimeType });

    // Create a File object from the Blob
    return new File([blob], filename, { type: mimeType });

}
export const getAddressFromCoordinates = async (latitude: number, longitude: number) => {
    try {
        const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );

        if (response.data && response.data.results.length > 0) {
            const address = response.data.results[0].formatted_address;
            return address;
        } else {
            throw new Error('No results found');
        }
    } catch (error) {
        console.error('Error retrieving address:', error);
        throw error;
    }
};

export const uniqueIdArray = (arr: any) => {
    const uniqueArray = arr.filter((obj: any, index: number, self: any) => {
        return index === self.findIndex((o: any) => o?.id === obj?.id);
    });
    return uniqueArray
}

export const hasAddressData = (data: any) => (data?.address || data?.city || data?.country || data?.postcode) || false;

export const renderAddressDetail = (data: any, isPostCode?: boolean) => {
    let address = '';
    if (data?.address || data?.city || data?.country || data?.postcode) {
        address = `${data?.address || ''}${(data?.address && data?.city) ? ',' : ''} ${data?.city || ''}${(data?.city && data?.country) ? ',' : ''} ${data?.country || ''}${(isPostCode && data?.postcode && data?.country) ? ',' : ''} ${isPostCode && data?.postcode ? data?.postcode : ''}`
    }
    return address;
}