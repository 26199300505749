
import { apiConstants } from '../utils/apiConstants';
import * as yup from "yup";
import { errorMessage } from '../utils/constants';
import moment from 'moment';
import { getNumberWithIsd } from '../utils';

import React from 'react';


export const signUpFormData = () => {
    return {
        [apiConstants.full_name]: '',
        [apiConstants.email]: '',
        [apiConstants.password]: '',
    }
};
export const checkEmailFormData = (data: any) => {
    return {
        [apiConstants.email]: data?.email || ''
    }
};
export const signInFormData = (data?: any) => {
    return {
        [apiConstants.email]: data?.email || '',
        [apiConstants.password]: '',
    }
};
export const ownerFormData = (data: any) => {

    const ownerProfile = data?.owner_profile

    let mobileNumber = '';
    if (data?.mobile_number) {
        const [isdCode, contact]: any = getNumberWithIsd(data?.mobile_number);
        mobileNumber = contact;
    }

    return {
        [apiConstants.company_logo]: ownerProfile?.[apiConstants.company_logo] || '',
        [apiConstants.company_name]: ownerProfile?.[apiConstants.company_name] || '',
        [apiConstants.contact_person]: ownerProfile?.[apiConstants.contact_person] || '',
        [apiConstants.mobile_number]: mobileNumber || '',
        [apiConstants.email]: data?.email || ''
    }
};
export const ownerAddressFormData = (data: any) => {
    return {
        [apiConstants.address]: data?.[apiConstants.address] || '',
        [apiConstants.city]: data?.[apiConstants.city] || '',
        [apiConstants.post_code]: data?.[apiConstants.post_code] || '',
    }
};
export const ownerRateFormData = (data: any) => {

    const ownerProfile = data?.owner_profile

    return {
        [apiConstants.sixth_day_earning_rates]: ownerProfile?.[apiConstants.sixth_day_earning_rates] || '',
        [apiConstants.seventh_day_earning_rates]: ownerProfile?.[apiConstants.seventh_day_earning_rates] || '',
    }
};
export const supplierFormData = (data: any) => {

    const supplierProfile = data?.supplier_profile

    return {
        [apiConstants.company_logo]: supplierProfile?.[apiConstants.company_logo] || '',
        [apiConstants.company_name]: supplierProfile?.[apiConstants.company_name] || '',
        [apiConstants.bio]: supplierProfile?.[apiConstants.bio] || '',
        [apiConstants.email]: data?.email || ''
    }
};
export const supplierInfoData = (data: any) => {

    const supplierProfile = data?.supplier_profile;


    let mobileNumber = '';
    if (data?.mobile_number) {
        const [isdCode, contact]: any = getNumberWithIsd(data?.mobile_number);
        mobileNumber = contact;
    }

    return {
        [apiConstants.profile_image]: data?.[apiConstants.profile_image] || '',
        [apiConstants.mobile_number]: mobileNumber || '',
        [apiConstants.website]: supplierProfile?.[apiConstants.website] || '',
        [apiConstants.contact_person]: `${data?.first_name || ''} ${data?.last_name || ''}`,
    }
};

export const addServicesFormData = () => {
    return {
        [apiConstants.service]: '',
    }
};

export const addProductFormData = () => {
    return {
        [apiConstants.product]: '',
    }
};
export const createJob = (data: any) => {
    return {
        [apiConstants.image]: data?.[apiConstants.image] || '',
        [apiConstants.title]: data?.[apiConstants.title] || '',
        [apiConstants.department]: data?.[apiConstants.department] || '',
        [apiConstants.daily_rate]: data?.[apiConstants.daily_rate] || '',
        [apiConstants.location]: data?.[apiConstants.location] || '',
        [apiConstants.number_of_positions]: data?.[apiConstants.number_of_positions] || '',
        [apiConstants.start_date]: moment(data?.[apiConstants.start_date]) || '',
        [apiConstants.end_date]: moment(data?.[apiConstants.end_date]) || '',
        [apiConstants.description]: data?.[apiConstants.description] || '',
    }
};

export const createUser = () => {
    return {
        [apiConstants.email]: '',
        [apiConstants.first_name]: '',
        [apiConstants.last_name]: '',
    }
}

export const createJobSchema = () => {
    return yup.object().shape({
        // [apiConstants.image]: yup.string().required(errorMessage.required_validation('Image')),
        [apiConstants.department]: yup.string().required(errorMessage.required_validation('Department')),
        [apiConstants.title]: yup.string().required(errorMessage.required_validation('Job title')),
        [apiConstants.daily_rate]: yup.string().required(errorMessage.required_validation('Daily rate')).matches(new RegExp(/^[+]?([.]\d+|\d+[.]?\d*)$/), 'Daily rate must be positive'),
        [apiConstants.number_of_positions]: yup.string().required(errorMessage.required_validation('Number of positions')).matches(new RegExp(/^[+]?([.]\d+|\d+[.]?\d*)$/), 'Number of positions must be positive'),
        [apiConstants.location]: yup.string().required(errorMessage.required_validation('Location')),
        [apiConstants.start_date]: yup.string().required(errorMessage.required_validation('Start date')),
        [apiConstants.end_date]: yup.string().required(errorMessage.required_validation('End date')),
    }).required();
};
export const createEventSchema = () => {
    return yup.object().shape({
        // [apiConstants.image]: yup.string().required(errorMessage.required_validation('Image')),
        [apiConstants.event_type]: yup.string().required(errorMessage.required_validation('Event type')),
        // [apiConstants.department]: yup.string().required(errorMessage.required_validation('Department')),
        [apiConstants.department]: yup.array().min(1, errorMessage.selectFieldRequired("department")),
        [apiConstants.eventStartTime]: yup.string().required(errorMessage.required_validation('Start time')),
        [apiConstants.eventEndTime]: yup.string().required(errorMessage.required_validation('End time')),
        [apiConstants.event_start_date]: yup.string().required(errorMessage.required_validation('Date')),
        [apiConstants.eventName]: yup.string().required(errorMessage.required_validation('Event Name')),
        [apiConstants.eventSubCategory]: yup.string().required(errorMessage.required_validation('Event Sub Category')),
        //[apiConstants.meeting_link]: yup.string().required(errorMessage.required_validation('Meeting Link')),
        [apiConstants.description]: yup.string().required(errorMessage.required_validation('Event Description')),
        [apiConstants.evt_timezone]: yup.string().required(errorMessage.required_validation('Event Timezone')),
        //[apiConstants.event_address]: yup.string().required(errorMessage.required_validation('Location'))
    }).required();
};

export const changePassFormData = () => {
    return {
        [apiConstants.old_password]: '',
        [apiConstants.password]: '',
    }
};

export const verifyPwdData = () => {
    return {
        [apiConstants.password]: ''
    }
}
export const verifyPin = () => {
    return {
        [apiConstants.pin]: ''
    }
}

export const newBroadCastStepOneFormData = () => {
    return {
        [apiConstants.department]: [],
        [apiConstants.roles]: [],
        [apiConstants.user_ids]: [],
    }
};

export const newBroadCastStepTwoFormData = () => {
    return {
        [apiConstants.message]: '',
    }
};

export const newBroadCastStepOneSchema = () => {
    return yup.object().shape({
        [apiConstants.department]: yup.array().min(1, errorMessage.selectFieldRequired("Department")),
        [apiConstants.roles]: yup.array().min(1, errorMessage.selectFieldRequired("Roles")),
        [apiConstants.user_ids]: yup.array().min(1, errorMessage.selectFieldRequired("Crew members")),
    }).required();
};
export const createNewEvent = (data?: any) => {
    return {
        [apiConstants.eventName]: data?.[apiConstants.eventName] || '',
        [apiConstants.eventSubCategory]: data?.sub_category || '',
        [apiConstants.event_type]: data?.type || '',
        //[apiConstants.event_start_date]: data?.start_datetime?.splice(0,10) || '',
        [apiConstants.department]: [],
        // [apiConstants.department]: data?.department || '',
        [apiConstants.meeting_link]: data?.meeting_link || '',
        [apiConstants.eventStartTime]: '',
        [apiConstants.eventEndTime]: '',
        [apiConstants.description]: data?.description || '',
        // [apiConstants.event_location]: handleConvertCoordinates(data?.location.coordinates[0], data?.location.coordinates[1]) || ''
    }
}
