import React, { useEffect, useRef, useState } from 'react'
import { createFullName } from '../../utils';
import { apiConstants } from '../../utils/apiConstants';
import Search from '../../global/ui/Search';
import Loader from '../../global/ui/Loader';
import Logo from '../../components/commonComponent/ProfileImage';

export interface UserFilterProps {
    value: any;
    onChange: (value: any) => void;
    userListData: Array<any>;
    fetchMore: () => void;
    next: any;
    isLoading: any;
    searchValue?: any;
    onSearch?: (value: any) => void;
    showSearch?: boolean;
}

const UserFilter: React.FC<UserFilterProps> = (props) => {

    const ref = useRef<any>(null)

    const [isShow, setIsShow] = useState(false);
    const [userSelectedData, setUserSelectedData] = useState(null);

    const onSelect = (item: any) => {
        setIsShow(false);
        props.onChange(item?.[apiConstants.id] || '')
    }

    useEffect(() => {
        const data = props?.userListData?.find((el) => (el.id == props?.value)) || null;
        setUserSelectedData(data)
    }, [props.value]);

    const style = { width: '30px', height: '30px', borderRadius: '50%' }

    return (
        <div className='custom-dropdonw'>
            <div className={`selct-dropdon d-flex align-items-center ${isShow ? "active" : ""}`} onClick={() => setIsShow((prevState) => !prevState)}>
                <img style={userSelectedData?.[apiConstants.profile_image] ? style : {}} src={userSelectedData?.[apiConstants.profile_image] || "/assets/images/defalt-user-icon.svg"} className='profile-img pe-3' alt="" />
                <h6 className='mb-0'>{userSelectedData ? createFullName(userSelectedData?.[apiConstants.first_name], userSelectedData?.[apiConstants.last_name]) : 'All Users'}</h6>
                <img src="/assets/images/arrow-donw-black.svg" className='arrow-desing ps-2' alt="" />
            </div>
            <div style={{ marginTop: '10px', border: '1px solid #F2F2F2', borderRadius: '5px', display: isShow && props?.showSearch ? 'block' : 'none' }}>
                <Search
                    placeholder={'Search...'}
                    value={props?.searchValue || ''}
                    onChange={(value) => props?.onSearch?.(value) as never}
                />
            </div>
            <ul style={props?.showSearch ? { marginTop: '30px' } : {}} ref={ref}
                onScroll={() => {
                    if (ref.current?.scrollTop + ref.current?.clientHeight >= ref.current?.scrollHeight - 100) {
                        if (props?.isLoading) return;
                        if (props.next) props?.fetchMore?.();
                    }
                }}
                className={`over-driodonw list-unstyled ms-0 mb-0 d-${isShow ? "grid" : "none"}`} >
                {props.userListData?.length > 0
                    ?
                    <>
                        {props.userListData?.map((item, idx) => {
                            return (
                                <li onClick={() => onSelect(item)} key={idx} className='d-flex align-items-center gap-2'>
                                    {item?.[apiConstants.profile_image] ? <img style={item?.[apiConstants.profile_image] ? style : {}} src={item?.[apiConstants.profile_image]} alt="user" />
                                    : <Logo style={{ width: '25px', height: '25px' }} data={item?.[apiConstants.first_name]?.[0] || item?.[apiConstants.last_name]?.[0]}/>}
                                    {createFullName(item?.[apiConstants.first_name], item?.[apiConstants.last_name])}
                                </li>
                            )
                        })}
                    </>
                    :
                    <li>User not found</li>
                }
                {props?.isLoading &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loader imgStyle={{ width: '40px', height: '40px' }} /></div>
                }
            </ul>
        </div>
    )
}

export default UserFilter