import { ApiResponseType } from "../types";
import { showToast } from "../utils";
import { instance } from "../utils/axios";

//use for post method
export const makePostCall = (
    url: string,
    apiPayload: object = {},
    isToast: boolean = false
) => {
    return new Promise(async (resolve: (response: any) => void, reject) => {
        try {
            const response: ApiResponseType = await instance.post(url, apiPayload);
            const statusCode = response?.status?.code;
            const message = response?.status?.message;
            resolve(response);
            if (message && isToast && statusCode === 200) {
                showToast(message, "success");
            }
        } catch (error) {
            reject(error);
        }
    });
};

//use for patch method
export const makePatchCall = (
    url: string,
    apiPayload: object = {},
    isToast: boolean = false
) => {
    return new Promise(async (resolve: (response: any) => void, reject) => {
        try {
            const response: ApiResponseType = await instance.patch(url, apiPayload);
            const statusCode = response?.status?.code;
            const message = response?.status?.message;
            resolve(response);
            if (message && isToast && statusCode === 200) {
                showToast(message, "success");
            }
        } catch (error) {
            reject(error);
        }
    });
};

//use for patch method
export const makePutCall = (
    url: string,
    apiPayload: object = {},
    isToast: boolean = false
) => {
    return new Promise(async (resolve: (response: any) => void, reject) => {
        try {
            const response: ApiResponseType = await instance.put(url, apiPayload);
            const statusCode = response?.status?.code;
            const message = response?.status?.message;
            resolve(response);
            if (message && isToast && statusCode === 200) {
                showToast(message, "success");
            }
        } catch (error) {
            reject(error);
        }
    });
};

//use for delete method
export const makeDeleteCall = (
    url: string,
    apiPayload?: any,
    isToast: boolean = false
) => {
    return new Promise(async (resolve: (response: any) => void, reject) => {
        try {
            const response: ApiResponseType = await instance.delete(url, {
                data: apiPayload,
            });
            const statusCode = response?.status?.code;
            const message = response?.status?.message;
            resolve(response);
            if (message && isToast && statusCode === 200) {
                showToast(message, "success");
            }
        } catch (error) {
            reject(error);
        }
    });
};

//use for get method
export const makeGetCall = (
    url: string,
    params: object | any = {},
    isToast: boolean = false,
    hideGlobalLoader: boolean = false
) => {
    const config: any = {
        options: {
            hideGlobalLoader: hideGlobalLoader
        },
        params: params
    };
    return new Promise(async (resolve: (response: any) => void, reject) => {
        try {
            const response: ApiResponseType = await instance.get(url, config);
            const statusCode = response?.status?.code;
            const message = response?.status?.message;
            resolve(response);
            if (message && isToast && statusCode === 200) {
                showToast(message, "success");
            }
        } catch (error) {
            reject(error);
        }
    });
};

//use for get method
export const makeNextPageCall = (url: string, isToast: boolean = false, hideGlobalLoader: boolean = false) => {
    const config: any = {
        options: {
            hideApiBaseUrl: true,
            hideGlobalLoader: hideGlobalLoader
        },
    };
    return new Promise(async (resolve: (response: any) => void, reject) => {
        try {
            const response: ApiResponseType = await instance.get(url, config);
            const statusCode = response?.status?.code;
            const message = response?.status?.message;
            resolve(response);
            if (message && isToast && statusCode === 200) {
                showToast(message, "success");
            }
        } catch (error) {
            reject(error);
        }
    });
};
