import queryString from "query-string";
import { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  makeGetCall,
  makeNextPageCall,
  makePostCall,
} from "../../effects/requests";
import SideBarLayout from "../../global/layout/SideBarLayout";
import NoData from "../../global/ui/NoData";
import SearchWithHeading from "../../global/ui/SearchWithHeading";
import { ApiResponseType, JOB_SCHEDULE_TYPE, ROLE_TYPE, SCHEDULE_TYPE } from "../../types";
import { apiConstants } from "../../utils/apiConstants";
import { DateUtils } from "../../utils/dateUtils";
import { endPoints } from "../../utils/endpoints";
import AssignJob from "../crewMembers/AssignJob";
import DayEarningRates from "./DayEarningRates";
import ExportData from "./ExportData";
import ScheduleDetails from "./ScheduleDetails";
import ScheduleFilters from "./ScheduleFilters";
import ScheduleItem from "./ScheduleItem";
import TableHeaderData from "./TableHeaderData";
import { buttonsText, cookieServiceParam } from "../../utils/constants";
import NewEvent from "../../components/modal/NewEvent";

import EventScheduleWeek from "./EventScheduleWeek";
import EventScheduleDay from "./EventScheduleDay";
import EventDetail from "../newEvent/EventDetail";
import { MyContext } from "../../context";
import { manipulatedDayData, manipulatedMonthData } from "../../utils/UtilityFunctions";
import { cookieService } from "../../services";
import EventMonthView from "./monthView/EventMonthView";

const Schedule = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const parsed = queryString.parse(location.search);

  const [calData, setCalData] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [calHeaderData, setCalHeaderData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    DateUtils.getCurrentDate().toDate()
  );
  const [nextUrl, setNextUrl] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [calNextUrl, setCalNextUrl] = useState("");
  const [isExport, setIsExport] = useState(false);
  const [isAssignJob, setIsAssignJob] = useState(false);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [scheduleDetails, setScheduleDetails] = useState<any>(null);
  const [isWeekEnd, setIsWeekEnd] = useState(false);
  const [isLoading, setIssLoading] = useState(false);
  const [weekDayNumber, setWeekDayNumber] = useState<number>(-1)
  const [isShow, setIsShow] = useState(false);
  const [noOfHoursInDay, setNoOfHoursInDay] = useState<Array<any>>([]);
  const [weekDatesInMonth, setWeekDatesInMonth] = useState<Array<any>>([]);
  const [shootEventsInDay, setShootEventsInDay] = useState<Array<any>>([]);

  //Important Event - purple card
  const [impEventData, setImpEventData] = useState([]);
  //To know whether its a superuser or not
  const { userData } = useContext(MyContext)
  const isOwner = (userData?.user_type === ROLE_TYPE.TYPE_CREW) ? true : false;
  //Event Details
  const [eventDetail, setEventDetail] = useState(null)
  //Event Status - For Supplier View
  const [eventStatus, setEventStatus] = useState(null);
  //Supplier ID - For Supplier View
  const [supplierID, setSupplierID] = useState(null);
  //All Event Schedule List 
  const [allEventListData, setAllEventListData] = useState([])
  //Show Edit Pop up 
  const [isEditShow, setIsEditShow] = useState(false);
  const [isEditEventShow, setIsEditEventShow] = useState(false);
  const [isEventReqPending, setIsEventReqPending] = useState(false);
  //Schedule Crew - Event toggle
  const [scheduleType, setScheduleType] = useState(isOwner ? SCHEDULE_TYPE.CREW_SCH : SCHEDULE_TYPE.EVENT_SCH)
  //Calender Type - Day - Week- Month
  const [calendarType, setCalendarType] = useState('Week');
  //Month View Handling++++++
  const [currentMonth, setCurrentMonth] = useState(selectedDate.getMonth());
  const [currentYear, setCurrentYear] = useState(selectedDate.getFullYear());

  useEffect(() => {
    if (location?.state?.eventId) {
      setTimeout(() => {
        fetchEventDetail(false, location?.state?.eventId)
      }, 1000)
    }
  }, [location])
  const getScheduleData = () => {
    const payLoad: any = {
      start_date: DateUtils.startOfWeek(selectedDate, 'DD-MM-YYYY'),
      end_date: DateUtils.endOfWeek(selectedDate, 'DD-MM-YYYY'),
      production_company_name: userData?.owner_profile?.company_name || '',
      ...parsed,
    };
    // if(calendarType === 'Month'){
    //   payLoad.start_date = `01-${currentMonth+1}-${currentYear}`,
    //   payLoad.end_date = `${daysInMonth}-${currentMonth+1}-${currentYear}`
    // }
    if (userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER) {
      const payLoad = {
        start_date_gte: calendarType === 'Day' ? DateUtils.formatDateTime(selectedDate, 'YYYY-MM-DD') : calendarType === 'Month' ? DateUtils.startOfMonth(selectedDate, 'YYYY-MM-DD') : DateUtils.startOfWeek(selectedDate, 'YYYY-MM-DD'),
        start_date_lte: calendarType === 'Day' ? DateUtils.formatDateTime(selectedDate, 'YYYY-MM-DD') : calendarType === 'Month' ? DateUtils.endOfMonth(selectedDate, 'YYYY-MM-DD') : DateUtils.endOfWeek(selectedDate, 'YYYY-MM-DD'),
        ...parsed,
      }
      makeGetCall(endPoints.SCHEDULE, payLoad).then((res => {
        if (calendarType === 'Day') {
          updateDayHoursList(res)
        } else if (calendarType === 'Month') {
          updateWeekDatesInMonth(res)
        } else {
          setAllEventListData(res)
        }
      }))
    } else {
      if (scheduleType === SCHEDULE_TYPE.CREW_SCH) {
        makeGetCall(endPoints.USER_SCHEDULE, payLoad).then(
          (res: ApiResponseType<any>) => {
            setImpEventData(res?.shoot_data)
            setCalData(res?.results);
            setCalNextUrl(res?.next || "");
          }
        );
      } else if (scheduleType === SCHEDULE_TYPE.EVENT_SCH) {
        if (calendarType === 'Month') {
          payLoad.start_date = DateUtils.startOfMonth(selectedDate, 'DD-MM-YYYY');
          payLoad.end_date = DateUtils.endOfMonth(selectedDate, 'DD-MM-YYYY');
        } else if (calendarType === 'Day') {
          payLoad.start_date = DateUtils.formatDateTime(selectedDate, 'DD-MM-YYYY');
          payLoad.end_date = DateUtils.formatDateTime(selectedDate, 'DD-MM-YYYY');
        }
        makeGetCall(endPoints.EVENT, payLoad).then(
          (res) => {
            if (calendarType === 'Day') {
              updateDayHoursList(res)
            } else if (calendarType === 'Month') {
              updateWeekDatesInMonth(res)
            } else {
              setAllEventListData(res)
            }
          }
        )
      }
    }
  };

  const getWeekData = () => {
    const dates = DateUtils.getDatesOfDays(selectedDate);
    setCalHeaderData(dates);
  };
  const updateUrls = (key: any, value: any) => {
    const params = parsed;
    params[key] = value;
    navigate(`/schedule/?${createSearchParams(params as never)}`);
  };

  const getUserList = (query?: any) => {
    const params = {
      user_type: ROLE_TYPE.TYPE_CREW,
      search: query || '',
      production_company_name: userData?.owner_profile?.company_name
    }
    makeGetCall(endPoints.USERLIST, params).then((res: ApiResponseType<any>) => {
      setUserListData(res?.results);
      setNextUrl(res?.next || '')
    })
  }

  const fetchMore = () => {
    if (nextUrl) {
      setIssLoading(true)
      makeNextPageCall(nextUrl, false, true).then((res: ApiResponseType<any>) => {
        if (res?.next) {
          setIssLoading(false)
          const tempUser: any = [...userListData, ...res?.results];
          setUserListData(tempUser)
          setNextUrl(res?.next)
        }
      }).catch(() => setIssLoading(false))
    }
  };

  const onCardClick = (status: any, data: any) => {
    switch (status) {
      case JOB_SCHEDULE_TYPE.TYPE_AVAILABLE:
      case JOB_SCHEDULE_TYPE.TYPE_REST:
        if (DateUtils.getDayOfWeek(data?.key) === 0 || DateUtils.getDayOfWeek(data?.key) === 6) {
          setScheduleDetails(data)
          setWeekDayNumber(DateUtils.getDayOfWeek(data?.key))
          // setIsWeekEnd(true)
          setIsShow(true) //to show New Event pop up
        }
        else {
          setScheduleDetails(data)
          // setIsAssignJob(true)
          setIsShow(true)  //to show New Event pop up
        }
        break;

      case JOB_SCHEDULE_TYPE.TYPE_JOB:
        setScheduleDetails(data);
        setIsShowDetail(true);
        break;
    }
  };

  const fetchMoreData = () => {
    if (!calNextUrl) return;
    makeNextPageCall(calNextUrl).then((res: ApiResponseType<any>) => {
      const data = [...calData, ...res.results];
      setCalNextUrl(res?.next);
      setCalData(data as never);
    });
  };

  const handleAssignJob = (data: any) => {
    setIsAssignJob(false);
    const payload = {
      job: data?.id || "",
      crew_member: scheduleDetails?.id || "",
    };
    makePostCall(endPoints.JOB_REQUEST, payload, true).then(
      (res: ApiResponseType<any>) => {
        setScheduleDetails(null);
        getScheduleData();
      }
    );
  };

  useEffect(() => {
    if (userData) {
      getWeekData();
      getScheduleData();
    }
  }, [selectedDate, JSON.stringify(parsed), scheduleType, calendarType, userData]);

  useEffect(() => {
    setTimeout(() => {
      if (!cookieService.getCookie(cookieServiceParam.subscription_id)) {
        navigate(cookieService.getCookie(cookieServiceParam.account_completed) ? '/expire-subscription' : '/subscription')
      }
    }, 1000)
  }, [])
  const updateDayHoursList = (res: any) => {
    const selectedDateKey: any = DateUtils.formatDateTime(selectedDate, 'YYYY-MM-DD')
    const noOfHoursInDay = DateUtils.getNoOfHoursInDay(selectedDate);
    const [dayFinalData, shootEvents] = manipulatedDayData(noOfHoursInDay, res?.data || [], res?.date_data?.[selectedDateKey]);
    setNoOfHoursInDay(dayFinalData);
    setShootEventsInDay(shootEvents);
  }

  const updateWeekDatesInMonth = (res: any) => {
    const weekDatesInMonth = DateUtils.getWeekDatesInMonth(selectedDate)
    const finalData = manipulatedMonthData(weekDatesInMonth, res?.data || [], res?.date_data || []);
    setWeekDatesInMonth(finalData);
  }

  useEffect(getUserList, [userData]);

  const fetchEventDetail = (req: boolean, id: any, eventStatus?: any, supplierID?: any) => {
    setEventStatus(eventStatus);
    setSupplierID(supplierID);
    const url = `${endPoints.EVENT}${id}/`
    makeGetCall(url).then((res) => {
      setEventDetail(res?.data);
      setIsEditShow(!isEditShow)
    })
  }

  const evtDetails = (id: any) => {
    const url = `${endPoints.EVENT}/${id}`
    makeGetCall(url).then((res) => {
      setEventDetail(res?.data);
      setIsShow(!isShow);
      setIsEditEventShow(true);
    })
  }
  const resetEventDetail = () => {
    setIsShow(!isShow)
    setEventDetail(null)
  }
  const clearAll = () => {
    const params = {
      department: '',
      role: '',
      id: ''

    };

    navigate(`/schedule/?${createSearchParams(params as never)}`);
  }

  return (
    <SideBarLayout>
      <div className="right-content-full-screen">
        <SearchWithHeading
          placeholder="Search by name, email or phone"
          onSearch={(value) => updateUrls(apiConstants.search, value)}
          value={parsed?.[apiConstants.search]}
          heading="Schedule"
          addText={buttonsText.newEvent}
          onAdd={() => { resetEventDetail(); }}
        />
        <div className="bottom-content-full-screen">
          <ScheduleFilters
            value={parsed}
            onChange={updateUrls}
            clearAll={clearAll}
            onExport={() => setIsExport(true)}
            type={scheduleType}
            changeScheduleType={(type: any) => {
              setScheduleType(type)
              setCalendarType('Week');
            }}
            userFilterData={{
              next: nextUrl,
              fetchMore: fetchMore,
              userListData: userListData,
              value: parsed?.[apiConstants.user] || '',
              onChange: (value: any) => updateUrls(apiConstants.id, value),
              isLoading: isLoading,
              onSearch: (query) => {
                setSearchValue(query)
                getUserList(query)
              },
              showSearch: true,
              searchValue: searchValue
            }}
            scheduleType={scheduleType}
            isOwner={isOwner}
          />
          <div className="date-part-top-design d-flex align-item-center justify-content-between" >
            <div className="d-flex align-item-center" style={{ width: '400px' }}>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (calendarType === 'Day')
                    setSelectedDate(DateUtils.getPreviousDay(selectedDate))
                  if (calendarType === 'Week')
                    setSelectedDate(DateUtils.getPreviousWeek(selectedDate))
                  if (calendarType === 'Month') {
                    setSelectedDate(DateUtils.getPreviousMonth(selectedDate))
                    if (currentMonth === 0) {
                      setCurrentMonth(11);
                      setCurrentYear(currentYear - 1);
                    } else {
                      setCurrentMonth(currentMonth - 1);
                    }
                  }
                }
                }
                src="/assets/images/play-icon-dark.svg"
                className="left-side-mo"
                alt=""
              />
              {calendarType === 'Day' && <p className="mb-0 extra-padding-top">{`${DateUtils.startdate(
                selectedDate, "DD MMMM")}`}</p>}
              {calendarType === 'Week' && <p className="mb-0 extra-padding-top">{`${DateUtils.startOfWeek(
                selectedDate,
                "DD MMM, YY"
              )} - ${DateUtils.endOfWeek(selectedDate, "DD MMM, YY")}`}</p>}
              {calendarType === 'Month' &&
                <p className="mb-0 extra-padding-top">
                  {`${DateUtils.formatDateTime(selectedDate, "MMMM YYYY")}`}
                </p>
              }
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (calendarType === 'Day')
                    setSelectedDate(DateUtils.getNextDay(selectedDate))
                  if (calendarType === 'Week')
                    setSelectedDate(DateUtils.getNextWeek(selectedDate))
                  if (calendarType === 'Month') {
                    setSelectedDate(DateUtils.getNextMonth(selectedDate))
                    if (currentMonth === 11) {
                      setCurrentMonth(0);
                      setCurrentYear(currentYear + 1);
                    } else {
                      setCurrentMonth(currentMonth + 1);
                    }
                  }
                }
                }
                src="/assets/images/play-icon-dark.svg"
                className="right-side-mo"
                alt=""
              /></div>
            {/* {scheduleType === SCHEDULE_TYPE.EVENT_SCH &&
              <div>
                <button className={`calendar-type ${calendarType === 'Day' ? 'active-calendar-type' : ''}`} onClick={() => setCalendarType('Day')}>Day</button>
                <button className={`calendar-type ${calendarType === 'Week' ? 'active-calendar-type' : ''}`} onClick={() => setCalendarType('Week')}>Week</button>
                <button className={`calendar-type ${calendarType === 'Month' ? 'active-calendar-type' : ''}`} onClick={() => setCalendarType('Month')}>Month</button>
              </div>
            } */}
            {isEditShow &&
              <EventDetail
                visible={isEditShow}
                onClose={() => setIsEditShow(!isEditShow)}
                onAdd={(id: any) => {
                  setIsShow(!isShow);
                  setIsEditShow(!isEditShow);
                  evtDetails(id)
                }}
                eventStatus={eventStatus}
                supplierID={supplierID}
                eventDetail={eventDetail}
                getScheduleData={getScheduleData} />
            }
          </div>
          {scheduleType === SCHEDULE_TYPE.CREW_SCH &&
            <div className="main-collender">
              {calData?.length > 0 ? (
                <Table responsive borderless className="" cellSpacing={"0"}>
                  <TableHeaderData
                    data={calHeaderData}
                    impEventData={impEventData}
                    editFlow={isEditShow}
                    onAdd={(id: any) => { evtDetails(id); }}
                    onEventView={(id: any) => { fetchEventDetail(false, id) }}
                  />
                  <tbody id="scrollableDiv">
                    <InfiniteScroll
                      dataLength={calData?.length}
                      next={fetchMoreData}
                      hasMore={nextUrl ? true : false}
                      loader={null}
                      scrollableTarget="scrollableDiv"
                    >
                      {calData?.map((item, idx) => {

                        return (
                          <ScheduleItem
                            onClick={onCardClick}
                            item={item}
                            key={idx}
                            calHeaderData={calHeaderData}
                            index={idx}
                            onEventView={(id: any) => { fetchEventDetail(false, id) }}
                          />
                        );
                      })}
                    </InfiniteScroll>
                  </tbody>
                </Table>
              ) : (
                <NoData />
              )}
            </div>}
          {scheduleType === SCHEDULE_TYPE.EVENT_SCH &&
            <div>
              {calendarType === 'Day' &&
                <EventScheduleDay
                  onView={(evt) => fetchEventDetail(false, evt?.id)}
                  allData={noOfHoursInDay}
                  shootEvents={shootEventsInDay}
                  onEdit={(evt) => evtDetails(evt?.id)}
                />
              }
              {calendarType === 'Week' &&
                <EventScheduleWeek
                  data={calHeaderData}
                  onEventView={(id: any, eventStatus: any, supplierID?: any) => { fetchEventDetail(false, id, eventStatus, supplierID) }}
                  editFlow={isEditShow}
                  onAdd={(id: any) => { evtDetails(id) }}
                  isOwner={isOwner}
                  scheduleType={scheduleType}
                  allEventListData={allEventListData}
                  newEventShow={() => {
                    setEventDetail(null)
                    setIsShow(!isShow)
                  }}
                />
              }
              {calendarType === 'Month' &&
                <EventMonthView
                  selectedDate={selectedDate}
                  allData={weekDatesInMonth}
                  onView={(evt) => fetchEventDetail(false, evt?.id)}
                  onDaySelect={(date: Date) => {
                    setSelectedDate(date);
                    setCalendarType('Day')
                  }}
                />
              }
            </div>
          }
        </div>
      </div>
      <ExportData isShow={isExport} onClose={() => setIsExport(false)} type={scheduleType} />
      <ScheduleDetails
        isShow={isShowDetail}
        onClose={() => {
          setIsShowDetail(false);
          setScheduleDetails(null);
        }}
        scheduleDetails={scheduleDetails}
      />
      <AssignJob
        onClose={() => {
          setIsAssignJob(false);
          setScheduleDetails(null);
        }}
        onSubmit={handleAssignJob}
        isShow={isAssignJob}
      />
      <DayEarningRates
        onClose={() => {
          setIsWeekEnd(false);
          setScheduleDetails(null);
        }}
        onSubmit={() => {
          setIsAssignJob(true);
          setIsWeekEnd(false);
        }}
        isShow={isWeekEnd}
        weekDayNumber={weekDayNumber}
        scheduleDetails={scheduleDetails}
      />
      {isShow &&
        <NewEvent
          isShow={isShow}
          onClose={() => {
            setIsShow(false);
            setIsEditEventShow(false);
            getScheduleData();
          }}
          getScheduleData={getScheduleData}
          editFlow={isEditShow || isEditEventShow}
          data={eventDetail}
          setEventDetail={setEventDetail}
        />
      }
    </SideBarLayout>
  );
};

export default Schedule;