import React, { useState } from 'react'
import { USER_PRESS_ACTION_TYPE } from '../../types';
import { showToast } from '../../utils';
import { Modal } from 'react-bootstrap';
import FlagConfirm from '../../components/modal/FlagConfirm';
import Logo from '../../components/commonComponent/ProfileImage';

interface UserBasicInfoProps {
    userData: any;
    onClose: () => void;
    onAction: (type: any) => void;
    showMoreMenu?: any;
    isActive?: boolean;
    isSupplier?: boolean;
}

const UserBasicInfo: React.FC<UserBasicInfoProps> = (props) => {

    const [showMoreOption, setShowMoreOption] = useState(false);
    const [confirmFlag, setConfirmFlag] = useState(false);

    const onAction = (type: any) => {
        setShowMoreOption(false);
        props?.onAction?.(type)
    }
    return (
        <div className="popup-right-side-top">
            <div className='cross-and-dropdonw-selct d-flex justify-content-between align-items-center mb-0'>
                <img src={"/assets/images/dismiss-cross-black.svg"} alt="" className="img-cross-icon" onClick={props.onClose} />
                <div className='filter-select'>
                {!props?.isSupplier && <img onClick={() => setShowMoreOption((prevState) => !prevState)} src={"/assets/images/more-three-dots.svg"} className="main-drop-donwe" alt="" />}
                    <div className={`drop-down-dtost  ${showMoreOption ? "d-block" : "d-none"} `}>
                        <ul className="d-grid list-unstyled pl-0 mb-0">
                            {props?.showMoreMenu &&
                                <>
                                    {/* <li onClick={() => onAction?.(props.isActive ? USER_PRESS_ACTION_TYPE.TYPE_INACTIVE_USER : USER_PRESS_ACTION_TYPE.TYPE_ACTIVE_USER)} ><img src={"/assets/images/activat-user-black.svg"} alt="" />{props.isActive ? 'Deactivate User' : 'Activate User'}</li> */}
                                   <li onClick={() => {props?.userData?.account_completed ? onAction?.(USER_PRESS_ACTION_TYPE.TYPE_UPDATE_ROLE) : showToast("Able to update the role once the profile is created completely", "info")}} ><img src={"/assets/images/update-role.svg"} alt="" />Update Role</li>
                                </>
                            }
                            <li onClick={() => 
                                {   setConfirmFlag(!confirmFlag);
                                    // onAction?.(USER_PRESS_ACTION_TYPE.TYPE_FLAG)
                                }
                                } ><img src={"/assets/images/Flag-red.svg"} alt="" />Flag User</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="profile-show-part text-center">
                {props?.userData?.logo ? <img className="profile-img" src={props?.userData?.logo} width="87" height="87" alt="" />
                : <Logo style={{ width: '70px', height: '70px' }} data={props?.userData?.name?.[0]}/> }
                <h5>{props.userData?.name}</h5>
                {props.userData?.dep && <button className="perple-button mb-3">{props.userData?.dep}</button>}
                <ul className="list-unstyled d-flex flex-wrap align-items-center justify-content-center">
                    
                   {props.userData?.website?.length>0 && <li><button type='button' onClick={() => onAction?.(USER_PRESS_ACTION_TYPE.TYPE_WEBSITE)} className="btn btn-light-with-icon shadow-none"><img src={"/assets/images/link-icon.svg"} alt="" />{props.userData?.linkTitle}</button></li>}
                    <li><button type='button' onClick={() => onAction?.(USER_PRESS_ACTION_TYPE.TYPE_MESSAGE)} className="btn btn-dark-button shadow-none"><img src={"/assets/images/chat-message-icon.svg"} alt="" />Message</button></li>
                </ul>
            </div>
           <FlagConfirm
            show={confirmFlag}
            onClose = {()=>setConfirmFlag(!confirmFlag)}
            handleAction= {() => {
                onAction?.(USER_PRESS_ACTION_TYPE.TYPE_FLAG); 
                setConfirmFlag(!confirmFlag);
            }}
           />                     
           
        </div>
    )
}

export default UserBasicInfo