import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../global/ui/Button";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Tab,
  Tabs,
} from "react-bootstrap";
import InputField from "../../lib/input";
import { apiConstants } from "../../utils/apiConstants";
import { FormProvider, useForm } from "react-hook-form";
import { createEventSchema, createNewEvent } from "../../data/formData";
import { labels, placeholder } from "../../utils/constants";
import DropDown from "../../global/ui/dropDown";
import { eventTypeList, preConfigDeptData, timeSlots, timezone } from "../../data/data";
import DatePicker from "../../global/ui/DatePicker";
import Search from "../../global/ui/Search";
import CrewMemberList from "../../pages/newEvent/CrewMemberList";
import { ApiResponseType, BUTTON_TYPE, ROLE_TYPE, USER_TYPE } from "../../types";
import SelectedMembers from "../../pages/newEvent/SelectedMembers";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeDeleteCall, makeGetCall, makeNextPageCall, makePatchCall, makePostCall } from "../../effects/requests";
import { endPoints } from "../../utils/endpoints";
import { MyContext } from "../../context";
import moment from "moment";
import { setDropdownSelectedEvent, setDropdownSelectedEventTime, setDropdownSelectedValue, showToast, uniqueIdArray } from "../../utils";
import LocationInput from "../../pages/newEvent/googleLocation";
import useDebounce from "../../hooks/useDebounce";
import { DateType } from "../../utils/dateUtils";
import { generateFormData } from "../../utils";

const NewEvent = (props: any) => {
  const { userData } = useContext(MyContext)
  const [allDept, setAllDept] = useState(preConfigDeptData);
  const [selectedTab, setSelectedTab] = useState('Crew');
  const [selectedCrew, setSelectedCrew] = useState<any>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<any>([]);
  const [supplierList, setSupplierList] = useState([]);
  const [nextUrlSupplier, setNextUrlSupplier] = useState('')
  const [crewList, setCrewList] = useState([]);
  const [nextUrlCrew, setNextUrlCrew] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [gpsLocation, updateLocation] = useState<any>('');
  const [address, setAddress] = useState('')
  const [formData, setFormData] = useState(undefined)
  const [searchParam, setSearchParam] = useState('');
  const [confirmIndicator, setConfirmIndicator] = useState(false);
  const [selectedMemberTime, setSelectedMemberTime] = useState({
    start_time: '',
    end_time: '',
  })
  
  const debouncedValue = useDebounce(searchParam, 500)
  const inputRef = useRef<HTMLTextAreaElement>(null); 
  const { ...methods } = useForm({
    mode: "all",
    defaultValues: { ...createNewEvent() },
    reValidateMode: "onBlur",
    resolver: yupResolver(createEventSchema()),
  });
  useEffect(() => {
    if (methods.getValues(apiConstants.event_start_date) && methods.getValues(apiConstants.eventStartTime) && methods.getValues(apiConstants.eventEndTime)) {
      makeGetCall(endPoints.USERLIST, {
        user_type: USER_TYPE.TYPE_SUPPLIER,
        start: moment(methods.getValues(apiConstants.event_start_date)).format('DD-MM-YYYY') + " " + methods.getValues(apiConstants.eventStartTime),
        end: moment(methods.getValues(apiConstants.event_start_date)).format('DD-MM-YYYY') + " " + methods.getValues(apiConstants.eventEndTime),
        search: debouncedValue
      })
        .then((res) => {
          res?.results.forEach((obj: any) => { 
            obj['start_time'] = setDropdownSelectedEventTime(timeSlots, methods.getValues(apiConstants.eventStartTime)).value; 
            obj['end_time'] = setDropdownSelectedEventTime(timeSlots, methods.getValues(apiConstants.eventEndTime)).value; })
          setSupplierList(res?.results)
          setNextUrlSupplier(res?.next || '')
          setFormData(props.data ? props.data : undefined)
        })
        .catch((err) => {

        })
    }
  }, [methods.getValues(apiConstants.event_start_date), debouncedValue, methods.getValues(apiConstants.eventStartTime), methods.getValues(apiConstants.eventEndTime)])
  useEffect(() => {
    if (!props?.editFlow) {
      props?.setEventDetail(null)
    }
    valueSet();
  }, [props?.isShow])

  const valueSet = () => {
    const dept = getSelectedDept(props?.data?.department);
    methods.setValue(apiConstants.eventName, props?.data?.name || '')
    methods.setValue(apiConstants.eventSubCategory, props?.data?.sub_category || '')
    methods.setValue(apiConstants.event_type, props?.data?.type)
    methods.setValue(apiConstants.meeting_link, props?.data?.meeting_link || '')
    methods.setValue(apiConstants.description, props?.data?.description || '')
    methods.setValue(apiConstants.event_start_date, props?.data?.start_datetime ? moment(props?.data?.start_datetime) : '')
    methods.setValue(apiConstants.department, dept?.length > 0 ? dept : [])
    methods.setValue(apiConstants.event_address, props?.data?.address || '')
    methods.setValue(apiConstants.evt_timezone, props?.data?.time_zone || '')
    methods.setValue(apiConstants.eventStartTime, props?.data?.start_datetime ? props?.data?.start_datetime.substring(11, 16) : '')
    methods.setValue(apiConstants.eventEndTime, props?.data?.end_datetime ? props?.data?.end_datetime.substring(11, 16) : '')
    //methods.setValue(apiConstants.location , handleConvertCoordinates(props?.data?.location.coordinates[0], props?.data?.location.coordinates[1]))
    //if(props?.editFlow === 'Edit Event')
    setAddress(props?.data?.address || '');
    updateLocation(props?.data?.location);
  }

  const getSelectedDept = (dept: Array<any>) => {
    const selecetdDept: Array<any> = []
    preConfigDeptData?.map((item) => {
      if (dept?.includes(item?.label)) {
        selecetdDept.push(item);
      }
    })
    return selecetdDept
  }

  //},[props?.data])
  const checkAvailabilityOfAssignee = (selectedId:any, type:any) =>{
    if(selectedId.length>0){
    makeGetCall(endPoints.USERLIST,{
      start: moment(methods.getValues(apiConstants.event_start_date)).format('DD-MM-YYYY')  + " " + methods.getValues(apiConstants.eventStartTime),
      end: moment(methods.getValues(apiConstants.event_start_date)).format('DD-MM-YYYY')  + " " + methods.getValues(apiConstants.eventEndTime),
      user_ids :JSON.stringify(selectedId)
    })
    .then((res)=>{
      //res?.results.forEach((obj: any) => { obj['available'] = obj?.available ; obj['seventh_day'] = obj?.seventh_day ; obj['sixth_day'] = obj?.sixth_day ;  });
      //console.log("updated supplier", res?.results)
      res?.results.forEach((elem:any) => {
        var idx = selectedCrew.findIndex((x:any)=> {
        return (x?.assigned_member?.id === elem?.id || x?.id === elem?.id)
      })
        if(idx>-1){
        selectedCrew[idx].available = elem.available;
        selectedCrew[idx].seventh_day = elem.seventh_day;
        selectedCrew[idx].sixth_day = elem.sixth_day;}
        })
      
    })
    .catch((err)=>{

    })
    
  }
  }
  const updatedAssignee = () => {
    const selectedCrewId:any = [];
    const selectedSupplierId:any = [];
    //if(props?.editFlow){
      selectedCrew.map((item:any)=>{
        selectedCrewId.push(item?.assigned_member?.id || item?.id as String)
      })
      selectedSupplier.map((item:any)=>{
        selectedSupplierId.push(item?.assigned_member?.id || item?.id as String)
      })
      checkAvailabilityOfAssignee(selectedCrewId,ROLE_TYPE.TYPE_CREW)
      //checkAvailabilityOfAssignee(selectedSupplierId,ROLE_TYPE.TYPE_SUPPLIER)
    //}
  }
  useEffect(() => { 
    //if(props?.editFlow){
      updatedAssignee()
    //}
  }, [methods.getValues(apiConstants.event_start_date)])
  useEffect(() => {
    if (props?.data?.id) {
      getSelectedAssignee(USER_TYPE.TYPE_CREW);
      getSelectedAssignee(USER_TYPE.TYPE_SUPPLIER);
    }
  }, [props?.data?.id])
  const getSelectedAssignee = (type: any) => {
    makeGetCall(endPoints.CREATE_EVENT_ASSIGNEE, { user_type: type, event: props?.data?.id })
      .then((res) => {
        res?.results.forEach((obj: any) => { obj['event_created_already'] = true;
      //if(type === USER_TYPE.TYPE_CREW){
        obj['available'] = true;
        obj['start_time'] =  setDropdownSelectedEventTime(timeSlots, obj?.start_time.substring(11, 16)).value;
        obj['end_time'] = setDropdownSelectedEventTime(timeSlots, obj?.end_time.substring(11, 16)).value;
      //}
     });
        { type === USER_TYPE.TYPE_CREW ? setSelectedCrew(res?.results) : setSelectedSupplier(res?.results) }
      })
  }
  useEffect(() => {
    if (methods.getValues(apiConstants.event_start_date) && methods.getValues(apiConstants.eventStartTime) && methods.getValues(apiConstants.eventEndTime)) {
      makeGetCall(endPoints.USERLIST,
        {
          user_type: USER_TYPE.TYPE_CREW,
          production_company_name: userData?.owner_profile?.company_name,
          start: moment(methods.getValues(apiConstants.event_start_date)).format('DD-MM-YYYY')  + " " + methods.getValues(apiConstants.eventStartTime),
          end: moment(methods.getValues(apiConstants.event_start_date)).format('DD-MM-YYYY')  + " " + methods.getValues(apiConstants.eventEndTime),
          search: debouncedValue
        })
        .then((res) => {
          res?.results.forEach((obj: any) => { 
            obj['start_time'] = setDropdownSelectedEventTime(timeSlots, methods.getValues(apiConstants.eventStartTime)).value; obj['end_time'] = setDropdownSelectedEventTime(timeSlots, methods.getValues(apiConstants.eventEndTime)).value; });
          setCrewList(res?.results)
          setNextUrlCrew(res?.next || '')
        })
        .catch((err) => {

        })
    }
  }, [methods.getValues(apiConstants.event_start_date), debouncedValue, methods.getValues(apiConstants.eventStartTime), methods.getValues(apiConstants.eventEndTime)])

  const fetchMoreData = () => {
    if (!nextUrlSupplier) return;
    makeNextPageCall(nextUrlSupplier).then((res: ApiResponseType<any>) => {
      const data = [...supplierList, ...res.results];
      setNextUrlSupplier(res?.next);
      setSupplierList(data as never);
    });
  };
  const fetchMoreCrewData = () => {
    if (!nextUrlCrew) return;
    makeNextPageCall(nextUrlCrew).then((res: ApiResponseType<any>) => {
      const data = [...supplierList, ...res.results];
      setNextUrlSupplier(res?.next);
      setSupplierList(data as never);
    });
  };
  const tempCrewSelect = (member: any) => {
    setSelectedCrew(uniqueIdArray([...selectedCrew, member]))
  }
  const tempSupplierSelect = (member: any) => {
    setSelectedSupplier(uniqueIdArray([...selectedSupplier, member]))
  }
  const deleteMember = (member: any) => {
    const updateCrewlist = selectedCrew.filter((item: any) => item.id != member.id)
    const updateSupplierlist = selectedSupplier.filter((item: any) => item.id != member.id)
    setSelectedCrew(updateCrewlist);
    setSelectedSupplier(updateSupplierlist);

  }

  const onSubmit = (values: any) => {
    setIsLoading(true)
    const payload = { ...values };
    const dept: any = []  
    if(props?.editFlow){
     var arr=  selectedCrew.filter((obj:any) => {
        if (obj.available === false) {
          makeDeleteCall(`${endPoints.CREATE_EVENT_ASSIGNEE}${obj?.id}`)
        .then((res) => {

        })
        .catch((err) => {

        })
        }
      });
      setSelectedCrew(arr)
      props?.getScheduleData();
    }
    const userList = [...selectedSupplier, ...selectedCrew]
    if (values?.[apiConstants.eventStartTime] > values?.[apiConstants.eventEndTime]) {
      showToast("End time should be greater than start time", "error")
      setIsLoading(false);
      return;
    }
    //const date = DateUtils.formatDateTime(values?.[apiConstants.start_date], 'YYYY-MM-DD')
    const date = moment(values?.[apiConstants.event_start_date]).format('YYYY-MM-DD')

    payload[apiConstants.event_start_date] = date + " " + values?.[apiConstants.eventStartTime];
    payload[apiConstants.event_end_date] = date + " " + values?.[apiConstants.eventEndTime];
    delete payload[apiConstants.eventStartTime];
    delete payload[apiConstants.eventEndTime];
    delete payload[apiConstants.event_location];
    delete payload[apiConstants.department];
    // payload[apiConstants.event_location] = gpsLocation;
    payload[apiConstants.event_address] = address;

    if (values?.department?.length > 0) {
      values?.department?.map((item: any) => {
        dept.push(item?.label);
      })
    }

    const fd = generateFormData(payload);
    fd.append(apiConstants.department, JSON.stringify(dept))
    //gpsLocation === undefined ? '' :fd.append(apiConstants.event_location, JSON.stringify(gpsLocation))
    if(gpsLocation && gpsLocation.coordinates.length>0){
      fd.append(apiConstants.event_location, JSON.stringify(gpsLocation))
    }

    if (props?.editFlow) {
      userList.map((item: any, idx: any) => {
        if (item.event_created_already) {
          console.log("edit")
          if (props?.data?.start_datetime.substring(11, 16) !== methods.getValues(apiConstants.eventStartTime) || props?.data?.end_datetime.substring(11, 16) !== methods.getValues(apiConstants.eventEndTime) 
          || date !== moment(props?.data?.start_datetime).format('YYYY-MM-DD')) {
            
            var params = {
              //event_id: props?.editEventId,
              //assigned_member: item?.id,
              start_time: date + " " + methods.getValues(apiConstants.eventStartTime),
              end_time: date + " " + methods.getValues(apiConstants.eventEndTime)
            }
            makePatchCall(`${endPoints.CREATE_EVENT_ASSIGNEE}${item.id}/`, params)
              .then((res) => {
                //showToast("Time updated successfully","success")
              })
              .catch((err) => {

              })

          }
          if(moment(props?.data?.start_datetime) != methods.getValues(apiConstants.event_start_date)){
            
          }
          props?.getScheduleData();
          return;
        }
        else {
          var param = {
            event_id: props?.data?.id,
            assigned_member: item?.id,
            start_time: date + " " + moment(item.start_time, 'h:mm A').format('HH:mm'),
            end_time: date + " " + moment(item.end_time, 'h:mm A').format('HH:mm'),
            is_seventh_day: item.seventh_day,
            is_sixth_day: item.sixth_day
          }
          makePostCall(endPoints.CREATE_EVENT_ASSIGNEE, param)
            .then((res) => {
            })
            .catch((err) => {
            })
            props?.getScheduleData();
        }

      })
      
      if(props?.data?.start_datetime.substring(11, 16) === methods.getValues(apiConstants.eventStartTime) && props?.data?.end_datetime.substring(11, 16) === methods.getValues(apiConstants.eventEndTime)
        && moment(props?.data?.start_datetime).format('YYYY-MM-DD') === moment(values?.[apiConstants.event_start_date]).format('YYYY-MM-DD') 
        && props?.data?.type === values?.[apiConstants.event_type]
        && props?.data?.name === values?.[apiConstants.eventName]
        && props?.data?.sub_category === values?.[apiConstants.eventSubCategory]
        && props?.data?.meeting_link === values?.[apiConstants.meeting_link]
        && props?.data?.description === values?.[apiConstants.description]
        && props?.data?.address === address
        && props?.data?.time_zone === values?.[apiConstants.evt_timezone]
        && JSON.stringify(props?.data?.department) ===JSON.stringify(dept)
        ){
          setIsLoading(false)
          close();
          return;
      }
      else{
      
      makePatchCall(`${endPoints.CREATE_EVENT}${(props?.data?.id).trim()}/`, fd, true)
        .then((res) => {
          props?.getScheduleData();
          
        })
        .catch(() => { })
      }
      setIsLoading(false)
      close();
    }
    else {
      makePostCall(endPoints.CREATE_EVENT, fd, true)
        .then((res) => {
          userList.map((item: any, idx: any) => {
            var param = {
              event_id: res?.data?.id,
              assigned_member: item?.id,
              start_time: date + " " + moment(item.start_time, 'h:mm A').format('HH:mm'),
              end_time: date + " " + moment(item.end_time, 'h:mm A').format('HH:mm'),
              is_seventh_day: item.seventh_day,
              is_sixth_day: item.sixth_day
            }
            makePostCall(endPoints.CREATE_EVENT_ASSIGNEE, param)
              .then((res) => {

              })
              .catch((err) => {

              })
          })
          props?.getScheduleData();
          close();
        })
        .catch(() => setIsLoading(false))
      setIsLoading(false)
    }
    props?.getScheduleData();
  }

  const checkSubmit = () => {
    if(selectedCrew.length === 0){
      // methods.handleSubmit(onSubmit);
      onSubmit(methods.getValues())
    } else {
      let checkFlagIndicator= false;
      selectedCrew.forEach((item:any) => {
        if(item?.sixth_day || item?.seventh_day){
          setConfirmIndicator(!confirmIndicator);
          checkFlagIndicator = true;
        }
      })     
      if(!checkFlagIndicator){
        onSubmit(methods.getValues())
      }
    }
  }
  const close = () => {
    props?.setEventDetail(null);
    setSelectedCrew([]);
    setSelectedSupplier([]);
    setCrewList([]);
    setSupplierList([]);
    props?.onClose();
  }
  const { formState } = methods;

  const updateMembertime = (key: any, value: any) => {
    const times: any = { ...selectedMemberTime }
    times[key] = value;
    setSelectedMemberTime(times)
  }
useEffect(()=>{

  if(props?.data?.start_datetime.substring(11, 16) !== methods.getValues(apiConstants.eventStartTime) || props?.data?.end_datetime.substring(11, 16) !== methods.getValues(apiConstants.eventEndTime)){
  // console.log('+++',props?.data?.start_datetime.substring(11, 16),  methods.getValues(apiConstants.eventStartTime))

  selectedSupplier.map((item:any, idx:any)=> {
    item.start_time =  moment(methods.getValues(apiConstants.eventStartTime), 'HH:mm').format('h:mm A')
    item.end_time =  moment(methods.getValues(apiConstants.eventEndTime), 'HH:mm').format('h:mm A')})
  selectedCrew.map((item:any, idx:any)=> {
      item.start_time =  moment(methods.getValues(apiConstants.eventStartTime), 'HH:mm').format('h:mm A')
      item.end_time =  moment(methods.getValues(apiConstants.eventEndTime), 'HH:mm').format('h:mm A')})
    }
},[methods.getValues(apiConstants.eventStartTime), methods.getValues(apiConstants.eventEndTime)])
  
const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  const cursorPosition = e.target.selectionStart;
  const previousScrollPosition = inputRef.current?.scrollTop;
  methods.setValue(apiConstants.description, e.target.value, { shouldValidate: true })
  requestAnimationFrame(() => {
    e.target.selectionStart = cursorPosition;
    e.target.selectionEnd = cursorPosition;
    if (inputRef.current && previousScrollPosition !== undefined) {
      inputRef.current.scrollTop = previousScrollPosition;
    }
  });
};

  return (
    <Modal
      centered
      scrollable
      className="max-width-1033 addProductionCrew modal-body-scroll-part"
      show={props.isShow}
      onHide={close}
    >
      {/* <FormProvider {...methods}> */}
      <ModalHeader className="p-0 border-0 mb-4 align-items-start">
        <button className="close-btn-de shadow-none" onClick={() => close()}>
          <img src={"/assets/images/black-arrow-left-side.svg"} alt="" />
        </button>
        <ModalTitle className="fw-bold me-auto ps-3 text-start">
          <h4 className="h4 text-start mb-0 mt-0">{props?.editFlow ? 'Edit Event' : 'New Event'} </h4>
        </ModalTitle>
        <div>
          <Button type={BUTTON_TYPE.TYPE_BUTTON}
            btnTxt='Save Event'
            className={'btn btn-warning btn-sm shadow-none'}
            style={{ marginTop: "0px", width: "auto", background: "#FFD800", padding: "10px" }}
            onClick={methods.handleSubmit(checkSubmit)}
            isLoading={isLoading}
          />
        </div>

      </ModalHeader>
      <ModalBody className="p-0 text-start  height-402">
        <div className="row">
          <div className="col-md-6">
            <h6 style={{ fontWeight: "600" }}>Event Details</h6>
            <div className="row">
              <FormProvider {...methods}>
                <div className="col-md-12">
                  <InputField
                    label={labels.event_name}
                    placeholder={placeholder.event_name}
                    name={apiConstants.eventName}
                    type="text"
                    className="black-input mb-4"
                  />
                </div>
                <div className="col-md-6" style={{ zIndex: '13' }}>
                  <DropDown
                    className="arrow-fill drop-down-over-vis height-100"
                    data={eventTypeList}
                    selectedItems={setDropdownSelectedEvent(eventTypeList, methods.getValues(apiConstants.event_type))}
                    onSelect={(data) => {
                      methods.setValue(apiConstants.event_type, data.value, { shouldValidate: true });
                    }}
                    placeholder="Select Event"
                    label="Event Type"
                    errorMessage={formState.errors?.[apiConstants.event_type]?.message}
                  //   selectedItems={selectedPermsn}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label={labels.event_sub_category}
                    placeholder={placeholder.event_sub_category}
                    name={apiConstants.eventSubCategory}
                    type="text"
                    className="black-input mb-4"
                  />
                </div>
                <div className="col-md-12" style={{ zIndex: '12' }} >
                  <DatePicker
                    date={methods.getValues(apiConstants.event_start_date)}
                    onDateChange={(date) => {
                      methods.setValue(apiConstants.event_start_date, date, {
                        shouldValidate: true,
                      })
                    }}
                    label="Select Date"
                    displayFormat={DateType.NEWEVENT}
                    //allowPastDate={true}
                    openDirection='down'
                    placeholder='Select Date'
                    style={{ width: 'auto', marginBottom: '1.5rem' }}
                    className="black-input"
                    errorMessage={formState.errors?.[apiConstants.event_start_date]?.message}
                  />

                </div>
                <div className="col-md-12" style={{ zIndex: '11' }}>
                  <DropDown
                    className="arrow-fill w-100 mb-0 mb-sm-4 drop-down-over-vis height-200"
                    data={allDept}
                    placeholder={placeholder.sel_dept}
                    label={labels.department}
                    selectedItems={methods.getValues(apiConstants.department)}
                    onSelect={(el) => methods.setValue(apiConstants.department, el, { shouldValidate: true })}
                    errorMessage={formState.errors?.[apiConstants.department]?.message}
                    isMultiSelect={true}
                  />
                </div>
                <div className="col-md-6 custom-padding" style={{ zIndex: '10' }}>
                  <DropDown
                    className="arrow-fill drop-down-over-vis height-100"
                    data={timeSlots}
                    onSelect={(data) => {
                      methods.setValue(apiConstants.eventStartTime, data.startTime, { shouldValidate: true });
                      updateMembertime('start_time', data?.value || '')
                    }}
                    selectedItems={setDropdownSelectedEventTime(timeSlots, methods.getValues(apiConstants.eventStartTime))}
                    placeholder={placeholder.sel_start_time}
                    label={labels.event_start_time}
                    errorMessage={formState.errors?.[apiConstants.eventStartTime]?.message}
                  //   selectedItems={selectedPermsn}
                  />
                </div>
                <div className="col-md-6 custom-padding" style={{ zIndex: '10' }}>
                  <DropDown
                    className="arrow-fill drop-down-over-vis height-100"
                    data={timeSlots}
                    onSelect={(data) => {
                      methods.setValue(apiConstants.eventEndTime, data.startTime, { shouldValidate: true });
                      updateMembertime('end_time', data?.value || '')
                    }}
                    selectedItems={setDropdownSelectedEventTime(timeSlots, methods.getValues(apiConstants.eventEndTime))}
                    placeholder={placeholder.sel_end_time}
                    label={labels.event_end_time}
                    errorMessage={formState.errors?.[apiConstants.eventEndTime]?.message}
                  //   selectedItems={selectedPermsn}
                  />
                </div>
                <div className="col-md-12">
                  <DropDown
                    className="arrow-fill drop-down-over-vis height-100"
                    data={timezone}
                    onSelect={(data) => {
                      methods.setValue(apiConstants.evt_timezone, data.value, { shouldValidate: true });
                    }}
                    selectedItems={setDropdownSelectedEvent(timezone, methods.getValues(apiConstants.evt_timezone))}
                    placeholder={placeholder.sel_time_zone}
                    label={labels.timezone}
                    errorMessage={formState.errors?.[apiConstants.evt_timezone]?.message}
                  //errorMessage={formState.errors?.[apiConstants.eventStartTime]?.message}
                  //   selectedItems={selectedPermsn}
                  />
                </div>
                <div className="col-md-12 ">
                  <InputField
                    label={labels.meeting_link}
                    placeholder={placeholder.meeting_link}
                    name={apiConstants.meeting_link}
                    type="text"
                    className="black-input mb-4"
                  //meetingIcon={true}
                  />
                </div>
                <div className="col-md-12">
                </div>
                <div className="col-md-12 ">
                  <div className={`form_group black-input mb-4 ${formState.errors?.[apiConstants.event_location]?.message ? 'error' : ''}
                  `}>
                    <div className="input_box">
                      <label htmlFor="location">Location</label>
                      <LocationInput input='input' value={methods.getValues(apiConstants.event_address)}
                        handleChange={(data: any) => {
                          updateLocation({ "type": "Point", "coordinates": [data.lng, data.lat] }),
                            setAddress(data.address);
                          methods.setValue(
                            apiConstants.event_location,
                            data?.address,
                            { shouldValidate: true }
                          )
                        }} listItemStyle={undefined} updateLoctionInput={true} />
                      {/* {formState.errors?.[apiConstants.event_location]?.message && <span>{formState.errors?.[apiConstants.event_location]?.message}</span>} */}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className={`form_group black-input  ${formState.errors?.[apiConstants.description]?.message ? 'error' : ''}`}>
                    <div className="input_box">
                      <label htmlFor="supplier_bio">{labels.event_desc}</label>
                      <textarea
                        name="supplier_bio"
                        id="supplier_bio"
                        placeholder="Add Description"
                        value={methods.getValues(apiConstants.description)}
                        onChange={(evt) =>handleInputChange(evt)}
                        maxLength={500}
                        ref={inputRef}
                        className="no-resize"
                      ></textarea>
                      {/* {formState.errors?.[apiConstants.description]?.message && <span>{formState.errors?.[apiConstants.description]?.message}</span>} */}
                    </div>
                  </div>
                </div>
              </FormProvider>
            </div>
          </div>
          <div className="col-md-6">
            <h6 style={{ fontWeight: "600" }}>Attendees</h6>
            <div className="bottom-tabs-wo">
              <ul className="nav">
                <li className="nav-item" onClick={() => { setSelectedTab('Crew'); setSearchParam(''); }} >
                  <a className={`nav-link ${selectedTab === 'Crew' ? "active" : ""}`}>
                    Crew
                  </a>
                </li>
                <li className="nav-item" onClick={() => { setSelectedTab('Supplier'); setSearchParam('') }} >
                  <a className={`nav-link ${selectedTab === 'Supplier' ? "active" : ""}`}>
                    Supplier
                  </a>
                </li>
              </ul>
            </div>
            {selectedTab === 'Crew' &&
              <div className="tab-content">
                <div className="tab-title px-0">Assign Crew</div>
                <div className="search-button d-flex align-items-center gap-3 justify-content-center newEvent-search">
                  <input type="text" className="form-control  py-2 px-2 shadow-none assign" value={searchParam} placeholder='Assign Crew members' onChange={(e) => setSearchParam(e.target.value)} style={{ border: 'none' }} />
                  {searchParam && <img src={"/assets/images/cross-grey-fill.svg"} onClick={() => setSearchParam('')} alt="" />}
                </div>
                {(searchParam && (methods.getValues(apiConstants.event_start_date) || props?.data?.start_datetime)) &&
                  <div className="crew-member-list" style={{ position: 'absolute', zIndex: '1', width: '48%' }} >
                    <CrewMemberList
                      fetchMoreData={() => fetchMoreCrewData} nextUrl={nextUrlCrew}
                      tempData={crewList} selectCrewMembers={tempCrewSelect} deleteMember={deleteMember} />
                  </div>
                }
                {selectedCrew?.length > 0 &&
                  <SelectedMembers
                    tempData={selectedCrew}
                    setSelectedList={setSelectedCrew}
                    deleteMember={deleteMember}
                    data={props?.data}
                    startTime={ selectedMemberTime?.start_time || ''}
                    endTime={selectedMemberTime?.end_time}
                    date={moment(methods.getValues(apiConstants.event_start_date)).format('YYYY-MM-DD')}
                    editEvent={props?.editFlow}
                    editEventId={props?.data?.id}
                    selectedTab={selectedTab}
                    timeupdate = {(timeupdate:any, idx:any)=> {
                      selectedCrew[idx].start_time = timeupdate.start_time;
                      selectedCrew[idx].end_time = timeupdate.end_time;
                      // setSelectedMemberTime(timeupdate)
                    } }
                  />
                }
              </div>
            }
            {selectedTab === 'Supplier' &&
              <div className="tab-content">
                <div className="tab-title">Assign Supplier</div>
                <div className="search-button d-flex align-items-center gap-3 justify-content-center newEvent-search">
                  <input type="text" className="form-control  py-2 px-2 shadow-none assign" value={searchParam} placeholder='Assign Supplier' onChange={(e) => setSearchParam(e.target.value)} />
                  {searchParam && <img src={"/assets/images/cross-grey-fill.svg"} onClick={() => setSearchParam('')} alt="" />}
                </div>
                {(searchParam && (methods.getValues(apiConstants.event_start_date) || props?.data?.start_datetime)) &&
                  <div className="crew-member-list" style={{ position: 'absolute', zIndex: '1', width: '48%' }} id="scrollableDiv11" >
                    <CrewMemberList
                      fetchMoreData={() => fetchMoreData} nextUrl={nextUrlSupplier}
                      tempData={supplierList} selectCrewMembers={tempSupplierSelect} deleteMember={deleteMember}
                    />
                  </div>
                }
                {selectedSupplier.length > 0 &&
                  <SelectedMembers
                    tempData={selectedSupplier}
                    setSelectedList={setSelectedSupplier}
                    deleteMember={deleteMember}
                    data={props?.data}
                    startTime={selectedMemberTime?.start_time}
                    endTime={selectedMemberTime?.end_time}
                    date={moment(methods.getValues(apiConstants.event_start_date)).format('YYYY-MM-DD')}
                    editEvent={props?.editFlow}
                    editEventId={props?.data?.id}
                    selectedTab={selectedTab}
                    timeupdate = {(timeupdate:any, idx:any)=> {
                      selectedSupplier[idx].start_time = timeupdate.start_time;
                      selectedSupplier[idx].end_time = timeupdate.end_time;
                      // setSelectedMemberTime(timeupdate)
                     
                    } }
                  />
                }
              </div>
            }
          </div>
        </div>
      </ModalBody>
      {/* </FormProvider> */}
      <Modal
          centered
          scrollable
          className=" addProductionCrew modal-body-scroll-part"
          show={confirmIndicator}
          onHide={close}
        > <div className="d-flex flex-column align-items-center justify-content-center">
            <img src={"/assets/images/flag-orange.svg"} alt="" />
            <h5 style={{ fontWeight: '600', paddingTop: '2rem' }}>Crew Overtime</h5>
            <p style={{ textAlign: 'center', marginTop: '10px' }}>Some crew have been booked for 6th and 7th day occurrences. Are you sure you want to continue?</p>
            <button className={'btn btn-warning w-100 with-after-shadow shadow-none'} onClick={methods.handleSubmit(onSubmit)}> Continue</button>
            <button className={'btn w-100 with-after-shadow shadow-none'} style={{ marginTop: '10px' }} onClick={() => setConfirmIndicator(!confirmIndicator)}> Cancel</button>
          </div>
        </Modal>
    </Modal>
  );
};

export default NewEvent;