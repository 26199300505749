import React from 'react'

interface ProductsProps {
    data: Array<any>;
    title: any
}

const Products: React.FC<ProductsProps> = (props) => {
    return (
        <div className='rate-section mb-3 text-center text-sm-start'>
            <h3 style={{ textTransform: 'capitalize' }}>{props.title}</h3>
            <div className='services-design d-flex flex-wrap gap-4'>
                {props.data?.map((item, idx) => <span key={idx}>{item}</span>)}
            </div>
            <br/>
        </div>
    )
}

export default Products