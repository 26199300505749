import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import { BUTTON_TYPE } from '../../types'
import Button from "../../global/ui/Button";

const DeleteProfile = (props: any) => {

  return (
    <Modal
      centered
      scrollable
      show={props.show}
      onHide={props.closePopup}>
      <ModalHeader className="p-0 border-0 text-center mb-3">
        <ModalTitle className="fw-bold mx-auto">
          <p><img src="/assets/images/delete-user-red.svg" alt="" /></p>
          Delete Profile
        </ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0 text-center mb-5 pb-3">
        <p className="mb-0">Are you sure you want to delete your profile?</p>
      </ModalBody>
      <ModalFooter className="p-0 border-0">
        <Button
          type={BUTTON_TYPE.TYPE_BUTTON}
          btnTxt='Deactivate User'
          style={{ marginTop: "0px", width: "auto" }}
          onClick={() => {
            props.onDone()
          }}
          className={'btn btn-dark-danger w-100 with-after-shadow shadow-none'}
        />
        <Button
          type={BUTTON_TYPE.TYPE_BUTTON}
          btnTxt='Cancel'
          style={{ marginTop: "0px", width: "auto" }}
          onClick={props.closePopup}
          className={'btn btn-light w-100 with-after-shadow shadow-none fw-normal bg-transparent m-0 border-0 mt-4 p-0'}
        />
      </ModalFooter>
    </Modal>
  )
}

export default DeleteProfile