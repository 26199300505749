import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import { makeGetCall } from '../../effects/requests';
import { ApiResponseType, SETTINGS_ITEMS_TYPE } from '../../types';
import { emptyFunction } from '../../utils';
import { endPoints } from '../../utils/endpoints';

interface LegalsModalProps {
    isShow: boolean;
    onClose: () => void;
    type: any
}

const LegalsModal: React.FC<LegalsModalProps> = (props) => {

    const [legals, setLegals] = useState<string | null>();

    const title = props?.type === SETTINGS_ITEMS_TYPE.TYPE_PP ? 'Privacy Policy' : 'Terms & Conditions';

    const getLegal = (url: any) => {
        makeGetCall(url).then((res: ApiResponseType<any>) => {
            setLegals(res.data?.text || null)
        })
    }

    useEffect(() => {
        const urls = props?.type === SETTINGS_ITEMS_TYPE.TYPE_PP ? endPoints.PP : endPoints.TERMS;
        if (props.type === SETTINGS_ITEMS_TYPE.TYPE_PP || props.type === SETTINGS_ITEMS_TYPE.TYPE_TNC) getLegal(urls)
    }, [props.type]);

    return (
        <Modal
            centered
            scrollable
            className='updaterole term-and-policy without-gradent-bottom'
            show={props.isShow}
            onHide={emptyFunction}>
            <ModalHeader className="p-0 pb-4 border-0 mb-0 align-items-center">
                <button className="close-btn-de shadow-none" onClick={props.onClose}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
                <ModalTitle className="fw-bold me-auto ps-3 text-start">
                    <h4 className='h4 text-start mb-0 mt-0'>{title}</h4>
                </ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 ps-0 ps-sm-5 ms-0 ms-sm-3 text-start inner-terms-policy">
                <div className='text-start' dangerouslySetInnerHTML={{ __html: legals || '' }} />
            </ModalBody>
        </Modal>
    )
}

export default LegalsModal