import React from "react";

interface JobFilterProps {
    count?: any;
    updateValue?: (key: any, value: any) => void;
    data: any;
    showing?: any;
}

const JobFilter: React.FC<JobFilterProps> = (props) => {
   
    return (
        <div className="table-filter-setup mb-3 mb-lg-0">
            <div className="row align-items-center">
                <div className="col-md-12 col-lg-12 col-xl-12">
                    <div className="right-side-showing d-flex align-items-center justify-content-center justify-content-lg-end gap-3 mb-4 mt-3 mt-sm-0">
                        {/* <p className="mb-0 fw-bold">Showing</p>
                        <div className="form_group w-100 black-input">
                            <div className="input_box">
                                <select name="country" value={props?.data?.limit || ''} onChange={(evt) => props?.updateValue?.('limit', evt.target.value)} className="seend-arrow w-100 p-3">
                                    {paginationData.map((item, idx) => <option key={idx} value={item?.value}>{item?.label}</option>)}
                                </select>
                            </div>
                        </div>
                        <p className="mb-0 fw-bold">{`of ${props?.count || 0} results`}</p> */}
                        <p className="mb-0 fw-bold">{`Showing ${props?.showing} of ${props?.count || 0
                            } results`}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobFilter;
