import React from "react";
import { Button, Modal, ModalTitle } from "react-bootstrap";
import Webcam from "react-webcam";



const WebcamModal = (props: any) => {
  const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: "user",
  };
    return (
      <div>
          <Modal
            className="crop-image123"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props?.show}
            onHide={props.onClose}
          >
            <Modal.Header className="p-0 border-0 justify-content-between pb-2 mb-0">
              <ModalTitle className="fw-bold text-center">Capture Image</ModalTitle>
            </Modal.Header>
            <Modal.Body className="p-0">
              <div className="croper_outer">
              <Webcam
                audio={false}
                ref={props?.webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                minScreenshotWidth={180}
                minScreenshotHeight={180}
              />
              {props?.imgSrc && <img src={props?.imgSrc} alt="img" />}
               
                <Button variant="primary" className="btn-warning w-100" onClick={props?.capture}>
                Capture
                </Button>
              </div>
            </Modal.Body>
          </Modal>
      </div>
    )
  }

export default WebcamModal;
