import React from 'react'
import { Table } from "react-bootstrap";
import NoData from '../../global/ui/NoData';
import { createFullName, getPrmsnText, renderAddressDetail } from '../../utils';
import { tableHeader } from '../../utils/constants';
import Logo from '../../components/commonComponent/ProfileImage';

interface ProductionCrewsProps {
    data: Array<any>;
    onClick?: (data: any) => void;
    onSelect: (id: any, type: 'S' | 'M', checked?: any) => void;
    selectedIDS: Array<any>
}


const ProductionCrews: React.FC<ProductionCrewsProps> = (props) => {

    return (
        <div className="common-table ovflow-height">
            {props.data?.length > 0
                ?
                <Table responsive className="">
                    <thead>
                        <tr>
                            <th style={{ minWidth: '80px', width: '10%' }}></th>
                            <th style={{ minWidth: '100px', width: '10%' }}>{tableHeader.name}</th>
                            <th style={{ minWidth: '206px', width: '10%' }}>{tableHeader.email}</th>
                            <th style={{ minWidth: '128px', width: '10%' }}>{tableHeader.phone}</th>
                            <th style={{ minWidth: '200px', width: '10%' }}>{tableHeader.address}</th>
                            <th style={{ minWidth: '150px', width: '15%' }}>{tableHeader.department}</th>
                            <th style={{ minWidth: '163px', width: '10%' }}>{tableHeader.role}</th>
                            <th style={{ minWidth: '180px', width: '10%' }}>{tableHeader.type}</th>
                            {/* <th style={{ minWidth: '100px', width: '10%' }}>{tableHeader.status}</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {props?.data?.map((item: any, idx: any) => {
                            let role: any = [];
                            item?.crew_member_profile?.role?.map((dept: any) => {
                                role = [...role, ...dept.role]
                            })
                            return (
                                <tr style={{ cursor: 'pointer' }} onClick={() => props?.onClick?.(item)} key={idx}>
                                    <td style={{ minWidth: '80px', width: '10%' }}>
                                        <div className="d-flex align-items-center gap-4">
                                            {item?.profile_image ?
                                                <img style={{ width: '33px' }} src={item?.profile_image} alt="" className={`rounded-circle ${item?.profile_image ? '' : 'table-icon-img'}`} />
                                                :
                                                <Logo style={{ width: '33px', height: '33px' }} data={item?.first_name[0]} />}
                                        </div>
                                    </td>
                                    <td className='text-break' style={{ minWidth: '100px', width: '10%' }}><p style={{ textTransform: 'capitalize' }} className="mb-0">{createFullName(item?.first_name, item?.last_name)}</p></td>
                                    <td className='text-break' style={{ minWidth: '206px', width: '10%' }}>{item?.email}</td>
                                    <td style={{ minWidth: '128px', width: '10%' }}>{item?.mobile_number}</td>
                                    <td className='text-break' style={{ minWidth: '200px', width: '10%' }}>{renderAddressDetail(item, true)}</td>
                                    {/* <td style={{ minWidth: '100px', width: '15%' }}>{item?.crew_member_profile?.role?.length > 0 && <button className="perple-button">{`${item?.crew_member_profile?.role[0]?.dep} ${(item?.crew_member_profile?.role?.length - 1) ? "+" + (item?.crew_member_profile?.role?.length - 1) : ''}`}</button>}</td> */}
                                    <td style={{ minWidth: '150px', width: '15%' }}>{item?.crew_member_profile?.company_department?.length > 0 && <button className="perple-button text-break">{`${item?.crew_member_profile?.company_department[0]?.dep} ${(item?.crew_member_profile?.company_department?.length - 1) ? "+" + (item?.crew_member_profile?.company_department?.length - 1) : ''}`}</button>}</td>
                                    <td style={{ minWidth: '163px', width: '10%' }}>{role?.length > 0 && <button className="perple-button text-break">{`${role[0]} ${(role?.length - 1) ? "+" + (role?.length - 1) : ''}`}</button>}</td>
                                    <td style={{ minWidth: '180px', width: '10%' }}>{getPrmsnText(item?.permission_type)}</td>
                                    {/* <td style={{ minWidth: '100px', width: '10%' }}><button className={`btn rounded-pill witout-hover ${item?.active_at_company ? 'btn-success' : 'btn-danger'}`}>{item?.active_at_company ? 'Active' : 'Inactive'}</button></td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                :
                <NoData />
            }
        </div>
    )
}

export default ProductionCrews