import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import { BUTTON_TYPE } from '../../types'
import Button from "../../global/ui/Button";

interface DayEarningRatesProps {
    isShow?: boolean;
    weekDayNumber:number;
    onClose: () => void
    onSubmit: () => void
    scheduleDetails: any;
}

//This component use only for ui presentation so all details enter here is static will remove once api is available and also maintain reusability
const DayEarningRates = (props: DayEarningRatesProps) => {

    return (
        <Modal
            centered
            scrollable
            show={props.isShow}
            onHide={props.onClose}>
            <ModalHeader className="p-0 border-0 text-center mb-3 align-items-start">
                <div className='text-center mx-auto'>
                    <p className='mb-0' style={{ paddingTop: '18px' }}><img src={"/assets/images/Flag-orange.png"} alt="" /></p>
                    <ModalTitle className="fw-bold mx-auto pt-3">{props.weekDayNumber == 0?'7':'6'}th Day</ModalTitle>
                </div>
                <button className="close-btn-de shadow-none mb-1" onClick={props.onClose}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
            </ModalHeader>
            <ModalBody className="p-0 text-center mb-5 pb-3">
                <p className="mb-0">This booking will make {props.scheduleDetails?.first_name}'s {props.weekDayNumber == 0?'7':'6'}th day in a row. Overtime rates will apply for this booking.</p>
            </ModalBody>
            <ModalFooter className="p-0 border-0">
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt='Continue & Book'
                    style={{ marginTop: "0px", width: "auto" }}
                    onClick={props.onSubmit}
                    className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                />
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt='Cancel'
                    style={{ marginTop: "0px", width: "auto" }}
                    onClick={props.onClose}
                    className={'btn btn-light w-100 with-after-shadow shadow-none bg-transparent m-0 border-0 mt-4 p-0'}
                />
            </ModalFooter>
        </Modal>
    )
}

export default DayEarningRates