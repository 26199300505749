import Button from '../../global/ui/Button';
import { BUTTON_TYPE, ROLE_TYPE } from '../../types';
import { signupText, buttonsText, cookieServiceParam } from '../../utils/constants';
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import AuthSection from '../../global/layout/authSection';
import RadioButton from '../../global/ui/radioButton';
import { cookieService } from '../../services';

const OnBoarding: React.FC = () => {

  const [selectedOption, setSelectedOption] = useState('C');
  const navigate = useNavigate();

  return (
    <AuthSection>
      <div className='inner-content text-center ms-auto pb-5'>
        <div className='top-content'>
          <img src={"/assets/images/signin-icon.svg"} alt="" />
          <h3>{signupText.profile_type}</h3>
          <p>{signupText.profile_type_selection}</p>
        </div>
        <form>
          <div className='input-radio-select-without-radio-button'>
            <RadioButton label={buttonsText.productionCompany} name='flexRadioDisabled' id='flexRadioDisabled1' checked={true} onClick={() => { setSelectedOption('C'); }} type='C' />
            <RadioButton label={buttonsText.btnSupplier} name='flexRadioDisabled' id='flexRadioDisabled2' checked={false} onClick={() => { setSelectedOption('S'); }} type='S' />
          </div>
          <Button
            type={BUTTON_TYPE.TYPE_BUTTON}
            btnTxt={buttonsText.continue}
            style={{ marginTop: "20px", width: "100%" }}
            onClick={() => {
              cookieService.saveCookie(cookieServiceParam.userType, selectedOption)
              {selectedOption === ROLE_TYPE.TYPE_CREW? navigate('/sign-in'): navigate(`/signup`)}
            }}
            className={'btn btn-warning w-100 with-after-shadow shadow-none'}
          />
        </form>
        <div className='move-login-signup' onClick={() => navigate('/sign-in')}>
          <p className='mb-0'>{buttonsText.login_instead}</p>
        </div>
      </div>
    </AuthSection>
  )
}
export default OnBoarding;