import React from 'react'
import { DateUtils } from '../../utils/dateUtils'
import Logo from '../../components/commonComponent/ProfileImage'

interface JobInfoProps {
    jobDetails: any
}

const JobInfo: React.FC<JobInfoProps> = (props) => {
    return (
        <div className='card popup-card-top mt-4'>
            <div className='card-body'>
                <div className='top-part-s d-grid d-sm-flex justify-content-between'>
                    <div className='left-part-s'>
                        <h3>{props.jobDetails?.title || ''}</h3>
                        <div className='list-selc d-flex flex-wrap gap-2 mb-3'>
                            <span className="perple-button py-0">{props.jobDetails?.department || ''}</span>
                            <span className="perple-button py-0">{`£${props.jobDetails?.daily_rate}/day`}</span>
                        </div>
                        <ul className="profile-information-list gap-2">
                            <li className="align-items-center">
                                <img src={"/assets/images/user-icon-perple.svg"} alt="" />
                                {`${props.jobDetails?.number_of_positions} Positions`}
                            </li>
                            <li className="align-items-center">
                                <img src={"/assets/images/Calendar-empty-icon-perple.svg"} alt="" />
                                {`${DateUtils.formatDateTime(props.jobDetails?.start_date, 'DD MMMM YYYY')}`}
                            </li>
                            <li className="align-items-center word-break">
                                <img src={"/assets/images/Location-icon-perple.svg"} alt="" />
                                {props.jobDetails?.location}
                            </li>
                        </ul>
                    </div>
                    {props?.jobDetails?.image ? <img src={props.jobDetails?.image } className={`top-right-img mb-3 mb-sm-0 ${props.jobDetails?.image ? "" : "logo-not-present"}`} alt=""  style={{background:'none'}}/>
                    : <Logo style={{ width: '140px', height: '140px' , borderRadius: '0'}} data={props?.jobDetails?.production_company?.production_company_name?.[0]}/> }
                </div>
                <p className='mb-0 word-break'>{props.jobDetails?.description}</p>
            </div>
        </div>
    )
}

export default JobInfo