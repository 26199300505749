import React, { useState, useEffect, useContext } from "react";
import NewBroadCast from "../../pages/messages/newBroadCast/index";
import moment from 'moment';
import useDebounce from "../../hooks/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { PERMISSION_TYPE, REQ_STATUS, ROLE_TYPE, USER_TYPE } from "../../types";
import { MyContext } from "../../context";
import { makeGetCall, makePostCall } from "../../effects/requests";
import { endPoints } from "../../utils/endpoints";
import { showToast } from "../../utils";
import ChatRequest from "../../components/modal/ChatRequest";
import { ContactTile } from "../../components/commonComponent/ContactTile";

interface ContactsProps {
    contacts: any;
    currentUser: any;
    changeChat: (chat: any) => void;
    isShowMessage: boolean;
    setIsShowMessage: (e: boolean) => void;
    searchList: (text: string) => void;
    fetchMoreData: () => void;
    nextUrl: string;
    supplierList: (type: boolean) => void;
    ownerList: (type: string) => void;
    setNextUrl: (e:any) => void;
}

const Contacts: React.FC<ContactsProps> = ({
    contacts,
    currentUser,
    changeChat,
    isShowMessage,
    setIsShowMessage,
    searchList,
    fetchMoreData,
    nextUrl,
    supplierList,
    ownerList,
    setNextUrl
}) => {
    const location = useLocation();
    const { userData } = useContext(MyContext)
    const [currentSelected, setCurrentSelected] = useState<string>("");
    const [activeMenu, setActiveMenu] = useState(false);
    const [isNewBroadcast, setIsNewBroadcast] = useState(false);
    const navigate = useNavigate();
    const parsed = queryString.parse(location.search);
    const [searchParam, setSearchParam] = useState('')
    const debouncedValue = useDebounce(searchParam, 500)
    const [companyList, setCompanyList] = useState([]);
    const [nextUrlList, setNextUrlList] = useState('');
    const [showChatReqModal, setShowChatReqModal] = useState(false);
    const [contactId, setContactId] = useState('')
    const createRoom = (msg?: string, attachement_url?: string, attachement_type?: string) => {
        makePostCall(endPoints.ROOM_LIST, {
            'other_user': contactId,
            'message': msg ? msg : '',
            'attachement_url': attachement_url ? attachement_url : '',
            'attachement_type': attachement_type ? attachement_type : ''
        })
            .then((res) => {
                setShowChatReqModal(false);
                if (res?.data?.other_user?.chat_room?.chat_room_created) {
                    makeGetCall(endPoints.ROOM_LIST + res?.data?.id + '/')
                        .then((response) => {
                            if (response?.data?.status === REQ_STATUS.DELETED) {
                                showToast("User account is deleted", "error", 323)
                            }
                            // else if (response?.data?.status === REQ_STATUS.PENDING) {
                            //     showToast("Chat request is pending with user", "info", 323)
                            // }
                            else {
                                navigate('/messages/?search=&type=C', { state: { data: res?.data } })
                            }
                        })
                        .catch((err) => {

                        })

                }
                else {
                    showToast("Chat request sent successfully", "success", 23)
                }
                setShowChatReqModal(false);
            })
            .catch((err) => {

            })

    }

    useEffect(() => {
        updateUrls('search', searchParam);
        updateUrls('type', parsed.type ? parsed.type : (userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) ? USER_TYPE.TYPE_SUPPLIER : USER_TYPE.TYPE_PRIMARY)
        searchList(debouncedValue)
    }, [debouncedValue])

    const changeCurrentChat = (index: any, contact: any) => {
        setCurrentSelected(index);
        changeChat(contact);
    };
    const updateUrls = (key: any, value: any) => {
        const params = parsed;
        params[key] = value;
        navigate(`/messages/?${createSearchParams(params as never)}`);
    }
    const onTabChange = () => {
        setActiveMenu(false);
        setNextUrlList('');
        setCompanyList([]);
        setNextUrl(null);
    }
    return (
        <>
            <div className={`messages-left-side ${isShowMessage ? "mobile-d-none" : ""}`}>
                <div className='messages-top-side d-flex justify-content-between align-items-center'>
                    <h5>Messages</h5>
                    {userData?.user_type !== ROLE_TYPE?.TYPE_SUPPLIER && <p className='d-flex align-items-center gap-2' onClick={() => setIsNewBroadcast(true)}>New Broadcast <img src="/assets/images/broadcast-icon-perple.svg" alt="" /></p>}
                </div>
                <div className='messages-middle-side'>
                    <div className="search-button d-flex align-items-center justify-content-between gap-3">
                        <div className="input-group">
                            <input type="text" className="form-control border-0 shadow-none" placeholder="Search messages" aria-label="Amount (to the nearest dollar)" onChange={(e) => { setSearchParam(e.target.value) }} value={searchParam} />
                            <span className="input-group-text bg-transparent border-0">
                                {searchParam ?
                                    <img src="/assets/images/cross-cercle-fill.svg" onClick={() => setSearchParam('')} alt="" />
                                    :
                                    <img src={"/assets/images/search-black.svg"} alt="" />
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div className='messages-bottom-side'>
                    <div className="bottom-tabs-wo">
                        {(userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) ?
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className={`nav-link ${parsed.type === USER_TYPE.TYPE_SUPPLIER || parsed.type === undefined ? 'active' : ''}`} data-bs-toggle="pill" href="#profile_information" onClick={() => { ownerList(ROLE_TYPE.TYPE_SUPPLIER); onTabChange(); updateUrls('type', USER_TYPE.TYPE_SUPPLIER); }}>Supplier</a>
                                </li>
                                <li className="nav-item" onClick={() => { ownerList(ROLE_TYPE.TYPE_CREW); onTabChange(); updateUrls('type', USER_TYPE.TYPE_CREW); }}>
                                    <a className={`nav-link ${parsed.type === USER_TYPE.TYPE_CREW ? 'active' : ''}`} data-bs-toggle="pill" href="#profile_information">Crew</a>
                                </li>
                            </ul> :
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className={`nav-link ${parsed.type === USER_TYPE.TYPE_PRIMARY || parsed.type === undefined ? 'active' : ''}`} data-bs-toggle="pill" href="#profile_information" onClick={() => { supplierList(true); onTabChange(); updateUrls('type', USER_TYPE.TYPE_PRIMARY); }}>Primary</a>
                                </li>
                                <li className="nav-item" onClick={() => { supplierList(false); onTabChange(); updateUrls('type', USER_TYPE.TYPE_REQUEST); }}>
                                    <a className={`nav-link ${parsed.type === USER_TYPE.TYPE_REQUEST ? 'active' : ''}`} data-bs-toggle="pill" href="#profile_information">Request</a>
                                </li>
                            </ul>
                        }
                        <div className="tab-content">
                            <div className="tab-pane active" id="profile_information">
                                <div className='messages-chat-list' id="scrollableDiv">
                                    <InfiniteScroll
                                        dataLength={contacts?.length}
                                        next={fetchMoreData}
                                        hasMore={nextUrl ? true : false}
                                        loader={null}
                                        scrollableTarget="scrollableDiv"
                                    >
                                        {contacts?.map((contact: any, idx: any) => {
                                            return (
                                                <ContactTile contact={contact} onClick={() => { setIsShowMessage(true); changeCurrentChat(idx, contact) }}/>
                                            )
                                        })}
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NewBroadCast
                    isShow={isNewBroadcast}
                    onClose={() => setIsNewBroadcast(false)}
                />
                <ChatRequest show={showChatReqModal} closePopup={() => setShowChatReqModal(false)} onSubmit={createRoom} />
                <div className={`select-contact-bg ${activeMenu ? "show" : ""}`}></div>
            </div>
        </>
    )
};
export default Contacts;
