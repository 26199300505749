import React, { useContext, useState } from 'react'
import DropDown from '../../../global/ui/dropDown';
import { preConfigDeptData } from '../../../data/data';
import { components } from 'react-select';
import { createFullName, emptyFunction } from '../../../utils';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import { apiConstants } from '../../../utils/apiConstants';
import Button from '../../../global/ui/Button';
import { ApiResponseType, BUTTON_TYPE, PERMISSION_TYPE, ROLE_TYPE, USER_TYPE } from '../../../types';
import { makeGetCall } from '../../../effects/requests';
import { endPoints } from '../../../utils/endpoints';
import AsyncSelect from 'react-select/async';
import { cloneDeep } from 'lodash';
import { MyContext } from '../../../context';

interface SelectMembersProps {
    formData: any;
    onSubmit: (values: any) => void;
    activeStep: Number
}

const SelectMembers: React.FC<SelectMembersProps> = (props) => {

    const { formData } = props;
    const { formState } = formData;

    const [roles, setRoles] = useState<any>([])
    const [userList, setUserList] = useState([])
    const [selectedItems, setSelectedItems] = useState<any>([])
    const { userData } = useContext(MyContext)

    // const companyName = userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER ? userData?.owner_profile?.company_name : userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? userData?.supplier_profile?.company_name : userData?.production_company_name
    const companyName = userData?.production_company_name || ''

    const Option = ({ data, ...restProps }: any) => {
        return (
            <components.Option {...restProps}>
                <div className='d-flex gap-4 text-start justify-content-between align-items-center fw-bold' >
                    <div className='d-flex align-items-center gap-4'>
                        <img width='20px' height="20px" style={{ borderRadius: '50%' }} src={data?.profile_image || "/assets/images/user-icon-perple.svg"} alt='user' />
                        <p className='crew-options text-start mb-0 fw-bold'>{data?.label}</p>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" onChange={emptyFunction} type="checkbox" value={data?.value} checked={formData.getValues(apiConstants.user_ids)?.includes(data?.id) ? true : false} id={data?.value} />
                        <label className="form-check-label" htmlFor={data?.value}></label>
                    </div>
                </div>
            </components.Option>
        );
    };

    const getSelectedRoles = () => {
        const selectedData: Array<any> = formData.getValues(apiConstants.roles);
        const list: Array<any> = []
        roles.map((item: any) => {
            if (selectedData.includes(item?.label)) {
                list.push(item)
            }
        })
        return list;
    }

    const getUrls = (query: string) => `${endPoints.USERLIST}?role=${formData.getValues(apiConstants.roles)?.join('&role=')}&user_type=${USER_TYPE.TYPE_CREW}&department=${formData.getValues(apiConstants.department)?.join('&department=')}&search=${query}&production_company_name=${companyName}`

    const getUserList = () => {
        makeGetCall(getUrls("")).then((res: ApiResponseType<any>) => {
            const temp = res?.results?.map((item: any) => {
                return { ...item, label: createFullName(item?.first_name, item?.last_name), value: item?.id }
            })
            const selectedIds: Array<any> = [];
            temp.map((item: any) => {
                selectedIds.push(item?.value);
            })
            formData.setValue(apiConstants.user_ids, selectedIds, { shouldValidate: true });
            setUserList(temp)
            setSelectedItems(temp)
        })
    }

    const promiseOptions = (query: any) => {
        return makeGetCall(getUrls(query)).then((res: ApiResponseType<any>) => {
            return res?.results?.map((item: any) => {
                return { ...item, label: createFullName(item?.first_name, item?.last_name), value: item?.id }
            })
        })
    }

    const onDeptSelect = (el: any) => {
        const tempEl: Array<any> = [];
        let tempRoles: Array<any> = [...roles];
        const items = cloneDeep(el);
        items.map((item: any) => {
            tempEl.push(item?.label);
            item?.roles?.map((roleItem: any) => {
                const idx = tempRoles.findIndex((roleEl) => roleEl?.label === roleItem?.label);
                if (idx === -1) {
                    tempRoles.push(roleItem)
                }
            })
        })
        formData.setValue(apiConstants.department, tempEl, { shouldValidate: true });
        setRoles(tempRoles);
        setUserList([]);
        setSelectedItems([]);
    }

    const getSelectedDept = () => {
        const selectedData: Array<any> = formData.getValues(apiConstants.department);
        const list: Array<any> = []
        preConfigDeptData.map((item: any) => {
            if (selectedData.includes(item?.label)) {
                list.push(item)
            }
        })
        return list;
    }

    return (
        <FormProvider {...formData}>
            <form className='text-start' onSubmit={formData.handleSubmit(props.onSubmit)} noValidate>
                <ModalBody className="p-0 text-start pb-4">
                    <DropDown
                        className="arrow-fill new-brodcast"
                        data={preConfigDeptData}
                        placeholder='Select departments'
                        label='Departments'
                        onSelect={onDeptSelect}
                        // selectedItems={setDropdownSelectedValue(preConfigDeptData, formData.getValues(apiConstants.department))}
                        selectedItems={getSelectedDept()}
                        errorMessage={formState.errors?.[apiConstants.department]?.message}
                        isMultiSelect={true}
                        onBlur={() => setTimeout(getUserList, 300)}
                    />
                    <DropDown
                        className="arrow-fill new-brodcast"
                        isMultiSelect={true}
                        data={roles}
                        placeholder='Select roles'
                        label='Roles'
                        onSelect={(el) => {
                            const tempEl: Array<any> = []
                            el.map((item: any) => {
                                tempEl.push(item?.label);
                            })
                            formData.setValue(apiConstants.roles, tempEl, { shouldValidate: true });
                        }}
                        selectedItems={getSelectedRoles()}
                        errorMessage={formState.errors?.[apiConstants.roles]?.message}
                        onBlur={() => setTimeout(getUserList, 300)}
                    />
                    <div className={`form_selcet mb-4 ${formState.errors?.[apiConstants.user_ids]?.message ? 'error' : ''}`}>
                        <div className="input_box">
                            <label htmlFor="country">Crew Members</label>
                            <AsyncSelect
                                className="basic-multi-select arrow-fill shadow-none"
                                classNamePrefix="select"
                                loadOptions={promiseOptions}
                                defaultOptions={userList}
                                placeholder='Select crew members'
                                value={selectedItems}
                                onChange={(el) => {
                                    const tempEl: Array<any> = []
                                    el.map((item: any) => {
                                        tempEl.push(item?.id);
                                    })
                                    formData.setValue(apiConstants.user_ids, tempEl, { shouldValidate: true });
                                    setSelectedItems(el)
                                }}
                                components={{ Option }}
                                hideSelectedOptions={false}
                                closeMenuOnSelect={false}
                                isMulti
                            />
                        </div>
                        {formState.errors?.[apiConstants.user_ids]?.message && <span>{formState.errors?.[apiConstants.user_ids]?.message}</span>}
                    </div>
                </ModalBody>
                <ModalFooter className="p-0 border-0 row align-items-center" style={{ marginTop: '45px' }}>
                    <div className='col-4  m-0'>
                        <p className='mb-0'>{`${props.activeStep} of 2`}</p>
                    </div>
                    <div className='col-8 m-0'>
                        <Button
                            type={BUTTON_TYPE.TYPE_SUBMIT}
                            btnTxt={'Add'}
                            style={{ marginTop: "0px", width: "auto" }}
                            className={'btn btn-warning w-100 with-after-shadow shadow-none m-0'}
                        />
                    </div>
                </ModalFooter>
            </form>
        </FormProvider>
    )
}

export default SelectMembers