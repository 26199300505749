import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { makeDeleteCall, makePostCall } from '../effects/requests';
import { endPoints } from '../utils/endpoints';
import { MyContext } from '../context';
import { cookieService } from '../services';
import Button from '../global/ui/Button';
import { BUTTON_TYPE } from '../types';

const PageNotFound: React.FC = () => {
    const {updateUserData} = useContext(MyContext);
    const navigate = useNavigate();
    const onLogOut = () => {
        makeDeleteCall(endPoints.LOGOUT, { device_type: 'WEB' })
            .then(clearAll)
            .catch(clearAll)
    }

    const clearAll = () => {
        updateUserData(null)
        cookieService.removeAllCookies();
        navigate('/sign-in');
    }
    return (
        <div className="page-not-found-wrap bg-dark min-vh-100 d-flex align-items-center text-center text-white justify-content-center">
            <div className='page-not-found'>
                <h1 className='fw-bold display-1'>404</h1>
                <h2 className='fw-bold mb-5' >Page not Found!</h2>
                <span className='h5'>Seems you have trying to access a page which was either moved or does not exist.</span>
                <p className='mt-4'>
                    {/* <Button  className='cancel-btn btn btn-warning px-5 shadow-none'>Go back to home</Button> */}
                    <Button
                        type={BUTTON_TYPE.TYPE_BUTTON}
                        btnTxt='Logout'
                        style={{ marginTop: "20px", width: "100%" }}
                        className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                        onClick={onLogOut}
                        />
                </p>
            </div>
        </div>
    )
}

export default PageNotFound