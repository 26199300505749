import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChangePassword from "../../components/modal/changePassword";
import DeleteProfile from "../../components/modal/DeleteProfile";
import Faq from "../../components/modal/Faq";
import LegalsModal from "../../components/modal/LegalsModal";
import { settingsData } from "../../data/data";
import { makeDeleteCall, makeGetCall, makePutCall } from "../../effects/requests";
import { cookieService } from "../../services";
import { ApiResponseType, SETTINGS_ITEMS_TYPE } from "../../types";
import { defaultAdminMail } from "../../utils/constants";
import { endPoints } from "../../utils/endpoints";
import { showToast } from "../../utils";

const Setting = (props: any) => {

  const navigate = useNavigate();

  const [actionType, setActionType] = useState<any>(null)
  const [adminInfo, setAdminInfo] = useState<any>(null)

  const handleUpdatePass = (data: any) => {
    setActionType(null)
    makePutCall(endPoints.PASSWORD, data).then((res: ApiResponseType<any>) => {
      if(res?.status?.code == 200){
      showToast(res?.status?.message, 'success')
      }
      else{
        showToast(res?.status?.message, 'error')
      }
     })
  }

  //This method allow us to delete user porfile
  const handleDeleteAcc = () => {
    setActionType(null)
    makeDeleteCall(endPoints.PROFILE).then(clearAll).catch(clearAll)
  }

  const clearAll = () => {
    cookieService.removeAllCookies();
    navigate('/sign-in');
  }

  const fetchAdminInfo = () => {
    makeGetCall(endPoints.ADMIN_CONTACT).then((res: ApiResponseType<any>) => {
      setAdminInfo(res.data)
    })
  }

  useEffect(fetchAdminInfo, [])

  return (
    <div className="tab-pane active" id="settings">
      <div className="setting-policy-list d-grid gap-3 pb-4 mb-3">
        {settingsData.map((item, idx) => {
          return (
            <div key={idx} className="setting-policy-design d-flex align-items-center justify-content-between" onClick={() => setActionType(item.type)}>
              <div className="d-flex gap-4 align-items-center">
                <img src={item.icon} alt={item.label} />
                {item?.type === SETTINGS_ITEMS_TYPE.TYPE_CONTACT_ADMIN
                  ?
                  <a className="a_tag_style" href={`mailto:${adminInfo?.email || defaultAdminMail}`}>{item.label}</a>
                  :
                  <p className="mb-0">{item.label}</p>
                }
              </div>
              {item.type !== SETTINGS_ITEMS_TYPE.TYPE_DELETE_PROFILE && <img src={"/assets/images/play-icon-dark.svg"} alt="" />}
            </div>
          )
        })}
      </div>
      <ChangePassword
        isShow={actionType === SETTINGS_ITEMS_TYPE.TYPE_CHANGE_PASS}
        onClose={() => setActionType(null)}
        onSubmit={handleUpdatePass}
      />
      <LegalsModal
        isShow={(actionType === SETTINGS_ITEMS_TYPE.TYPE_PP || actionType === SETTINGS_ITEMS_TYPE.TYPE_TNC) ? true : false}
        type={actionType}
        onClose={() => setActionType(null)}
      />
      <Faq
        show={actionType === SETTINGS_ITEMS_TYPE.TYPE_FAQ}
        closePopup={() => setActionType(null)}
      />
      <DeleteProfile
        show={actionType === SETTINGS_ITEMS_TYPE.TYPE_DELETE_PROFILE}
        closePopup={() => setActionType(null)}
        onDone={handleDeleteAcc}
      />
    </div>
  )
}
export default Setting;