import React from 'react'

interface DeptProps {
    data: Array<any>;
    icon?: any
}

const Dept: React.FC<DeptProps> = (props: DeptProps) => {
    return (
        <li className='align-items-center'>
            <img src={props?.icon || "/assets/images/Briefcase-perple-icon.svg"} alt="icon" />
            <div className='like-togl'>
                {props.data?.map((item: string, idx: number) => <span key={idx}>{item}</span>)}
            </div>
        </li>
    )
}

export default Dept