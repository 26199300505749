import { buttonsText } from '../../utils/constants';
import { useEffect, useState } from 'react';
import Stripe from '../../components/stripe';
import { makeDeleteCall, makeGetCall, makePatchCall } from '../../effects/requests';
import { ApiResponseType } from '../../types';
import CardItems from './CardItems';
import { endPoints } from '../../utils/endpoints';
import ConfirmSubscription from '../../components/modal/confirmSubscription';

const Payment = () => {
  const [allData, setAllData] = useState([])
  const [isShowCardModal, setIsShowCardModal] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const getCardData = () => {
    makeGetCall(endPoints.PAYMENTDATA).then((res: ApiResponseType<any>) => {
      if (res.status?.code == 200) {
        setAllData(res?.data)
      }
    })
  }

  const deleteCard = (id: any) => {
    makeDeleteCall(endPoints.PAYMENTDATA, { source_id: id }).then((res: ApiResponseType<any>) => {
      if (res.status?.code == 200) {
        getCardData();
      }
    });
  }

  const makeDefault = (id: any) => {
    makePatchCall(endPoints.PAYMENTDATA, { source_id: id }).then((res: ApiResponseType<any>) => {
      if (res.status?.code == 200) {
        getCardData();
      }
    });
  }

  useEffect(getCardData, [])

  return (
    <div className="tab-pane active" id="payment_info">
      <div className='payments-card-design d-flex gap-4 overflow-auto pb-4'>
        {allData.map((item: any, idx) => <CardItems
          key={idx}
          item={item}
          onDelete={() => deleteCard(item?.id)}
          onSetDefault={() => makeDefault(item?.id)}
          onEdit={() => alert('To be implemented')}
        />)}
        <div onClick={() => setIsShowCardModal(true)} className='add-payments-card text-white'>
          <button onClick={() => setIsShowCardModal(true)}>
            <p className="mb-0">{buttonsText.addNewCard}</p>
            <img src={"/assets/images/circle-black-plus-icon.svg"} alt="" />
          </button>
        </div>
      </div>
      <Stripe
        isShow={isShowCardModal}
        onClose={() => setIsShowCardModal(false)}
        onDone={(isNeedToSave: any) => {
          setIsShowCardModal(false);
          getCardData();
        }}
        onSubscribe={() => setIsShow(true)}
        redirectType='profile'
      />
      <ConfirmSubscription isShow={isShow} onClose={() => setIsShow(false)} />
    </div>
  )
}
export default Payment;