import React from 'react'

interface ProfileTabsProps {
    data: Array<any>;
    onClick?: (idx: any) => void;
    selectedIdx: number
}

const ProfileTabs: React.FC<ProfileTabsProps> = (props) => {

    return (
        <div className="bottom-tabs-wo">
            <ul className="nav justify-content-between remove-arrows">
                {props.data.map((item, idx) => {
                    return (
                        <li key={idx}
                            onClick={() => props?.onClick?.(idx)}
                            className="nav-item">
                            <a style={{ textTransform: 'capitalize' }} className={props.selectedIdx === idx ? "nav-link active" : "nav-link"} data-bs-toggle="pill" href={`#${item?.toLowerCase()}`}>{item}</a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default ProfileTabs