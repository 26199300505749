import React from 'react'
import Button from '../../../global/ui/Button'
import { BUTTON_TYPE } from '../../../types'
import { ModalBody } from 'react-bootstrap'

interface FinalProps {
    onFinal: () => void
}

const Final: React.FC<FinalProps> = (props) => {
    return (
        <ModalBody className="p-0 text-start pb-0 mx-auto">
            <div className='broadcast_message_sent text-center'>
                <img src="/assets/images/megaphone-loud.svg" alt="" />
                <h3 className='mb-4'>Broadcast Message Sent</h3>
                {/* <p>We have sent a verification link to your email</p> */}
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt='0k'
                    style={{ marginTop: "0px", width: "auto" }}
                    onClick={props.onFinal}
                    className={'btn btn-warning w-100 with-after-shadow shadow-none m-0 mt-4'}
                />
            </div>
        </ModalBody>
    )
}

export default Final