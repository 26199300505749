import React, { useContext, useEffect, useState } from 'react'
import SideBarLayout from '../../global/layout/SideBarLayout'
import SearchWithHeading from '../../global/ui/SearchWithHeading'
import queryString from 'query-string';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { makeGetCall, makePatchCall, makePostCall } from '../../effects/requests';
import { ApiResponseType, EXPLORE_TYPE, ROLE_TYPE, USER_PRESS_ACTION_TYPE } from '../../types';
import { buttonsText } from '../../utils/constants';
import CrewMemFilter from './CrewMemFilter';
import ProductionCrews from './ProductionCrews';
import Crews from './Crews';
import ViewCrewDetails from './ViewCrewDetails';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { createFullName, generateFormData } from '../../utils';
import AddProductionCrewMem from './AddProductionCrewMem';
import AssignJob from './AssignJob';
import CustomPagination from '../../global/ui/CustomPagination';
import { endPoints } from '../../utils/endpoints';
import { MyContext } from '../../context';
import InviteCrewMemToProductionCompany from './InviteCrewMemToProductionCompany';
import AddUser from '../../components/modal/AddUser';
import DownloadCSV from '../../components/modal/downloadCSV';
import ImportCSV from '../../components/modal/ImportCSV';

const CrewMembers: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const { userData } = useContext(MyContext)

    const parsed = queryString.parse(location.search);

    const [allData, setAllData] = useState([]);
    const [count, setCount] = useState(0);
    const [selectedIDS, setSelectedIDS] = useState([]);
    const [crewMemDetails, setCrewMemDetails] = useState<any>(null);
    const [actionType, setActionType] = useState(null);
    const [isShow, setIsShoow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowManualBulk, setIsShowManualBulk] = useState({ isShowManual: false, isShowBulk: false });
    const [importCsv, setImportCsv] = useState(false);

    useEffect(() => {
        if (location?.state?.userId && location?.state?.userId?.length > 0) {
            fetchDetails(location?.state?.userId)
            location.state.userId = '';
        }
    }, [location])

    const getCrewMem = () => {
        if (!userData?.id) return
        const params: any = { ...parsed };
        params.offset = params?.search ? 0 : Number(params.limit) - 10;
        delete params.limit
        if (parsed.type === EXPLORE_TYPE.MY_PROD) {
            params.production_company_name = userData?.owner_profile?.company_name ? userData?.owner_profile?.company_name : userData?.production_company_name ? userData?.production_company_name : '';
            // params.offset = 0
        }
        if (!(params?.start_date && params?.end_date)) {
            params.start_date = ''
            params.end_date = ''
        }
        delete params.type
        makeGetCall(endPoints.USERLIST, { ...params, user_type: ROLE_TYPE.TYPE_CREW }).then((res: ApiResponseType<any>) => {
            setAllData(res.results);
            setCount(res?.count);
        })
    }

    useEffect(getCrewMem, [JSON.stringify(parsed), userData?.id]);

    const updateUrls = (key: any, value: any) => {
        const params = parsed;
        params[key] = value;
        if (key === 'department') {
            params['role'] = ''
        }
        navigate(`/explore/?${createSearchParams(params as never)}`);
    }

    const fetchDetails = (id: any) => {
        const urls = `${endPoints.USER}${id}`
        makeGetCall(urls).then((res: ApiResponseType<any>) => {
            setCrewMemDetails(res?.data)
        })
    }

    const handleActiveDeActive = () => {
        setActionType(null)
        const urls = `${endPoints.USER}${crewMemDetails?.id}/`;
        const payload = { active_at_company: actionType == USER_PRESS_ACTION_TYPE.TYPE_ACTIVE_USER ? true : false }
        makePatchCall(urls, payload, true).then((res: ApiResponseType<any>) => {
            getCrewMem()
        })
    }

    const handleUpdateRole = (apiPayload: any) => {
        setActionType(null)
        const fd = generateFormData(apiPayload);
        makePatchCall(`${endPoints.USER}${crewMemDetails?.id}/`, fd, true).then((res: ApiResponseType<any>) => { getCrewMem() })
    }

    const handleAssignJob = (data: any) => {
        setActionType(null)
        const payload = {
            job: data?.id || '',
            crew_member: crewMemDetails?.id || ''
        }
        makePostCall(endPoints.JOB_REQUEST, payload, true).then((res: ApiResponseType<any>) => { })
    }

    const handleInvite = (data: any) => {
        setActionType(null)
        const apiPayload = {
            ...data,
            user: crewMemDetails?.id
        }
        const fd = generateFormData(apiPayload);
        makePostCall(endPoints.COMPANY_INVITE, fd, true).then((res: ApiResponseType<any>) => { })
    }

    const onUserCreate = (data: any) => {
        setIsLoading(true)
        const fd = generateFormData(data);
        makePostCall(endPoints.CREATE_USER, fd, true).then((res: ApiResponseType<any>) => {
            setIsLoading(false)
            if (res?.status?.code == 200) {
                setIsShoow(false);
                setIsShowManualBulk({ isShowManual: false, isShowBulk: false })
            }
        }).catch((err) => setIsLoading(false))
    }
    const btnText = actionType === USER_PRESS_ACTION_TYPE.TYPE_ACTIVE_USER ? 'Activate User' : 'Deactivate User'
    const descText = actionType === USER_PRESS_ACTION_TYPE.TYPE_ACTIVE_USER ? `Are you sure you want to re-activate ${createFullName(crewMemDetails?.first_name, crewMemDetails?.last_name)}?` : `Are you sure you want to deactivate ${createFullName(crewMemDetails?.first_name, crewMemDetails?.last_name)}?`

    return (
        <SideBarLayout>
            <div className="right-content-full-screen">
                <SearchWithHeading
                    placeholder="Search by name, email or phone"
                    onSearch={(value) => updateUrls('search', value)}
                    value={parsed?.search || ''}
                    heading='Production Crew'
                    addText={buttonsText.addCrew}
                    onAdd={() => setIsShoow(true)}
                />
                <div className="bottom-content-full-screen">
                    <CrewMemFilter
                        count={count}
                        updateValue={updateUrls}
                        data={parsed}
                        showing={allData?.length || 0}
                        onClear={() => navigate(`/explore/?type=${parsed.type}&search=&department=&location=&limit=10&role=&joining_date_current_company=&start_date=&end_date=&permission_type=`)}
                    />
                    {parsed.type === EXPLORE_TYPE.MY_PROD
                        ?
                        <div className="common-table">
                            <ProductionCrews
                                data={allData}
                                onClick={(item) => fetchDetails(item?.id)}
                                onSelect={(id, type, checked) => {
                                    let ids: any = [];
                                    if (type === 'M') {
                                        allData.map((item: any) => {
                                            if (checked) ids.push(item?.id as never)
                                        })
                                    } else {
                                        ids = [...selectedIDS]
                                        const idx = ids.findIndex((item: any) => item === id);
                                        if (idx === -1) {
                                            ids.push(id as never)
                                        } else {
                                            ids.splice(idx, 1)
                                        }
                                    }
                                    setSelectedIDS(ids)
                                }}
                                selectedIDS={selectedIDS}
                            />
                        </div>
                        :
                        <div className="production-crow-exploree">
                            <Crews
                                data={allData}
                                onClick={(item) => fetchDetails(item?.id)}
                            />
                        </div>
                    }
                    {Number(count) > 10 && <CustomPagination queryParams={parsed} count={count} onUpdate={updateUrls} />}
                </div>
            </div>
            <ViewCrewDetails
                visible={crewMemDetails ? true : false}
                onClose={() => setCrewMemDetails(null)}
                crewDetails={crewMemDetails}
                onAction={(type) => {
                    if (type == USER_PRESS_ACTION_TYPE.TYPE_FLAG) {
                        alert("To be implement")
                    }
                    setActionType(type)
                }}
                type={parsed.type}
                onRefView={(item: any) => {
                    if (item?.user_type === ROLE_TYPE.TYPE_CREW) {
                        fetchDetails(item?.reference_id)
                    } else {
                        navigate(`/suppliers/?detail_id=${item?.reference_id}`)
                    }
                }}
            />
            <ConfirmationModal
                visible={(actionType == USER_PRESS_ACTION_TYPE.TYPE_ACTIVE_USER || actionType == USER_PRESS_ACTION_TYPE.TYPE_INACTIVE_USER) ? true : false}
                onClose={() => setActionType(null)}
                btnTxt={btnText}
                title={btnText}
                description={descText}
                onDone={handleActiveDeActive}
            />
            <AddProductionCrewMem
                isShow={isShowManualBulk.isShowManual}
                onClose={() => setIsShowManualBulk({ isShowManual: false, isShowBulk: false })}
                onCreate={onUserCreate}
                isLoading={isLoading}
            />

            <AddUser
                isShow={isShow}
                onClose={() => setIsShoow(false)}
                onClick={setIsShowManualBulk}
            />
            <ImportCSV show={importCsv} closePopup={() => setImportCsv(false)} onClick={setIsShowManualBulk} setIsShoow={setIsShoow} />
            <DownloadCSV
                show={isShowManualBulk.isShowBulk}
                closePopup={() => setIsShowManualBulk({ isShowManual: false, isShowBulk: false })}
                onContinue={() => setImportCsv(true)}
            />
            <AssignJob
                onClose={() => setActionType(null)}
                onSubmit={handleAssignJob}
                isShow={actionType === USER_PRESS_ACTION_TYPE.TYPE_ASIGN_JOB ? true : false}
            />
            <InviteCrewMemToProductionCompany
                isShow={(actionType === USER_PRESS_ACTION_TYPE.TYPE_IP || actionType == USER_PRESS_ACTION_TYPE.TYPE_UPDATE_ROLE) ? true : false}
                onClose={() => setActionType(null)}
                onInvite={(data) => {
                    if (actionType === USER_PRESS_ACTION_TYPE.TYPE_IP) handleInvite(data)
                    else handleUpdateRole(data)
                }}
                title={actionType == USER_PRESS_ACTION_TYPE.TYPE_UPDATE_ROLE ? 'Update Role' : ''}
                hideDesc={actionType == USER_PRESS_ACTION_TYPE.TYPE_UPDATE_ROLE ? true : false}
                btnTxt={actionType == USER_PRESS_ACTION_TYPE.TYPE_UPDATE_ROLE ? 'Update Team Role' : ''}
            />
        </SideBarLayout>
    )
}

export default CrewMembers