import React from 'react'
import Products from './Products'
import SupplierMedia from './SupplierMedia'

interface ServicesProps {
    data: any;
    selectedIdx?: number
}

const Services: React.FC<ServicesProps> = (props) => {
    return (
        <div className={`tab-pane fade ${props?.selectedIdx === 1 && 'active show'}`} id="services" >
            <div className='seplayre-services'>
                {props.data?.supplier_profile?.services && props.data?.supplier_profile?.services?.length > 0 &&
                    <Products
                        title={`${props?.data?.supplier_profile?.company_name} Offers Following Services :`}
                        data={props.data?.supplier_profile?.services ? props.data?.supplier_profile?.services?.split(',') : []}
                    />
                }
                {props.data?.supplier_profile?.products && props.data?.supplier_profile?.products?.length > 0 &&
                    <Products
                        title={`${props?.data?.supplier_profile?.company_name} Offers Following Products :`}
                        data={props.data?.supplier_profile?.products ? props.data?.supplier_profile?.products?.split(',') : []}
                    />
                }
                {props.data?.supplier_media && props.data?.supplier_media?.length > 0 &&
                    <SupplierMedia
                        data={props.data?.supplier_media}
                    />
                }
            </div>
        </div>
    )
}

export default Services