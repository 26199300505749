import React, { useContext, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PageNotFound from '../pages/PageNotFound';
import AuthRoutes from './AuthRoutes';
import UnAuthRoutes from './UnAuthRoutes';
import Test from '../pages/test'
import CreateProductionOwnerProfile from '../pages/viewProfile/createProductionOwnerProfile/index'
import CreateSupplierProfile from '../pages/viewProfile/createSupplierProfile';
import ForgotPassword from '../pages/signIn/forgotPassword';
import ResetPassword from '../pages/signIn/resetPassword';
import OnBoarding from '../pages/signUp/onBoarding';
import VerifyEmail from '../pages/signUp/verifyEmail';
import Signup from '../pages/signUp/signup';
import { routes } from '../utils/routesConstants';
import SignIn from '../pages/signIn';
import Legals from '../pages/legalPages';
import ViewProfile from '../pages/viewProfile'
import Subscription from '../pages/subscription';
import Suppliers from '../pages/suppliers';
import CrewMembers from '../pages/crewMembers';
import JobList from '../pages/jobsManagement';
import Notifications from '../pages/notification';
import Schedule from '../pages/scheduleManagement';
import Chat from '../pages/messages/Chat';
import { cookieService } from '../services';
import { cookieServiceParam } from '../utils/constants';
import { MyContext } from '../context';
import { PERMISSION_TYPE, ROLE_TYPE, USER_ACTION_TYPE } from '../types';
import PaymentComplete from '../components/stripe/PaymentComplete';
import { SubscriptionExpire } from '../pages/subscription/SubscriptionExpire';


const RouteStack: React.FC = (props) => {

    const { userData } = useContext(MyContext);
    const navigate = useNavigate()
    const isAuthenticated = cookieService.getCookie(cookieServiceParam.token) ? true : false;
    const paymentData = cookieService.getCookie(cookieServiceParam.payment_data) ? true : false;
    useEffect(() => {
        let routeUrls: any = null
        if (isAuthenticated && userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type !== PERMISSION_TYPE.TYPE_CREW_MEMBER && userData?.permission_type !== PERMISSION_TYPE.TYPE_PROD_OWNER) {
            routeUrls = '/view-profile'
        }
        else if (isAuthenticated && userData && !userData?.subscription_id && !userData?.account_completed) {
            routeUrls = paymentData ? '/payment-success' : '/subscription';
        } else if (isAuthenticated && userData && userData?.account_completed && !userData?.subscription_id) {
            routeUrls = paymentData ? '/payment-success' : userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? '/expire-subscription' : '/subscription';
        }
        if (routeUrls) {
            setTimeout(() => {
                navigate(routeUrls)
            }, 300);
        }
    }, [userData?.subscription_id])

    return (
        <>
            <div className='payment-layout' id='my_container' />
            <Routes>
                {/* Public Routes */}
                <Route element={<UnAuthRoutes />}>
                    <Route path={routes.onboarding} element={<OnBoarding />} />
                    <Route path={routes.forgotPassword} element={<ForgotPassword />} />
                    <Route path={routes.resetPassword} element={<ResetPassword />} />
                    <Route
                        path={routes.signup}
                        element={<Signup />}
                    />
                    <Route
                        path="/sign-in"
                        element={<SignIn />}
                    />
                    <Route path='/legals/:type' element={<Legals />} />
                </Route>

                {/* Private Routes */}
                <Route element={<AuthRoutes />}>
                    <Route path={routes.verifyEmail} element={<VerifyEmail />} />
                    <Route path={routes.subscription} element={<Subscription />} />
                    <Route path={routes.expireSubscription} element={<SubscriptionExpire />} />
                    <Route
                        path={routes.createOwnerProfile}
                        element={<CreateProductionOwnerProfile />}
                    />
                    <Route
                        path={routes.viewProfile}
                        element={<ViewProfile />}
                    />
                    <Route
                        path={routes.createSupplierProfile}
                        element={<CreateSupplierProfile />}
                    />
                    <Route path={routes.jobs} element={<JobList />} />
                    {/* <Route path={routes.messages} element={<Messages />} /> */}
                    <Route path={routes.messages} element={<Chat />} />
                    <Route path={routes.schedule} element={<Schedule />} />
                    <Route path={routes.suppliers} element={<Suppliers />} />
                    <Route path={routes.explore} element={<CrewMembers />} />
                    <Route path={routes.notifications} element={<Notifications />} />
                    <Route path={routes.paymentComplete} element={<PaymentComplete />} />
                </Route>
                <Route path={'/test'} element={<Test />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </>
    )

}

export default RouteStack;