import Button from '../../../global/ui/Button';
import { BUTTON_TYPE, USER_ACTION_TYPE } from '../../../types';
import { buttonsText, defaultCountryOptions, errorMessage, labels, placeholder } from '../../../utils/constants';
import MobileNumber from '../../../global/ui/mobileNumber';
import { useForm, FormProvider } from "react-hook-form";
import { MOBILE_VALIDATION } from '../../../lib/input/validation-rules';
import { supplierInfoData } from '../../../data/formData';
import { apiConstants } from '../../../utils/apiConstants';
import InputField from '../../../lib/input';
import { useEffect, useState } from 'react';
import { generateFormData, getNumberWithIsd, getSelectedIsd, showToast, validatePhoneNumber } from '../../../utils';
import UserSnap from '../../../global/ui/UserSnap';
import VerifyPassword from '../../../components/modal/VerifyPassword';
import VerifyPhoneNumber from '../../../components/modal/VerifyMobNumber';

interface info {
  userData: any;
  onSubmit: (value: any) => void;
  actionType?: any;
  editedSuccessfully?: boolean;
  setEditedSuccessfully: () => void;
}
const Info = (props: info) => {

  const { ...methods } = useForm({
    mode: 'all',
    defaultValues: { ...supplierInfoData(props.userData) },
    reValidateMode: 'onBlur',
  });

  const [countryOptions, setCountryOptions] = useState(defaultCountryOptions);
  const [password, setPassword] = useState('');
  const [showPwdPopUp, setShowPwdPopUp] = useState(false);
  const [fieldData, setFieldData] = useState({});
  const [numberVerifyPopup, setNumberVerifyPopup] = useState(false);
  const [updatedField, setUpdatedField] = useState(false)

  const verifyPwd = (data: any) => {
    const pwdData = { ...fieldData, 'password': data.password }
    onSubmit(pwdData)
    setShowPwdPopUp(false);
    setFieldData({});
    setPassword('');
  }
  useEffect(() => {
    methods.setValue(apiConstants.profile_image, props.userData?.profile_image, { shouldValidate: true })
  }, [props?.userData]);

  useEffect(() => {
    if (props.editedSuccessfully && updatedField) {
      setNumberVerifyPopup(true);
    }
  }, [props.editedSuccessfully])
  useEffect(() => {
    if (props.userData?.mobile_number) {
      const [isdCode]: any = getNumberWithIsd(props.userData?.mobile_number);
      setCountryOptions(getSelectedIsd(isdCode))
    }
  }, [props.userData?.mobile_number])


  const onSubmit = (values: any) => {
    props.setEditedSuccessfully();
    const apiPayload = { ...values };
    if (values?.[apiConstants.mobile_number]) {
      if (values?.[apiConstants.mobile_number][0] !== '+') {
        apiPayload[apiConstants.mobile_number] = `${countryOptions?.dial_code}${values?.[apiConstants.mobile_number]}`
        if (!validatePhoneNumber(apiPayload?.[apiConstants.mobile_number])) {
          showToast(errorMessage.invalid_mobile_msg, 'error', 'mobile')
          return
        }
      }
    }
    if (apiPayload[apiConstants.mobile_number] == props?.userData?.[apiConstants.mobile_number]) {
      delete apiPayload[apiConstants.mobile_number]
    }
    else if (props?.actionType === 'update' && Object.keys(fieldData).length === 0) {
      setFieldData(apiPayload as never);
      setShowPwdPopUp(true);
      setUpdatedField(true)
      return;
    }
    if (methods.getValues(apiConstants.profile_image) == props.userData?.profile_image) {
      delete apiPayload[apiConstants.profile_image]
    }
    
    if (props?.actionType === USER_ACTION_TYPE.TYPE_UPDATE) {
      const firstSpaceIndex = apiPayload?.[apiConstants.contact_person].indexOf(' ');

      if (firstSpaceIndex !== -1) {
        apiPayload[apiConstants.first_name] = apiPayload?.[apiConstants.contact_person].substring(0, firstSpaceIndex);
        apiPayload[apiConstants.last_name] = apiPayload?.[apiConstants.contact_person].substring(firstSpaceIndex + 1);
      }
      else{
        apiPayload[apiConstants.first_name] = apiPayload?.[apiConstants.contact_person];
        apiPayload[apiConstants.last_name] = '';
      }
    }
    delete apiPayload[apiConstants.contact_person]
    //const fd = generateFormData(apiPayload);
    props.onSubmit(apiPayload)
  }
  return (
    <FormProvider {...methods} >
      <form action="" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <div>
          <div className="text-center">
            <UserSnap
              onChange={(value) => methods.setValue(apiConstants.profile_image, value, { shouldValidate: true })}
              value={methods.getValues(apiConstants.profile_image)}
              label={labels.addLogo}
              showImg
            />
          </div>
          <div className="all-form">
            {props?.actionType === USER_ACTION_TYPE.TYPE_UPDATE &&
              <InputField
                label={labels.supplierContactName}
                placeholder={placeholder.supplierName}
                name={apiConstants.contact_person}
                type='text'
                className='black-input'
                rules={{ required: false }}
                maxLength={150}
              />
            }
            <InputField
              label={labels.supplier_website}
              placeholder={placeholder.website_url}
              name={apiConstants.website}
              type='text'
              containerStyle={{ marginTop: '25px', }}
              className='black-input'
              rules={{
                required: false
              }}
              maxLength={250}
            />
            <MobileNumber
              onSelect={(data: any) => setCountryOptions(data)}
              selectedOption={countryOptions}
              name={apiConstants.mobile_number}
              label={labels.contactNumber}
              placeholder={placeholder.contactNumber}
              type='number'
              rules={MOBILE_VALIDATION}
              containerStyle={{ marginTop: '25px', }}
              className='black-input'
              selectLabel="Select"
            />
          </div>
        </div>
        <div className="main-button">
          <Button
            type={BUTTON_TYPE.TYPE_SUBMIT}
            btnTxt={props?.actionType === USER_ACTION_TYPE.TYPE_UPDATE ? buttonsText.saveAndUpdate : buttonsText.continue}
            className={"btn btn-warning w-100 with-after-shadow shadow-none"}
          />
        </div>
        <VerifyPassword pwd={password} show={showPwdPopUp} closePopup={() => { setShowPwdPopUp(false); props.setEditedSuccessfully(); }} onSubmit={verifyPwd} />
        <VerifyPhoneNumber show={numberVerifyPopup} closePopup={() => { setNumberVerifyPopup(false); props.setEditedSuccessfully(); }} />
      </form>
    </FormProvider>
  )
}
export default Info;