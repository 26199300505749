import { errorMessage } from "../../utils/constants"

export const REQUIRED_VALIDATION = (name: any, plural?: boolean) => { return { required: errorMessage.required_validation(name, plural) } }

export const MOBILE_VALIDATION = {
    required: errorMessage.required_validation('Mobile number'),
    pattern: {
        value: /^.{9,15}$/,
        message: errorMessage.invalid_mobile_msg,
        required: true,
    },
}
export const NAME_VALIDATION = (name: any)=>{
    return {
    required: errorMessage.required_validation(name),
    pattern: {
        value: /^[^0-9]*$/,
        message: errorMessage.invalid_name,
        required: true,
    }
}
}

export const EMAIL_VALIDATION = {
    required: errorMessage.required_validation('Email'),
    pattern: {
        value: /\b[\w\\.+-]+@[\w\\.-]+\.\w{2,10}\b/,
        message: errorMessage.invalid_email_msg,
        required: true,
    },
}

// Minimum eight characters
export const PASS_VALIDATION = (message?: any) => {
    return {
        required: errorMessage.required_validation(message || 'Password'),
        pattern: {
            value: /^(?=.*\d).{8,}$/,
            message: errorMessage.invalid_pass_msg,
            required: true,
        },
    }
}
export const POSTCODE_VALIDATION = ()=>{
    //post_code_min_length: number, post_code_max_length: number, is_numeric: boolean) => {
    //let expression1 = is_numeric ? `^[0-9]{${post_code_min_length},${post_code_max_length}}$` : `^[A-Za-z0-9]{${post_code_min_length},${post_code_max_length}}$`;
    //let expression = `^.{${post_code_min_length},${post_code_max_length}}$`;
    let expression1 = `^[a-zA-Z0-9!@#$%^&*()-_+=~{}|:;<>,.?/]{1,8}$`;
    return {
        required: errorMessage.required_validation('Post Code'),
        pattern: {
            value: new RegExp(expression1),
            message: errorMessage.invalid_post_code,
            required: true,
        }
    }
}
export const MOBILE_NO_VALIDATION = (exp: any) => {
    return {
        required: errorMessage.required_validation('Mobile number'),
        pattern: {
            value: exp,
            message: errorMessage.invalid_mobile_msg,
            required: true,
        }
    }
}

export const POST_VALIDATION = (reg: any, message: any) => {
    return {
        required: errorMessage.required_validation('Post Code'),
        pattern: {
            value: reg,
            message: message,
            required: true,
        },
    }
}
export const PIN_VALIDATION = {
    required: errorMessage.required_validation('Pin verification'),
    pattern: {
        value: /^[0-9]{6}$/,
        message: errorMessage.pin_validation,
        required: true,
    },
}