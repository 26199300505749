import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { cookieService } from '../services';
import { cookieServiceParam } from '../utils/constants';

const PrivateRoutes: React.FC<any> = () => {

    const isAuthenticated = cookieService.getCookie(cookieServiceParam.token) ? true : false;
    return isAuthenticated ? <Outlet /> : <Navigate to='/' />
}

export default PrivateRoutes