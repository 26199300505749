import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import ActionableModal from '../../../components/modal/ActionableModal';
import { MyContext } from '../../../context';
import { makeDeleteCall } from '../../../effects/requests';
import Button from '../../../global/ui/Button';
import UploadAndDisplayImage from '../../../global/ui/uploadAndDisplayImage';
import { BUTTON_TYPE, SERVICE_AND_PROD_TYPE, USER_ACTION_TYPE } from '../../../types';
import { showToast } from '../../../utils';
import { apiConstants } from '../../../utils/apiConstants';
import { buttonsText, errorMessage, labels, placeholder } from '../../../utils/constants';
import { endPoints } from '../../../utils/endpoints';
import AddServiceAndProduct from './AddServiceAndProduct';
interface ProductServicesProps {
  userData: any;
  onSubmit: (value: any) => void;
  actionType?: any
}

const ProductServices: React.FC<ProductServicesProps> = (props) => {

  const { userData } = useContext(MyContext);
  const [selectedServices, setSelectedServices] = useState([])
  const [selectedProduct, setSelectedProduct] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [type, setType] = useState<any>(null);

  useEffect(() => {
    if (userData?.supplier_profile?.services?.length > 0) {
      setSelectedServices(userData?.supplier_profile?.services.split(','))
    }
    if (userData?.supplier_profile?.products?.length > 0) {
      setSelectedProduct(userData?.supplier_profile?.products.split(','))
    }
    if (userData?.supplier_media?.length > 0) {
      setSelectedImages(userData?.supplier_media)
    }
  }, [])
  const { ...methods } = useForm({
    mode: 'all',
    defaultValues: { [apiConstants.name]: '' },
    reValidateMode: 'onBlur',
  });

  const onDone = (data: any) => {
    const error = type === SERVICE_AND_PROD_TYPE.TYPE_PRODUCT ? errorMessage.duplicate_product : errorMessage.duplicate_service
    const temp: any = type === SERVICE_AND_PROD_TYPE.TYPE_PRODUCT ? [...selectedProduct] : [...selectedServices];
    const idx = temp.findIndex((item: any) => item === data?.name);
    if (idx > -1) {
      showToast(error, 'error');
      setType(null)
      return
    }
    temp.push(data?.name);
    if (type === SERVICE_AND_PROD_TYPE.TYPE_PRODUCT) {
      setSelectedProduct(temp);
    } else {
      setSelectedServices(temp)
    }
    setType(null)
  }

  const onSubmit = () => {
    let file = []
    if (selectedServices?.length > 0 || selectedProduct?.length > 0) {
      if (selectedImages?.length > 0) {
        file = selectedImages.map((item: any) => {
          return item?.file
        })
      }
      const fd = new FormData();
      fd.append('services', selectedServices as never);
      fd.append('products', selectedProduct as never);
      if (userData?.subscription_id) {
        fd.append('account_completed', true as never);
      }
      if (file?.length > 0) file.map((img: any) => { fd.append('media', img) })
      props.onSubmit(fd)
    } else {
      showToast('Please select either a service or a product.', 'error', 'service')
    }
  }

  return (
    <>
      <div className="all-form mt-2">
        <AddServiceAndProduct
          label={labels.addServices}
          placeholder={placeholder.addService}
          onShow={() => {
            setType(SERVICE_AND_PROD_TYPE.TYPE_SERVICE);
            methods.reset();
          }}
          onRemove={(idx: any) => {
            const temp = [...selectedServices];
            temp.splice(idx, 1);
            setSelectedServices(temp)
          }}
          data={selectedServices}
        />
        <AddServiceAndProduct
          label={labels.addProduct}
          placeholder={placeholder.addProduct}
          onShow={() => {
            setType(SERVICE_AND_PROD_TYPE.TYPE_PRODUCT);
            methods.reset()
          }}
          onRemove={(idx: any) => {
            const temp = [...selectedProduct];
            temp.splice(idx, 1);
            setSelectedProduct(temp)
          }}
          data={selectedProduct}
        />
      </div>
      <div className='select-pupinput mb-4'>
        <h5>{labels.catalogueImg}</h5>
        <div className='input-type-file-multiple'>
          <div className="form_group file-upload black-input">
            <div className="input_box">
              <UploadAndDisplayImage
                images={selectedImages}
                onChange={(data: any) => {
                  let temp: any = [...selectedImages]
                  temp = [...temp, ...data]
                  setSelectedImages(temp)
                }}
                onRemove={(idx: any, id?: any) => {
                  const temp = [...selectedImages]
                  temp.splice(idx, 1);
                  setSelectedImages(temp);
                  if (id) {
                    makeDeleteCall(endPoints.SUPPLIER_MEDIA + `${id}/`)
                      .then((res) => {

                      })
                      .catch((err) => {

                      })
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="main-button">
        <Button
          type={BUTTON_TYPE.TYPE_BUTTON}
          btnTxt={props?.actionType === USER_ACTION_TYPE.TYPE_UPDATE ? buttonsText.saveAndUpdate : buttonsText.continue}
          className={"btn btn-warning w-100 with-after-shadow shadow-none"}
          onClick={onSubmit}
        />
      </div>
      <ActionableModal
        isShow={type ? true : false}
        onClose={() => {
          setType(null);
          methods.reset()
        }}
        onDone={onDone}
        name={apiConstants.name}
        label={type === SERVICE_AND_PROD_TYPE.TYPE_PRODUCT ? labels.addProduct : labels.serviceProvided}
        placeholder={type === SERVICE_AND_PROD_TYPE.TYPE_PRODUCT ? placeholder.addProduct : placeholder.addService}
        config={methods}
        title={type === SERVICE_AND_PROD_TYPE.TYPE_PRODUCT ? 'Add Product' : 'Add Service'}
      />
    </>
  )
}

export default ProductServices