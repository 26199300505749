import React from "react";
import { BUTTON_TYPE } from "../../types";
import { emptyFunction } from "../../utils";
import Loader from "./Loader";

interface ButtonProps {
	onClick?: () => void;
	className?: string;
	btnTxt: string;
	type?: any;
	restProps?: any;
	isLoading?: boolean;
	disabled?: boolean;
	loaderColor?: string;
	style?: React.CSSProperties
}

const Button = (props: ButtonProps) => {
	const {
		onClick = emptyFunction,
		className,
		btnTxt = "",
		isLoading = false,
		disabled,
		type = BUTTON_TYPE.TYPE_BUTTON,
		loaderColor,
		...restProps
	} = props;

	return (
		<button
			style={props?.style}
			type={type}
			disabled={disabled}
			onClick={() => {
				if (!isLoading) onClick();
			}}
			className={`common_btn ${className} ${disabled ? "disabled" : ""}`}
			{...restProps}
		>
			{isLoading ? <Loader /> : btnTxt}
		</button>
	);
};

export default Button;
