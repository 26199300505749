import React, { useContext, useEffect, useRef, useState } from "react";
import ChatInput from "./ChatInput";
import FullViewImage from "../../components/modal/fullViewImage";
import useWebSocket from "../../hooks/useWebSocket";
import { makeDeleteCall, makeGetCall } from "../../effects/requests";
import { endPoints } from "../../utils/endpoints";
import { ApiResponseType, Message, MESSAGE_STATUS, MESSAGE_TEXT, PERMISSION_TYPE, REQ_STATUS, USER_PRESS_ACTION_TYPE, USER_TYPE } from "../../types/index";
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import useDebounce from "../../hooks/useDebounce";
import { emptyFunction, showToast } from "../../utils";
import queryString from "query-string";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { DateUtils } from '../../utils/dateUtils'
import { ROLE_TYPE } from "../../types/index";
import ViewPdf from "../../components/modal/ViewPdf";
import { MyContext } from "../../context";
import ViewCrewDetails from "../crewMembers/ViewCrewDetails";
import ViewSupplierDetails from "../suppliers/ViewSupplierDetails";
import Logo from "../../components/commonComponent/ProfileImage";

interface ChatContainerProps {
  currentChat: any;
  currentUser: any;
  setIsShowMessage: (e: boolean) => void;
  updateMsg: (data: any) => void;
  rejectRequest: () => void;
}

const ChatContainer: React.FC<ChatContainerProps> = ({
  currentChat,
  currentUser,
  setIsShowMessage,
  updateMsg,
  rejectRequest
}) => {
  const location = useLocation();
  const [messages, setMessages] = useState<Message[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [fullView, setFullView] = useState<boolean>(false);
  const [enlargeImage, setEnlargeImage] = useState<any>();
  const [fullSearch, setFullSearch] = useState(false);
  const [nextURL, setNextURL] = useState('');
  const [searchParam, setSearchParam] = useState('')
  const [selectedPdf, setSelectedPdf] = useState<any>(null)
  const debouncedValue = useDebounce(searchParam, 500)
  const [showDownload, setShowDownload] = useState(true);
  const parsed = queryString.parse(location.search);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [reqAcceptedRejected, setReqAcceptedRejected] = useState(false);
  const [showDelButton, setDelButton] = useState(false);
  const [status, setStatus] = useState('')
  const { globalSocketNotification, userData, updateGlobalSocketNotification } = useContext(MyContext);
  const [viewProfile, setViewProfile] = useState<boolean>(false);
  const [actionType, setActionType] = useState(null);
  const [profileDetails, setProfileDetails] = useState<any>(null);
  const navigate = useNavigate();
  const [isRoomOpen, errorMessage, onSendMessage, onClose] = useWebSocket({
    roomID: currentChat?.chat?.id,
    userID: currentUser?.id || '',
    initializeWebSocket: true,
    onMessage: (data) => {
      if (data?.message || data?.attachment_url) {
        setMessages((msg) => [data, ...msg]);
        updateMsg(data)
      }
      else if (data?.status) {
        setTimeout(() => {
          getMessage({ room_id: currentChat?.chat?.id });
        }, 1000)
      }
    }
  });
  const updateUrls = (key: any, value: any) => {
    const params = parsed;
    params[key] = value;
    navigate(`/messages/?${createSearchParams(params as never)}`);
  }
  useEffect(() => {
    if (viewProfile) {
      const urls = `${endPoints.USER}${currentChat?.chat?.other_user?.id}`
      makeGetCall(urls, false).then((res: ApiResponseType<any>) => {
        setProfileDetails(res?.data)
      })
    }
  }, [viewProfile])
  useEffect(() => {
    //console.log('Component mounted');
    return () => {
      //console.log('Component unmounted');
      onClose();
    };
  }, []);
  useEffect(() => {
    return () => {
      //console.log("Room socket closed 1");
      onClose();
    };
  }, [currentChat?.chat?.id]);

  const getMessage = (paramsMessage: any) => {
    makeGetCall(endPoints.chat_messages_url, paramsMessage, false, true)
      .then((res) => {
        setMessages(res?.results)
        if (res.next) {
          setNextURL(res.next)
        }
      })
      .catch((err) => {

      })
  }
  //   useEffect(() => {
  //     // 👇️ scroll to bottom every time messages change
  //     bottomRef.current?.scrollIntoView({ alignToTop: false });
  // }, [messages, messages.length]);
  useEffect(() => {
    if (currentChat?.chat?.id && isRoomOpen) {
      const paramsMessage = {
        room_id: currentChat?.chat?.id,
        search: debouncedValue
      }
      getMessage(paramsMessage);
    }
    else {
      setMessages([]);
      setNextURL('');
    }
  }, [currentChat?.chat?.id, debouncedValue, globalSocketNotification])
  useEffect(() => {
    if (globalSocketNotification) {
      getMessage({ room_id: currentChat?.chat?.id })
      updateGlobalSocketNotification(false)
    }
  }, [globalSocketNotification])



  const handleSendMessage = (msg: string, attachment_url?: string, attachment_type?: string) => {
    if (currentUser && isRoomOpen) {
      onSendMessage({
        message: msg || "",
        //sent_by_id: currentUser?.other_user?.id || '',
        attachment_url: attachment_url,
        attachment_type: attachment_type,
        receiver_id: currentChat?.chat?.other_user?.id || ''
      })
    }
  };

  const acceptRejectRequest = (acceptorReject: string) => {
    const apiUrl = `${endPoints.accept_chat_invite_url}${currentChat?.chat?.id}`;
    const params = { status: acceptorReject };
    makeGetCall(apiUrl, params).then((res) => {
      if (acceptorReject === 'A') {
        updateUrls('type', USER_TYPE.TYPE_PRIMARY)
      }
      else {
        rejectRequest();
        updateUrls('type', USER_TYPE.TYPE_REQUEST)
      }
      setReqAcceptedRejected(true);
    }).catch(emptyFunction)
  }

  const fetchMoreData = () => {
    if (nextURL) {
      makeGetCall(nextURL).then((res) => {
        setNextURL(res.next);
        setMessages((prevState) => [
          ...prevState,
          ...res.results
        ]);
      });
    }
  }
  
  const requestButton = (buttonText: string, type: string, image: string) => {
    return (
      <div className="d-flex align-items-center gap-2" onClick={() => acceptRejectRequest(type)}>
        <img src={image} alt="" />
        {buttonText}
      </div>
    )
  }

  const getImg = (item: any) => {
    if (item?.sent_by_id !== currentChat?.chat?.other_user?.id) {
      if (currentUser?.user_type === ROLE_TYPE.TYPE_CREW && currentUser?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) {
        return currentUser?.profile_image
      } else {
        return currentUser?.profile_image
      }
    } else {
      if (currentChat?.chat?.other_user?.user_type === ROLE_TYPE.TYPE_SUPPLIER) {
        return currentChat?.chat?.other_user?.profile_image
      } else if (currentChat?.chat?.other_user?.user_type === ROLE_TYPE.TYPE_CREW && currentUser?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) {
        return currentChat?.chat?.other_user?.profile_image
      } else if (currentChat?.chat?.other_user?.user_type === ROLE_TYPE.TYPE_CREW) {
        return currentChat?.chat?.other_user?.profile_image
      }
    }
    return null
  }
  console.log("current Chat", currentChat?.chat)
  const deleteMessage = (id: string) => {
    const param = {
      message_id: id
    }
    makeDeleteCall(endPoints.DELETE_MESSAGE, param)
      .then((res) => {
        if (res?.status?.code === 200) {
          const paramsMessage = {
            room_id: currentChat?.chat?.id,
          }
          getMessage(paramsMessage);
          showToast(res?.status?.message, "success")
        }
      })
      .catch((err) => {

      })
  }

  return (
    <div className='message-right-side'>
      <div className='message-rigth-side-top'>
        <div className='message-heading text-center'>
          <div className='message-top-partsetup'>
            <div className='back-btn-top' onClick={() => { setIsShowMessage(false); onClose() }}>
              <img src="/assets/images/black-arrow-left-side.svg" alt="" />
            </div>
            <div className={`input-group ${fullSearch ? "mw-100 setuptop" : ""}`}>
              <span className={`input-group-text bg-transparent`} onClick={() => setFullSearch(true)}>
                <img className='mb-0' src="/assets/images/search-thin-blank.svg" alt="" />
              </span>
              <input type="text" className="form-control border-start-0 border-end-0 py-2 px-0 shadow-none" value={searchParam} placeholder='Search in Media Access Solutions ' onChange={(e) => setSearchParam(e.target.value)} />
              <span className="input-group-text bg-transparent">
                <img className='mb-0' src="/assets/images/cross-thin-icon-black.svg" alt="" onClick={() => { setFullSearch(false); setSearchParam(''); }} />
              </span>
            </div>
          </div>
          {currentChat?.chat?.other_user?.supplier_profile?.company_logo || currentChat?.chat?.other_user?.owner_profile?.profile_image || currentChat?.chat?.other_user?.profile_image ? 
          <img src={currentChat?.chat?.other_user?.supplier_profile?.company_logo || currentChat?.chat?.other_user?.owner_profile?.profile_image || currentChat?.chat?.other_user?.profile_image}
            onClick={() => { setFullView(true); setShowDownload(false); setEnlargeImage(currentChat?.chat?.other_user?.supplier_profile?.company_logo || currentChat?.chat?.other_user?.owner_profile?.profile_image || currentChat?.chat?.other_user?.profile_image); }}
            alt="" className="cursor-pointer" />
            : 
            <Logo style={{ width: '50px', height: '50px' }} data={currentChat?.chat?.other_user?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? currentChat?.chat?.other_user?.supplier_profile?.company_name?.[0] : currentChat?.chat?.other_user?.first_name?.[0] || currentChat?.chat?.other_user?.last_name?.[0]}/>}
          <h3 onClick={()=>setViewProfile(true)} className="cursor-pointer">{currentChat?.chat?.other_user?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? currentChat?.chat?.other_user?.supplier_profile?.company_name : currentChat?.chat?.other_user?.first_name + " " + currentChat?.chat?.other_user?.last_name}</h3>

          {/* {!currentChat?.acceptedInvite && */}
          {(parsed.type === USER_TYPE.TYPE_REQUEST && !reqAcceptedRejected) &&
            <div className="accept-reject-des mt-3 pb-4 mb-2 d-flex align-items-center justify-content-center gap-3">
              {requestButton('Reject', 'R', "/assets/images/cross-cercle-fill-red.svg")}
              {requestButton('Accept', 'A', "/assets/images/checkmark-fill-green.svg")}
            </div>
          }
        </div>
      </div>
      <div className='message-right-side-chat-start'>
        <div className='message-setup d-grid gap-5'>
          <div className={`all-chents-inner gap-5 scrollAsd ${fullSearch ? "height-resetups" : ""}`}
            id="scrollableDiv2" ref={bottomRef}>
            <InfiniteScroll
              dataLength={messages.length}
              next={fetchMoreData}
              style={{ display: 'flex', flexDirection: 'column-reverse', overflowAnchor: 'none', contentVisibility: "auto" }} //To put endMessage and loader to the top.
              inverse={true}
              hasMore={nextURL ? true : false}
              loader={null}
              scrollableTarget="scrollableDiv2"
            >
              {/* <h1>{messages.length}</h1> */}
              {messages?.map((message, idx: any) => {
                return (
                  <>
                    {DateUtils.getDateWithTime(message?.created_at, 'll') !== DateUtils.getDateWithTime(messages[idx - 1]?.created_at, 'll') && idx !== 0 ? <p className='date-time-top text-center'>{DateUtils.getDateWithTime(messages[idx - 1]?.created_at, 'll')}</p> : ''}
                    <div className={`left-side-message d-flex position-relative gap-3 ${message?.sent_by_id !== currentChat?.chat?.other_user?.id ? "resive-other-message ms-auto" : "me-auto"}`} ref={scrollRef} key={idx}>

                      {message?.sent_by_id !== currentChat?.chat?.other_user?.id ?
                        <div className="mt-3 pt-4">
                          <div className='filter-select message-delete'>
                            <img src={"/assets/images/three-dots-more.svg"} id={idx} className="main-drop-donwe" alt="" onClick={() => setDelButton((prevState) => !prevState)} />
                            {showDelButton ? <div className={`drop-down-dtost pt-3 pb-3`}>
                              <ul className="d-grid list-unstyled pl-0 mb-0">
                                <li onClick={() => deleteMessage(message?.id)}><img src={"/assets/images/Delete-icon-red.svg"} alt="" /> Delete</li>
                              </ul>
                            </div> : ''}
                          </div>
                        </div> : ""}
                      {/* sent delivered read need to be added ahead for status in above class */}
                      <div className="w-100">
                        <div className={`profile-name-time d-flex justify-content-between align-items-center ${message?.sent_by_id !== currentChat?.chat?.other_user?.id ? "flex-row-reverse" : ""}`}>
                          <div className='message-name-logo  d-flex gap-3 align-items-center'>
                            {getImg(message) ? <img src={getImg(message)} alt="user" />
                            : <Logo style={{ width: '30px', height: '30px' }} data={message?.sent_by_id !== currentChat?.chat?.other_user?.id ?  
                              currentUser?.first_name?.[0] || currentUser?.last_name?.[0] :
                              currentChat?.chat?.other_user?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? currentChat?.chat?.other_user?.supplier_profile?.company_name?.[0] : currentChat?.chat?.other_user?.first_name?.[0] || currentChat?.chat?.other_user?.last_name?.[0]}/> }
                            <p className='mb-0'>{message?.sent_by_id !== currentChat?.chat?.other_user?.id ? "You" : currentChat?.chat?.other_user?.first_name + " " + currentChat?.chat?.other_user?.last_name}</p>
                          </div>
                          <p className='mb-0 date-tme'>{moment(message?.created_at).format('LLL')}</p>
                        </div>
                        <div className={`left-side-message-text ${message?.attachment_url ? "" : ""} ${message.message && message?.sent_by_id !== currentChat?.chat?.other_user?.id ? "ms-0" : ""} `}>
                          <p>
                            {message?.message?.length > 0 &&
                              <p className={message?.sent_by_id !== currentChat?.chat?.other_user?.id ? "" : "text-white"} dangerouslySetInnerHTML={{ __html: message?.message.replace(/\n/g, "<br/>") || '' }}></p>
                                //{message.message}</p>
                            }
                            {message?.attachment_url && message?.attachment_type === 'image' && (
                              <img src={message.attachment_url} alt="sended" id={idx} onClick={() => { setFullView(true); setEnlargeImage(message.attachment_url); }} />

                            )}
                            {message?.attachment_url && message?.attachment_type === 'video' &&
                              (<><video width="100%" height="240" controls={true} controlsList="download">
                                <source src={message.attachment_url} ></source>
                                Your browser does not support the video tag.
                              </video>
                                <p className={message?.sent_by_id !== currentChat?.chat?.other_user?.id ? "text-start fw-bold" : "fw-bold"}><a href={message.attachment_url} download={message.attachment_url} >{message.attachment_url.substring(message.attachment_url.lastIndexOf('/') + 1)} <img src={message?.sent_by_id !== currentChat?.chat?.other_user?.id ? "/assets/images/arrow-download-black.svg" : "/assets/images/arrow-download-white.svg"} className="donwnload-arrow" alt="" /></a></p>
                              </>)}

                            {message?.attachment_url && (message?.attachment_type === 'application' || message?.attachment_type === 'pdf') &&
                              (<div className='left-side-message-text file-send ms-0'>
                                <div className='file-send-div d-flex justify-content-between align-items-center gap-3'>
                                  <div style={{ cursor: 'pointer' }} onClick={() => setSelectedPdf(message.attachment_url)} className='file-name d-flex align-items-center gap-3 text-dark fw-bold'>
                                    <img src="/assets/images/document-black.svg" width='16px' alt="" />
                                    {message.attachment_url.substring(message.attachment_url.lastIndexOf('/') + 1)}
                                  </div>
                                  <a href={message.attachment_url} download={message.attachment_url} > <img src="/assets/images/arrow-download-black.svg" width='20px' alt="" /></a>
                                </div>
                              </div>)}
                          </p>
                        </div>
                        {message?.sent_by_id !== currentChat?.chat?.other_user?.id ?
                          <div>{message?.status === MESSAGE_STATUS.TYPE_READ ? MESSAGE_TEXT.TYPE_READ : message?.status === MESSAGE_STATUS.TYPE_SENT ? MESSAGE_TEXT.TYPE_SENT : MESSAGE_TEXT.TYPE_DELIEVRED}</div>
                          : ''}
                      </div>
                    </div>
                  </>
                )
              })}
            </InfiniteScroll>
          </div>
          <div className='all-text-write-here'>
            {parsed.type !== USER_TYPE.TYPE_REQUEST && currentChat?.chat?.status !== REQ_STATUS.PENDING && <ChatInput handleSendMessage={handleSendMessage} />}
            {(currentChat?.chat?.status === REQ_STATUS.PENDING && currentUser?.user_type === ROLE_TYPE.TYPE_CREW ) && <div className="h-100 d-flex align-items-center justify-content-center text-danger"><img src="/assets/images/error-icon.svg"/>Message request is pending</div> }
            <FullViewImage
              show={fullView}
              closePopup={() => {
                setFullView(false);
                setShowDownload(true);
              }}
              enlargeImage={enlargeImage}
              showDownload={showDownload}
            />
            {selectedPdf &&
              <ViewPdf
                onClose={() => setSelectedPdf(null)}
                file={selectedPdf}
              />
            }
            {currentChat?.chat?.other_user?.user_type === USER_TYPE.TYPE_CREW ?
              <ViewCrewDetails
                visible={viewProfile}
                onClose={() => setViewProfile(false)}
                crewDetails={profileDetails}
                onAction={(type) => {
                  if (type == USER_PRESS_ACTION_TYPE.TYPE_FLAG) {
                    alert("To be implement")
                  }
                  setActionType(type)
                }}
                type={parsed.type}
                hideBtn={userData?.user_type === USER_TYPE.TYPE_SUPPLIER ? true : false}
              />
              :
              <ViewSupplierDetails
                visible={viewProfile}
                onClose={() => setViewProfile(false)}
                supplierDetails={profileDetails}
                onFlag={() => alert('To be implemented')}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};


export default ChatContainer;
