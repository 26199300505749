import { Component } from "react";
import ReactCrop from "react-image-crop";
import { blobToFile, dataURLtoFile } from "../../utils/UtilityFunctions";
import { Button, Modal, ModalTitle } from "react-bootstrap";
import 'react-image-crop/dist/ReactCrop.css'
import heic2any from "heic2any";

const imgAcceptType = [
  "jpg",
  "jpeg",
  "png",
  "JPG",
  "JPEG",
  "PNG",
  "gif",
  "GIF",
  "heic"
];

class ImageCrop extends Component<Readonly<any>, any> {
  imageRef = null;
  fileUrl = null;
  constructor(props: Readonly<any>) {
    super(props);
    this.state = {
      crop: {
        unit: 'px',
        width: 220,
        height: 220,
        aspect: this.props.ratio || 1 / 1,
        // unit: '%',
        // width: 70,
        // height: 70,
        x: 15,
        y: 15,
      },
      url: null,
      isCropperShow: false,
      file: null,
      boundaries: null,
      croppedImageUrl: null,

    };
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onCropChange = (crop: any, percentCrop: any) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  onCropComplete = (crop: any) => {
    this.makeClientCrop(crop);
  };

  makeClientCrop = async (crop: any) => {
    if (this?.imageRef && crop?.width && crop?.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this?.imageRef,
        crop,
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image: any, crop: any) {
    const canvas = document.createElement('canvas');
    const scaleX = image?.naturalWidth / image?.width;
    const scaleY = image?.naturalHeight / image?.height;
    canvas.width = crop?.width;
    canvas.height = crop?.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop?.width,
      crop?.height
    );
    // As Base64 string
    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image
  }

  getCropImg = async () => {
    const { croppedImageUrl } = this.state;
    if (croppedImageUrl) {
      let newFile = dataURLtoFile(croppedImageUrl, Date.now() + '.jpeg');
      this.props.croppedImage(croppedImageUrl, newFile);
    }

    this.setState({ isCropperShow: false, url: null }, () => {
      this.setState({
        crop: {
          unit: '%',
          width: 70,
          height: 70,
          x: 15,
          y: 15
        },
      })
    });
  };

  onSelectFile = (evt: any) => {
    const file = evt?.target?.files[0],
      type = file?.type.split("/");
    if (evt?.target?.value)
      evt.target.value = "";
    if (imgAcceptType.indexOf(type[1]) !== -1) {
      if (((file.size / 1000) / 1000).toFixed(4) <= this.props.size) {
        const reader = new FileReader();
        if (type[1] == 'heic') {
          heic2any({ blob: file, toType: "image/jpg", quality: 1 }).then(
            (av: any) => {
              let file = blobToFile(av, Date.now() + '.png')
              reader.addEventListener("load", () =>
                this.setState({ url: reader.result, isCropperShow: true, file: file }),
              );
              reader.readAsDataURL(file);
            });
        }
        else {
          reader.addEventListener("load", () =>
            this.setState({ url: reader.result, isCropperShow: true, file: file }),
          );
          reader.readAsDataURL(file);
        }
      } else {
        //console.log("File", `File size is more than ${this.props.size} mb not supported`);
        this.setState({ url: null, isCropperShow: false, file: null });
        document.getElementById("image_id")!.innerHTML = "";
      }
    } else {
      this.setState({ url: null, isCropperShow: false, file: null });
      document.getElementById("image_id")!.innerHTML = "";
    }
  };

  render() {
    const { crop, url } = this.state;
    return (
      <div>
        <input
          id="image_id"
          accept="image/png, image/jpeg, image/heic"
          style={{ display: "none" }}
          type="file"
          onChange={this.onSelectFile}
          className={this.props?.inputClass}
        />
        {this.state?.url && (
          <Modal
            className="crop-image123"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            onHide={() => this.setState({
              url: null,
              isCropperShow: false,
              file: null
            }, () => {
              document.getElementById("image_id")!.innerHTML = "";
            })}
          >
            <Modal.Header className="p-0 border-0 justify-content-between pb-2 mb-0">
              <ModalTitle className="fw-bold text-center">Crop Image</ModalTitle>
            </Modal.Header>
            <Modal.Body className="p-0">
              <div className="croper_outer">
                <ReactCrop
                  src={url}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                  keepSelection={true}
                // circularCrop
                />
                <Button variant="primary" className="btn-warning w-100" onClick={this.getCropImg}>
                  Crop
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}
export default ImageCrop;
