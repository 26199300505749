

import React, { useState, useEffect } from "react";
import { cookieService } from "../../services/cookie.service";
import { cookieServiceParam } from "../../utils/constants";
import { setToken } from "./FCM";

const Notifications: React.FC<any> = () => {
  const [isTokenFound, setTokenFound] = useState(false);
  useEffect(() => {
    setToken(setTokenFound)
    .then((res)=>
    {
      cookieService.saveCookie(cookieServiceParam.browserToken , res);
    }
    );
  }, [isTokenFound]);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;