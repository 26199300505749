import React from 'react'
interface AddUserTypeProps {
  onClick: () => void;
  buttonText: any;
  type: any;
}
export const AddUserType = (props:AddUserTypeProps) => {
  return (
    <div className='add-user-desing dotted-border text-center' onClick={props?.onClick}>
            <img src="/assets/images/user-icon-fill-dark.svg" alt="" />
                <h3>{props?.buttonText}</h3>
            <p className='mb-0'>Best for adding {props?.type} users</p>
        </div>
  )
}
