import { outerContent, buttonsText } from '../../utils/constants';

interface Props {
  userData?: any
}

interface ItemsProps {
  label: any;
  value: any;
  onUpdate?: () => void
}

const Items = (itemProps: ItemsProps) => {
  return (
    <div className='rate-section mb-4 text-center text-sm-start'>
      <h3>{itemProps.label}</h3>
      <div className='d-grid d-sm-flex align-items-center justify-content-center justify-content-sm-between gap-3'>
        <p className='perple-button mb-0'>{`${itemProps.value} times daily rate`}</p>
        {/* <button type='button' onClick={itemProps?.onUpdate} className='danger-button'><img src={"/assets/images/edit-pencil-edit.svg"} alt="" />{buttonsText.update}</button> */}
      </div>
    </div>
  )
}

const Overtime = (props: Props) => {

  return (
    <div className="tab-pane active" id="overtime_rates">
      <div className='rate-design'>
        <Items
          label={outerContent.Day6thRate}
          value={props?.userData?.owner_profile?.sixth_day_earning_rates || ''}
        />
        <Items
          label={outerContent.Day7thRate}
          value={props?.userData?.owner_profile?.seventh_day_earning_rates || ''}
        />
      </div>
    </div>
  )
}
export default Overtime;