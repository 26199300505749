import Button from "../../../global/ui/Button";
import DropDown from "../../../global/ui/dropDown";
import { BUTTON_TYPE, USER_ACTION_TYPE } from "../../../types";
import {
  buttonsText,
  errorMessage,
  labels,
  placeholder,
} from "../../../utils/constants";
import { useForm, FormProvider } from "react-hook-form";
import InputField from '../../../lib/input';
import { apiConstants } from "../../../utils/apiConstants";
import { EMAIL_VALIDATION, NAME_VALIDATION, REQUIRED_VALIDATION } from "../../../lib/input/validation-rules";
import { supplierFormData } from "../../../data/formData";
import { preConfigDeptData } from "../../../data/data";
import { useEffect, useRef, useState } from "react";
import { showToast } from "../../../utils";
import UserSnap from "../../../global/ui/UserSnap";
import VerifyPassword from "../../../components/modal/VerifyPassword";
import VerifyUpdatedEmail from "../../../components/modal/VerifyUpdatedEmail";

interface basicInfo {
  userData: any;
  onSubmit: (value: any) => void;
  actionType?: any;
  editedSuccessfully?: boolean;
  setEditedSuccessfully: () => void;
}
const BasicInfo = (props: basicInfo) => {

  const { ...methods } = useForm({
    mode: 'all',
    defaultValues: { ...supplierFormData(props?.userData) },
    reValidateMode: 'onBlur',
  });

  const [selectedDept, setSelectedDept] = useState([]);
  const [password, setPassword] = useState('');
  const [showPwdPopUp, setShowPwdPopUp] = useState(false);
  const [emailVerifyPopup, setEmailVerifyPopup] = useState(false);
  const [fieldData, setFieldData] = useState({})
  const [updatedField, setUpdatedField] = useState(false)
  const inputRef = useRef<HTMLTextAreaElement>(null); 

  const verifyPwd = (data: any) => {
    const pwdData = { ...fieldData, 'password': data.password }
    onSubmit(pwdData)
    setShowPwdPopUp(false);
    setFieldData({});
    setPassword('');
  }
  useEffect(() => {
    if (props.editedSuccessfully && updatedField) {
      setEmailVerifyPopup(true);
    }
  }, [props.editedSuccessfully])
  useEffect(() => {
    methods.setValue(apiConstants.company_logo, props.userData?.supplier_profile?.company_logo, { shouldValidate: true })
  }, [props?.userData]);
  const onSubmit = (values: any) => {
    props.setEditedSuccessfully();
    const apiPayload = { ...values };
    if (selectedDept?.length <= 0) {
      showToast(errorMessage.dept_error, 'error', 'dep');
      return
    }
    const tempSelDepartment = selectedDept.map((item: any) => item.label);
    apiPayload[apiConstants.departments] = tempSelDepartment;
    if (methods.getValues(apiConstants.company_logo) == props.userData?.supplier_profile?.company_logo) {
      delete apiPayload[apiConstants.company_logo]
    }
    if (apiPayload[apiConstants.email] == props?.userData?.[apiConstants.email]) {
      delete apiPayload[apiConstants.email]
    }
    else if (props?.actionType === 'update' && Object.keys(fieldData).length === 0) {
      setFieldData(apiPayload as never);
      setShowPwdPopUp(true);
      setUpdatedField(true);
      return;
    }
    props.onSubmit(apiPayload)
  }

  useEffect(() => {
    if (props?.userData?.supplier_profile?.departments?.length > 0) {
      const tempDept = preConfigDeptData.filter(item => props?.userData?.supplier_profile?.departments?.includes(item.label))
      setSelectedDept(tempDept as never);
    }
  }, [JSON.stringify(props?.userData)])

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const cursorPosition = e.target.selectionStart;
    const previousScrollPosition = inputRef.current?.scrollTop;
    methods.setValue(apiConstants.bio, e.target.value, { shouldValidate: true });
  
    requestAnimationFrame(() => {
      e.target.selectionStart = cursorPosition;
      e.target.selectionEnd = cursorPosition;
      if (inputRef.current && previousScrollPosition !== undefined) {
        inputRef.current.scrollTop = previousScrollPosition;
      }
    });
  };
  return (
    <FormProvider {...methods} >
      <form action="" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <div className="all-form mt-0">
          <div className="text-center">
            <UserSnap
              onChange={(value) => methods.setValue(apiConstants.company_logo, value, { shouldValidate: true })}
              value={methods.getValues(apiConstants.company_logo)}
              label={labels.addLogo}
            />
          </div>

          <div className='col-md-12 mt-4 pt-4'>
            {props.actionType === 'update' ?
              <InputField
                label={labels.email}
                placeholder={placeholder.email}
                name={apiConstants.email}
                type='text'
                rules={EMAIL_VALIDATION}
                className='black-input'
              />
              : ""}
            <InputField
              label={labels.supplierName}
              placeholder={placeholder.supplierName}
              name={apiConstants.company_name}
              type='text'
              rules={NAME_VALIDATION(labels.supplierName)}
              containerStyle={{ marginTop: '25px', }}
              className='black-input mb-4'
            />
          </div>
          <DropDown
            className="arrow-fill"
            isMultiSelect={true}
            isClearable={false}
            data={preConfigDeptData}
            onSelect={(el) => setSelectedDept(el)}
            selectedItems={selectedDept}
            placeholder={placeholder.departmentSelect}
            label={labels.departmentServing}
          />
          <div className="form_group mb-4 black-input mt-4">
            <div className="input_box">
              <label htmlFor="supplier_bio">{labels.supplierBio}</label>
              <textarea
                name="supplier_bio"
                id="supplier_bio"
                placeholder={placeholder.shortBio}
                value={methods.getValues(apiConstants.bio)}
                className="no-resize"
                onChange={(evt)=>handleInputChange(evt)}
                ref={inputRef} />

            </div>
          </div>
        </div>
        <div className="main-button">
          <Button
            type={BUTTON_TYPE.TYPE_SUBMIT}
            btnTxt={props?.actionType === USER_ACTION_TYPE.TYPE_UPDATE ? buttonsText.saveAndUpdate : buttonsText.continue}
            className={"btn btn-warning w-100 with-after-shadow shadow-none"}
          />
        </div>
        <VerifyPassword pwd={password} show={showPwdPopUp} closePopup={() => { setShowPwdPopUp(false); props.setEditedSuccessfully() }} onSubmit={verifyPwd} />
        <VerifyUpdatedEmail show={emailVerifyPopup} closePopup={() => { setEmailVerifyPopup(false); props.setEditedSuccessfully() }} />
      </form>
    </FormProvider>
  );
};
export default BasicInfo;
