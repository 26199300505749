import { BUTTON_TYPE } from "../../types";
import Modal from 'react-bootstrap/Modal';
import Button from "../../global/ui/Button";
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ModalTitle from 'react-bootstrap/ModalTitle'
import { buttonsText, labels, signinText } from "../../utils/constants";

const Logout = (props: any) => {

  return (
    <Modal
      centered
      scrollable
      show={props.show}
      onHide={props.closePopup}>
      <ModalHeader className="p-0 border-0 text-center mb-3">
        <ModalTitle className="fw-bold mx-auto">{labels.logout}</ModalTitle>
        <button className="close-btn-de shadow-none" onClick={props.closePopup}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
      </ModalHeader>
      <ModalBody className="p-0 text-center mb-5 pb-3">
        <p className="mb-0">{signinText.logoutPopupText}</p>
      </ModalBody>
      <ModalFooter className="p-0 border-0">
        <Button
          type={BUTTON_TYPE.TYPE_BUTTON}
          btnTxt={buttonsText.logOut}
          style={{ marginTop: "0px", width: "auto" }}
          onClick={props.onLogOut}
          className={'btn btn-warning w-100 with-after-shadow shadow-none'}
        />
      </ModalFooter>
    </Modal>
  )
}
export default Logout; 