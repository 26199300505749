export const apiConstants = {
  version: 1,
  status: "status",
  code: "code",
  pin: "pin",
  email: "email",
  password: "password",
  old_password: "old_password",
  confirm_password: "confirm_password",
  data: "data",
  name: "name",
  full_name: "full_name",
  first_name: "first_name",
  last_name: "last_name",
  middle_name: "middle_name",
  mobile_number: "mobile_number",
  phone_number: "phone_number",
  company_name: "company_name",
  contact_name: "contact_name",
  address: "address",
  city: "city",
  post_code: "postcode",
  rate6: "rate6",
  rate7: "rate7",
  supplier_name: "supplierName",
  supplier_contact_name: "supplierContactName",
  service: "service",
  product: "product",
  title: "title",
  daily_rate: "daily_rate",
  location: "location",
  number_position: "numberOfPositions",
  number_of_positions: 'number_of_positions',
  description: 'description',
  start_date: 'start_date',
  end_date: 'end_date',
  image: 'image',
  department: 'department',
  departments: 'departments',
  company: 'company',
  website: 'website',
  user_type: 'user_type',
  user: 'user',
  permission_type: 'permission_type',
  view_type: 'view_type',
  mast_format: '+99 9999 999 999',
  company_logo: 'company_logo',
  contact_person: 'contact_person',
  country: 'country',
  postcode: 'postcode',
  account_completed: 'account_completed',
  seventh_day_earning_rates: 'seventh_day_earning_rates',
  sixth_day_earning_rates: 'sixth_day_earning_rates',
  isd_code: 'isd_code',
  bio: 'bio',
  profile_image: 'profile_image',
  search: "search",
  role: 'role',
  roles: 'roles',
  id: 'id',
  eventName: 'name',
  eventSubCategory: 'sub_category',
  event_start_date: 'start_datetime',
  event_end_date: 'end_datetime',
  meeting_link: 'meeting_link',
  event_location: 'location',
  event_address: 'address',
  event_type: 'type',
  eventStartTime: 'start_time',
  eventEndTime: 'end_time',
  user_ids: 'user_ids',
  message: 'message',
  evt_location: 'location',
  evt_timezone: 'time_zone'
};