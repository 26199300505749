import { ReactNode } from "react";
import { USER_ACTION_TYPE } from "../../types";

interface CreateProfileLayoutProps {
  onBack: () => void;
  actionType: any;
  children: ReactNode
}

const CreateProfileLayout = (props: CreateProfileLayoutProps) => {
  const { children } = props;
  return (
    <div>
      <div className='create-profile-layout'>
        <div className="card bg-white z_index_99">
          <div className="card-body">
            {children}
          </div>
        </div>
      </div>
      {props.actionType === USER_ACTION_TYPE.TYPE_UPDATE &&
        <div onClick={props.onBack} className="backdrop_close" />
      }
    </div>
  )
}
export default CreateProfileLayout; 