
interface Props {
  userData?: any
}

interface ItemsProps {
  data: Array<any>
  label: any
}

const Items = (itemProps: ItemsProps) => {
  return (
    <div className='service-work mb-3'>
      <h3>{itemProps?.label}</h3>
      <div className='like-togles d-flex flex-wrap align-items-center'>
        {itemProps.data.map((item: string, idx: any) => <span key={idx}>{item}</span>)}
      </div>
    </div>
  )
}

const Services = ({ userData }: Props) => {

  const services = userData?.supplier_profile?.services?.length > 0 ? userData?.supplier_profile?.services.split(',') : []
  const products = userData?.supplier_profile?.products?.length > 0 ? userData?.supplier_profile?.products.split(',') : []

  const extensions = [".jpg", ".jpeg", ".png", ".JPG", ".JPEG", ".PNG"];
  return (
    <div className="tab-pane active" id="overtime_rates">
      <div className='service-section-design'>
        {services?.length > 0 &&
          <Items
            data={services}
            label='Your Services:'
          />
        }
        {products?.length > 0 &&
          <Items
            data={products}
            label='Your Products:'
          />
        }
        {userData?.supplier_media?.length > 0 &&
          <div className='service-work mb-3'>
            <h3>Gallery:</h3>
            <div className='gallery-section d-flex gap-3 overflow-auto pb-3'>
              {userData?.supplier_media?.map((item: any) =>
                <>
                  {extensions.some(extension => item?.image?.includes(extension)) ? <img src={item?.image} alt="" /> :
                    <video width="25%"
                      height="75%"
                      src={item?.image}
                      className="box-video"
                    />
                  }</>
              )}
            </div>
          </div>}
      </div>
    </div>
  )
}
export default Services;