import InfiniteScroll from "react-infinite-scroll-component";
import { createFullName, hasAddressData, renderAddressDetail } from "../../utils";
import Logo from "../../components/commonComponent/ProfileImage";


interface RelevantCrewProps {
    data: Array<any>;
    selectedCrew?: Array<any>;
    onChange?: (value: any) => void
    hideCheckbox?: boolean,
    nextUrlList: string,
    fetchMoreDetails: () => void
}

const RelevantCrew = (props: RelevantCrewProps) => {
    return (
        <div className="jobs-selct-popup">
            {props.data?.length > 0 ?
                <div className='job-selct-scroll-part' id="scrollableDivList">
                    <InfiniteScroll
                        dataLength={props?.data?.length}
                        next={props?.fetchMoreDetails}
                        hasMore={props?.nextUrlList ? true : false}
                        loader={null}
                        scrollableTarget="scrollableDivList"
                    >
                        {props.data?.map((item: any, idx) => {
                            return (
                                <div key={idx} className='jobs-selct-popup-list d-flex py-3 px-3 align-items-center gap-3 mt-3'>
                                    {item?.profile_image ? <img src={item?.profile_image} className={`profile-img rounded-circle ${item?.profile_image ? '' : 'without-logo'}`} alt="" />
                                        : <Logo style={{ width: '54px', height: '54px', padding: '20px' }} data={item?.first_name?.[0]} />}
                                    <div className='jobs-selct-popup-list-img'>
                                        <h3 style={{ textTransform: 'capitalize' }}>{createFullName(item?.first_name, item?.last_name)}</h3>
                                        {hasAddressData(item) &&
                                            <ul className='list-unstyled pl-0 d-flex flex-wrap mb-0'>
                                                <li><img src={"/assets/images/Location-icon-perple.svg"} alt="" />{renderAddressDetail(item)}</li>
                                            </ul>
                                        }
                                    </div>
                                    {!props.hideCheckbox &&
                                        <div className='poupcheck'>
                                            <div className="input-check-box">
                                                <input
                                                    onChange={() => props?.onChange?.(item)}
                                                    checked={props.selectedCrew?.includes(item?.id as never)}
                                                    type="checkbox"
                                                    name=""
                                                    id={item?.id}
                                                    className="d-none"
                                                />
                                                <label htmlFor={item?.id}></label>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </InfiniteScroll>
                </div>
                :
                <div className="no-relevant-crew">
                    <p>No results to show</p>
                </div>
            }
        </div>
    )
}

export default RelevantCrew