import React from 'react'
import Logo from '../../components/commonComponent/ProfileImage';

interface ReferencesProps {
    data: Array<any>;
    onClick?: (value: any) => void;
    selectedIdx: number;
    changeIndex: any
}

const References: React.FC<ReferencesProps> = (props) => {

    return (
        <div className={`tab-pane ${props?.selectedIdx === 2 && 'active show'}`} id="references">
            <div className="references-tabs">
                {props.data?.length > 0
                    ?
                    <>
                        {props.data?.map((item, idx) => {
                            return (
                                <div key={idx} className="references-tabs-card d-grid d-sm-flex gap-2 align-items-center justify-content-start justify-content-sm-between  mb-4">
                                    <div className="left-side-references d-flex align-items-center justify-content-between">
                                        {item?.reference_image ? <img src={item?.reference_image} className="rounded-circle" alt="" />
                                            :
                                            <Logo style={{ width: '66px', height: '66px' }} data={item?.reference_name?.[0]} />}
                                        <div style={{ marginTop: '8px' }} className="contne-tab">
                                            <h6>{item?.reference_name || ''}</h6>
                                            {/* <p className="mb-0 perple-button">Exec Producer</p> */}
                                        </div>
                                    </div>
                                    {item?.profile_created &&
                                        <button
                                            type='button'
                                            className="btn btn-light-with-icon shadow-none"
                                            onClick={() => {
                                                props?.onClick?.(item);
                                                props?.changeIndex(idx)
                                            }}
                                        >
                                            View Profile
                                            <img src={"/assets/images/link-icon.svg"} alt="" />
                                        </button>
                                    }
                                </div>
                            )
                        })}
                    </>
                    :
                    <div className="no-relevant-crew">
                        <p>No results to show</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default References