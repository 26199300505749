import { countryList } from '../../../data/data';
import Button from '../../../global/ui/Button';
import DropDown from '../../../global/ui/dropDown';
import { BUTTON_TYPE, USER_ACTION_TYPE } from '../../../types';
import { buttonsText, errorMessage, labels, placeholder } from '../../../utils/constants';
import { useForm, FormProvider } from "react-hook-form";
import { POSTCODE_VALIDATION, REQUIRED_VALIDATION } from '../../../lib/input/validation-rules';
import { apiConstants } from '../../../utils/apiConstants';
import { ownerAddressFormData } from '../../../data/formData';
import InputField from '../../../lib/input';
import { useEffect, useState } from 'react';
import { generateFormData, getSelectedCountry, showToast } from '../../../utils';

interface AddressProps {
  userData: any;
  onSubmit: (value: any) => void;
  actionType?: any
}

const Address = (props: AddressProps) => {

  const { ...methods } = useForm({
    mode: 'all',
    defaultValues: { ...ownerAddressFormData(props.userData) },
    reValidateMode: 'onBlur',
  });

  const [selecetedCountry, setSelecetedCountry] = useState<any>(null);

  useEffect(() => {
    if (props.userData?.country) {
      setSelecetedCountry(getSelectedCountry(props.userData?.country))
    }
  }, [props.userData?.country])

  const onSubmit = (values: any) => {
    if (!selecetedCountry?.code) {
      showToast(errorMessage.country_error, 'error', 'country');
      return
    }
    const apiPayload = { ...values };
    apiPayload[apiConstants.country] = selecetedCountry?.label || "";
    const fd = generateFormData(apiPayload);
    props.onSubmit(fd)
  }

  return (
    <FormProvider {...methods} >
      <form action="" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <div className="all-form" style={{ marginTop: '14px' }}>
          <div>
            <div className="black-input">
              <DropDown
                className="arrow-fill drop-down-over-vis"
                data={countryList}
                onSelect={(data) => {
                  methods.reset();
                  setSelecetedCountry(data);
                }}
                placeholder={placeholder.selectCountry}
                selectedItems={selecetedCountry}
                label={labels.country}
              />
            </div>
          </div>
          {selecetedCountry?.code &&
            <>
              <div className="row">
                <div className='col-md-6'>
                  <InputField
                    label={labels.city}
                    placeholder={placeholder.city}
                    name={apiConstants.city}
                    type='text'
                    rules={REQUIRED_VALIDATION(labels.city)}
                    className='black-input cross-bg-white-setup'
                  />
                </div>
                <div className='col-md-6'>
                  <InputField
                    label={labels.postCode}
                    placeholder={placeholder.postCode}
                    name={apiConstants.post_code}
                    type='text'
                    //rules={POST_VALIDATION(selecetedCountry?.code === "AU" ? /^[0-9]{4}$/ : selecetedCountry?.code === "US" ? /^[0-9]{5}$/ : /^[0-9a-zA-Z]{5,7}$/, selecetedCountry?.code === "AU" ? errorMessage.invalid_post_msg('4 numeric') : selecetedCountry?.code === "US" ? errorMessage.invalid_post_msg('5 numeric') : errorMessage.invalid_post_msg('5-7 alphanumeric'))}
                    rules={POSTCODE_VALIDATION()}
                    className='black-input'
                  />
                </div>
              </div>
              <InputField
                label={labels.address}
                placeholder={placeholder.companyAddress}
                name={apiConstants.address}
                type='text'
                containerStyle={{ marginTop: '25px', }}
                className='black-input'
                maxLength={100}
              />
            </>
          }
        </div>
        <div className="main-button">
          <Button
            type={BUTTON_TYPE.TYPE_SUBMIT}
            btnTxt={props?.actionType === USER_ACTION_TYPE.TYPE_UPDATE ? buttonsText.saveAndUpdate : buttonsText.continue}
            className={"btn btn-warning w-100 with-after-shadow shadow-none"}
          />
        </div>
      </form>
    </FormProvider>
  )
}
export default Address;