import React from 'react'
import { Modal } from 'react-bootstrap';

const FlagConfirm = (props:any) => {
  return (
    <Modal
    centered
    scrollable
    className=" addProductionCrew modal-body-scroll-part"
    show={props?.show}
    onHide={props?.onClose}
 > <div className="d-flex flex-column align-items-center justify-content-center">
<img src={"/assets/images/flag-orange.svg"} alt="" />
<h5 style={{ fontWeight: '600', paddingTop: '2rem' }}>Caution! You are about to block this person, this will:</h5>
<div style={{margin:'0 25px'}}>
<p style={{ textAlign: 'left', marginTop: '10px' }}>1. Hide your profile from their search.</p>
<p style={{ textAlign: 'left', marginTop: '5px' }}>2. Prevent them from messaging you directly. This can be reversed at any time by clicking the block button.</p>
</div>


<button className={'btn btn-warning w-100 mt-4 with-after-shadow shadow-none'} 
onClick={props?.handleAction}
> Continue</button>
<button className={'btn w-100 with-after-shadow shadow-none'} style={{ marginTop: '10px' }} onClick={props?.onClose}> Cancel</button>
</div>
</Modal>
  )
}

export default FlagConfirm
