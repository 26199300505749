import React, { useContext, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import { MyContext } from '../../context';
import { preConfigDeptData, rolesList } from '../../data/data';
import Button from '../../global/ui/Button';
import DropDown from '../../global/ui/dropDown';
import { BUTTON_TYPE, PERMISSION_TYPE, ROLE_TYPE } from '../../types';
import { getRolesAsPerPrmsn, showToast } from '../../utils';

interface Props {
    isShow: boolean;
    onClose: () => void;
    onInvite: (data: any) => void;
    title?: any
    hideDesc?: boolean
    btnTxt?: any
}

const InviteCrewMemToProductionCompany: React.FC<Props> = (props) => {

    const { userData } = useContext(MyContext)

    const [selectedDept, setSelectedDept] = useState<any>([]);
    const [selectedRole, setSelectedRole] = useState<any>(null);
    const [allRoles, setAllRoles] = useState(rolesList)
    const [allDept, setAllDept] = useState(preConfigDeptData)

    useEffect(() => {
        if (userData?.permission_type !== PERMISSION_TYPE.TYPE_PROD_OWNER) {
            if (userData?.crew_member_profile?.company_department?.length > 0) {
                userData?.crew_member_profile?.company_department?.map((item: any) => {
                    item.label = item?.dep;
                    item.value = item?.dep;
                  })
                setAllDept(userData?.crew_member_profile?.company_department)
            }
            const data = getRolesAsPerPrmsn(userData?.permission_type);
            setAllRoles(data)
        }
        else{
            setAllRoles([...rolesList])
        }
    }, [userData]);

    useEffect(() => {
        if (props?.isShow) {
            setSelectedDept([]);
            setSelectedRole(null);
        }
    }, [props?.isShow])

    const isSuperUser = (selectedRole?.value == PERMISSION_TYPE.TYPE_ADMIN || selectedRole?.value === PERMISSION_TYPE.TYPE_HOD) ? true : false;

    const onInvite = () => {
        if (!selectedRole?.value) {
            showToast('Please select permission', 'error');
            return
        }
        if (selectedDept?.length <= 0) {
            showToast('Please select department', 'error');
            return
        }
        const dept: any = [];
        selectedDept?.map((item: any) => {
            dept.push({ dep: item?.label, roles: item?.roles })
        })
        const payload: any = {
            permission_type: selectedRole?.value,
            company_department: JSON.stringify(dept)
        }
        props.onInvite(payload)
    }

    return (
        <Modal
            centered
            scrollable
            className='max-width-496 addProductionCrew'
            show={props.isShow}
            onHide={props.onClose}>
            <ModalHeader className="p-0 pb-3 border-0 mb-3 align-items-center">
                <button className="close-btn-de shadow-none" onClick={props.onClose}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
                <ModalTitle className="fw-bold me-auto ps-3 text-start">
                    <h4 className='h4 text-start mb-0 mt-0'>{props?.title || 'Add Production Crew'}</h4>
                    {props?.hideDesc && <p className="mb-0 text-start">{'Invite crew members to your production company'}</p>}
                </ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-start" style={{ minHeight: '400px' }}>
                <div className='row mx-0'>
                    <div className='col-md-6'>
                        <DropDown
                            className="arrow-fill drop-down-over-vis"
                            data={allRoles}
                            onSelect={(data) => {
                                setSelectedDept([]);
                                setSelectedRole(data)
                            }}
                            placeholder='Select Permission'
                            label='Permission Type'
                            selectedItems={selectedRole}
                        />
                    </div>
                    <div className='col-md-6'>
                        <DropDown
                            className="arrow-fill drop-down-over-vis"
                            data={allDept}
                            onSelect={(data: any) => {
                                if (isSuperUser) {
                                    setSelectedDept(data)
                                } else {
                                    const temp: any = [];
                                    temp.push(data)
                                    setSelectedDept(temp)
                                }
                            }}
                            placeholder='Select Department'
                            label='Department'
                            selectedItems={isSuperUser ? selectedDept : selectedDept?.[0]}
                            isMultiSelect={isSuperUser ? true : false}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="p-0 border-0" style={{ marginTop: '0px' }}>
                <Button
                    type={BUTTON_TYPE.TYPE_BUTTON}
                    btnTxt={props?.btnTxt || 'Invite'}
                    style={{ marginTop: "0px", width: "auto" }}
                    onClick={onInvite}
                    className={'btn btn-warning w-100 with-after-shadow shadow-none m-0'}
                />
            </ModalFooter>
        </Modal>
    )
}

export default InviteCrewMemToProductionCompany