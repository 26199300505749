import React from 'react'
import { DateUtils } from '../../utils/dateUtils';

interface CrewWorkProps {
    data: Array<any>;
    name: any;
    selectedIdx: number
}

const CrewWork: React.FC<CrewWorkProps> = (props) => {
    return (
        <div className={`tab-pane ${props?.selectedIdx === 1 && 'active show'}`} id="work">
            <div className="work-tab-desi">
                <h5>{`${props?.name}’s Past Jobs`}</h5>
                {props.data?.length > 0
                    ?
                    <>
                        {props.data?.map((item, idx) => {
                            return (
                                <div key={idx} className="card work-card">
                                    <div className="card-body">
                                        <h4>{item?.job?.title}</h4>
                                        <ul className="profile-information-list">
                                            <li className="align-items-center">
                                                <img src={"/assets/images/user-icon-perple.svg"} alt="" />
                                                {item?.job?.department}
                                            </li>
                                            <li className="align-items-center">
                                                <img src={"/assets/images/Calendar-empty-icon-perple.svg"} alt="" />
                                                {`${DateUtils.formatDateTime(item?.job?.start_date, 'MMMM YYYY')} - ${DateUtils.formatDateTime(item?.job?.end_date, 'MMMM YYYY')}`}
                                            </li>
                                            <li className="align-items-center">
                                                <img src={"/assets/images/Briefcase-perple-icon.svg"} alt="" />
                                                <div className="like-togl">
                                                    <span>{item?.job?.department}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                    :
                    <div className="no-relevant-crew">
                        <p>No results to show</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default CrewWork