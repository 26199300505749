import React from 'react'

interface AddServiceAndProductProps {
    label: any;
    onShow: () => void;
    onRemove: (idx: any) => void;
    data: Array<any>;
    placeholder?: any;
}

const AddServiceAndProduct: React.FC<AddServiceAndProductProps> = (props) => {
    return (
        <div className='select-pupinput mb-4'>
            <h5>{props.label}</h5>
            <ul className='mb-3'>
                {props.data?.map((item: any, idx: number) => <li key={idx}>{item}<img src={"/assets/images/dismiss-cross-white.svg"} alt="" onClick={() => props.onRemove(idx)} /></li>)}
            </ul>
            <button onClick={props.onShow}>{props.placeholder}<img src={"/assets/images/plus-icon.svg"} alt="" /></button>
        </div>
    )
}

export default AddServiceAndProduct