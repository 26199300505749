import { useContext, useEffect, useRef, useState } from 'react';
import { MyContext } from '../context';
import { cookieService } from '../services';
import { cookieServiceParam } from '../utils/constants';

export type WebSocketType = {
    roomID: string;
    onMessage: (data: any) => void;
    userID?: string;
    initializeWebSocket?: boolean;
    superWebSocket?: boolean;
}

export default function useWebSocket(props: WebSocketType) {

    const ws = useRef<WebSocket | null>(null);

    const [isRoomOpen, setIsRoomOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState<any>(null);

    /**
     * it to execute code after the component has rendered.
     */
    useEffect(() => {
        //console.log("USEEFFECT CALLED")
        if (props?.roomID  && props?.initializeWebSocket) {
            removeListeners();
            setTimeout(()=>{initSocket()},300)
            
        }
    }, [props?.roomID, props?.initializeWebSocket]);

    useEffect(() => {
        return removeListeners;
    }, [])

    // initilize websocket
    const initSocket = () => {

        ws.current = new WebSocket(props?.superWebSocket ? `${process.env.REACT_APP_CHAT_BASE_URL}app-connect/${props?.roomID}/` : `${process.env.REACT_APP_CHAT_BASE_URL}chat/${props?.roomID}/${props?.userID}/`);
        //alert(props?.superWebSocket ? `${process.env.REACT_APP_CHAT_BASE_URL}content/${props?.roomID}/` : `${process.env.REACT_APP_CHAT_BASE_URL}${props?.roomID}/${props?.userID}/`);
        /**
        * Callback to trigger when the socket gets connected
        */
        ws.current.onopen = () => setIsRoomOpen(true);

        /**
        * Callback to trigger when the socket gets disconnected
        */
        ws.current.onclose = (evt: any) => setIsRoomOpen(false);

        /**
        * When the message is received in Component
        */
        ws.current.onmessage = (evt: any) => props.onMessage(JSON.parse(evt.data));

        /**
        * Callback to trigger when the socket throws error
        */
        ws.current.onerror = (error: any) => setErrorMessage(error)
    }

    /**
     * clean up listeners when the component is unmounted.
     */
    const removeListeners = () => {
        if (props?.initializeWebSocket) {
            ws.current?.close();
            ws.current = null
        }
    }

    /**
    * Callback to trigger when the user send message
    */
    const onSendMessage = (payLoad: any) => ws.current?.send(JSON.stringify(payLoad))

    const onClose = () => { ws.current?.close();  ws.current = null; }

    return [isRoomOpen, errorMessage, onSendMessage, onClose];
}

/** 
 * Usages
 * const [isRoomOpen, errorMessage, onSendMessage] = useWebSocket({ roomID: <Room Id>, userID:<User ID>, onMessage: callback trigger when the message is received})
 * */