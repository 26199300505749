import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Contacts from "./Contacts";
import Welcome from "./Welcome";
import ChatContainer from "./ChatContainer";
import SideBarLayout from "../../global/layout/SideBarLayout";
import { makeGetCall, makeNextPageCall } from "../../effects/requests";
import { endPoints } from "../../utils/endpoints";
import { MyContext } from "../../context";
import { ApiResponseType, PERMISSION_TYPE, REQ_STATUS, ROLE_TYPE, USER_TYPE } from "../../types";
import queryString from "query-string";
import { showToast } from "../../utils";

import React from 'react';

function Chat() {
  const { userData } = useContext(MyContext);
  const location = useLocation();
  const [currentChat, setCurrentChat] = useState({ chat: location?.state?.data || undefined });
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [data, setData] = useState([]);
  const [nextUrl, setNextUrl] = useState('')
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    if (location?.state?.data) {
      setTimeout(() => {
        setCurrentChat({ chat: location?.state?.data })
      }, 3000)
    }
  }, [location?.state?.data])
  useEffect(() => {
    if (currentChat?.chat?.id) {
      makeGetCall(endPoints.ROOM_LIST + currentChat?.chat?.id + '/')
        .then((res) => {
          if (res?.data?.status === REQ_STATUS.PENDING) {
            if (parsed.type !== USER_TYPE.TYPE_REQUEST) {
              //setCurrentChat({ chat: undefined })
              //showToast("Chat request is pending with user", "info", 323)
            }
          }
          else if (res?.data?.status === REQ_STATUS.DELETED) {
            setCurrentChat({ chat: undefined })
            showToast("User account is deleted", "error", 323)
          }
        })
        .catch((err) => {

        })
    }
  }, [currentChat?.chat?.id])
  useEffect(() => {
    if (userData) {
      if (userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) {
        ownerList(parsed?.type as never)
      }
      else {
        supplierList(parsed?.type === USER_TYPE.TYPE_REQUEST ? false : true)
      }
    }
    setCurrentChat({ chat: undefined })

  }, [parsed?.type, userData])
  const rejectRequest = () =>{
    supplierList(parsed?.type === USER_TYPE.TYPE_REQUEST ? false : true)
    setCurrentChat({ chat: undefined })
  }
  const getList = (params?: any) => {
    makeGetCall(endPoints.ROOM_LIST, params, false, false)
      .then((res) => {
        setData(res?.results)
        setNextUrl(res?.next)
      })
      .catch((err) => { })
  }
  const ownerList = (type: string, searchText?: string) => {
    setData([])
    const supplierParams = {
      user_type: type,
      search: searchText,
      //room_accepted: true
    }
    getList(supplierParams)
  }
  const supplierList = (primary: boolean, searchText?: string) => {
    setData([])
    const requestParams = {
      room_accepted: primary,
      search: searchText
    }
    getList(requestParams)
  }

  const handleChatChange = (chat: any) => {
    setCurrentChat({
      chat: chat
    });
    data.map((el: any) => {
      if (el?.id === chat?.id) {
        el.unread_messages = 0
      }
      return el
    })
  };

  const searchList = (text: string) => {
    if (userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER) {
      ownerList(parsed?.type as never, text)
    }
    else {
      supplierList(parsed?.type === USER_TYPE.TYPE_REQUEST ? false : true, text)
    } 
  }

  const fetchMoreData = () => {
    if (nextUrl) {
    makeNextPageCall(nextUrl).then((res: ApiResponseType<any>) => {
      const contactList = [...data, ...res.results];
      setNextUrl(res?.next);
      setData(contactList as never)
    })
  }
  }
  return (
    <SideBarLayout>
      <div className="right-content-full-screen">
        <div className="full-message-part d-flex">
          <Contacts
            contacts={data}
            currentUser={userData}
            changeChat={handleChatChange}
            isShowMessage={isShowMessage}
            setIsShowMessage={setIsShowMessage}
            supplierList={supplierList}
            ownerList={ownerList}
            searchList={searchList}
            nextUrl={nextUrl}
            setNextUrl={setNextUrl}
            fetchMoreData={fetchMoreData}
          />
          {currentChat?.chat === undefined ? (
            <Welcome currentUsername={userData?.first_name || ""} />
          ) : (
            <ChatContainer
              currentChat={currentChat}
              currentUser={userData}
              setIsShowMessage={setIsShowMessage}
              rejectRequest={rejectRequest}
              updateMsg={(item) => {
                const allList = [...data];
                allList.map((el: any) => {
                  if (el?.id === currentChat?.chat?.id) {
                    el.last_message = item?.message || el?.last_message;
                    el.last_message_on = item?.created_at || el?.last_message_on;
                    //el.unread_messages = item?.unread_messages || 0
                  }
                  return el
                })
                setData(allList)
              }}
            />
          )}
        </div>
      </div>
    </SideBarLayout>
  );
}

export default Chat;
