import React, { useContext, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { MyContext } from '../../context';
import { preConfigDeptData, rolesList } from '../../data/data';
import { createUser } from '../../data/formData';
import Button from '../../global/ui/Button';
import DropDown from '../../global/ui/dropDown';
import InputField from '../../lib/input';
import { EMAIL_VALIDATION, NAME_VALIDATION, REQUIRED_VALIDATION } from '../../lib/input/validation-rules';
import { BUTTON_TYPE, PERMISSION_TYPE, ROLE_TYPE } from '../../types';
import { emptyFunction, getRolesAsPerPrmsn, showToast } from '../../utils';
import { apiConstants } from '../../utils/apiConstants';
import { labels, placeholder } from '../../utils/constants';
interface AddProductionCrewMemProps {
  isShow: boolean;
  onClose: () => void;
  onCreate: (data: any) => void;
  isLoading?: boolean
}

const AddProductionCrewMem: React.FC<AddProductionCrewMemProps> = (props) => {

  const { ...methods } = useForm({
    mode: 'all',
    defaultValues: { ...createUser() },
    reValidateMode: 'onBlur'
  });

  const { userData } = useContext(MyContext)

  const [selectedPermsn, setSelectedPermsn] = useState<any>(null)
  const [selectedDept, setSelectedDept] = useState([])
  const [allRoles, setAllRoles] = useState([...rolesList])
  const [allDept, setAllDept] = useState(preConfigDeptData)

  const onSubmit: SubmitHandler<any> = (data: any) => {
    if (!selectedPermsn?.value) {
      showToast('Please select permission', 'error');
      return
    }
    if (selectedDept?.length <= 0) {
      showToast('Please select department', 'error');
      return
    }
    const dept: any = [];
    selectedDept?.map((item: any) => {
      dept.push({ dep: item?.label, roles: item?.roles })
    })
    const payload: any = {
      ...methods?.getValues(),
      permission_type: selectedPermsn?.value,
      company_department: JSON.stringify(dept)
    }
    props.onCreate(payload)
  };

  useEffect(() => {
    if (props?.isShow) {
      methods.reset();
      setSelectedDept([]);
      setSelectedPermsn(null)
    }
  }, [props.isShow]);

  useEffect(() => {
    if (userData?.permission_type !== PERMISSION_TYPE.TYPE_PROD_OWNER) {
      if (userData?.crew_member_profile?.company_department?.length > 0) {
        userData?.crew_member_profile?.company_department?.map((item: any) => {
          item.label = item?.dep;
          item.value = item?.dep;
        })
        setAllDept(userData?.crew_member_profile?.company_department)
      }
      const data = getRolesAsPerPrmsn(userData?.permission_type);
      setAllRoles(data)
    } else {
      setAllRoles([...rolesList])
    }
  }, [userData])

  const isSuperUser = (selectedPermsn?.value == PERMISSION_TYPE.TYPE_ADMIN || selectedPermsn?.value === PERMISSION_TYPE.TYPE_HOD) ? true : false
  console.log('list', userData, allRoles)

  return (
    <Modal
      centered
      scrollable
      className='max-width-496 addProductionCrew'
      show={props.isShow}
      onHide={emptyFunction}>
      <ModalHeader className="p-0 pb-3 border-0 mb-3 align-items-start">
        <button className="close-btn-de shadow-none" onClick={props.onClose}><img src={"/assets/images/black-arrow-left-side.svg"} alt="" /></button>
        <ModalTitle className="fw-bold me-auto ps-3 text-start">
          <h4 className='h4 text-start mb-0 mt-0'>Add Production Crew</h4>
          <p className="mb-0 text-start">Invite crew members to your production company</p>
        </ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0 text-start  height-402">
        <div className='row mx-0'>
          <FormProvider {...methods}>
            <div className='col-md-12'>
              <InputField
                label={labels.email}
                placeholder={placeholder.email}
                name={apiConstants.email}
                type='email'
                rules={EMAIL_VALIDATION}
                className="black-input mb-4"
              />
            </div>
            <div className='col-md-6'>
              <InputField
                type={'text'}
                name={apiConstants.first_name}
                label={labels.first_name}
                className="black-input mb-4"
                placeholder='Enter first name'
                rules={NAME_VALIDATION(labels.first_name)}
              />
            </div>
            <div className='col-md-6'>
              <InputField
                type={'text'}
                name={apiConstants.last_name}
                label={labels.last_name}
                className="black-input mb-4"
                placeholder='Enter last name'
                rules={NAME_VALIDATION(labels.last_name)}
              />
            </div>
          </FormProvider>
          <div className='col-md-12'>
            <DropDown
              className="arrow-fill drop-down-over-vis drop-uppers"
              data={allRoles}
              onSelect={(data) => {
                setSelectedPermsn(data);
                setSelectedDept([])
              }}
              placeholder='Select Permission'
              label='Permission Type'
              selectedItems={selectedPermsn}
            />
          </div>
          {selectedPermsn &&
            <div className='col-md-12'>
              <DropDown
                className="arrow-fill drop-down-over-vis"
                data={allDept}
                onSelect={(data: any) => {
                  if (isSuperUser) {
                    setSelectedDept(data)
                  } else {
                    const temp: any = [];
                    temp.push(data)
                    setSelectedDept(temp)
                  }
                }}
                placeholder='Select Department'
                label='Department'
                selectedItems={isSuperUser ? selectedDept : selectedDept?.[0]}
                isMultiSelect={isSuperUser ? true : false}
              />
            </div>
          }
        </div>
      </ModalBody>
      <ModalFooter className="p-0 border-0" style={{ marginTop: '0px' }}>
        <Button
          type={BUTTON_TYPE.TYPE_BUTTON}
          btnTxt='Invite'
          style={{ marginTop: "0px", width: "auto" }}
          className={'btn btn-warning w-100 with-after-shadow shadow-none m-0'}
          onClick={methods.handleSubmit(onSubmit)}
          isLoading={props?.isLoading}
        />
      </ModalFooter>
    </Modal>
  )
}

export default AddProductionCrewMem