import { EVENT_TYPE } from "../../../types";
import { DateUtils } from "../../../utils/dateUtils";

interface IEventCardProps {
    item: any;
    totalEventLength: number;
    onView: (event: any) => void;
}

const EventCard = (props: IEventCardProps) => {
    return (
        <div onClick={() => props.onView(props.item)} className={props.item?.type === EVENT_TYPE.SHOOT ? 'event-month-imp event-scadu' : "event-month event-scadu"} style={{ padding: '0.5rem 1rem', cursor: "pointer" }} >
            <div className="d-flex justify-content-between align-items-center" >
                <p className="mb-0 fw-bold other-event-title">{props.item?.name || ''}</p>
                <img src={props.item?.type === EVENT_TYPE.SHOOT ? "/assets/images/play-icon-white.svg" : "/assets/images/play-icon-dark.svg"} className="right-side-mo" alt="arrow" />
            </div>
            {props.totalEventLength <= 1 && <p className="mb-0" style={{ fontWeight: '500' }}>{`${DateUtils.formatDateTime(props.item?.start_datetime, 'HH:mm')} - ${DateUtils.formatDateTime(props.item?.end_datetime, 'HH:mm')}`}</p>}
        </div>
    );
}

export default EventCard 
