import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../context";
import { eventTypeList, preConfigDeptData } from "../../data/data";
import DropDown from "../../global/ui/dropDown";
import { PERMISSION_TYPE, ROLE_TYPE, SCHEDULE_TYPE } from "../../types";
import { setDropdownSelectedValue } from "../../utils";
import { apiConstants } from "../../utils/apiConstants";
import { labels, placeholder } from "../../utils/constants";
import UserFilter, { UserFilterProps } from "./UserFilter";

interface ScheduleFiltersProps {
  value: any;
  onChange: (key: any, value: any) => void;
  onExport?: () => void;
  userFilterData: UserFilterProps;
  changeScheduleType: any;
  type:any,
  scheduleType?:any,
  isOwner?:boolean,
  clearAll?: () => void
}

const ScheduleFilters: React.FC<ScheduleFiltersProps> = (props) => {
  const { userData } = useContext(MyContext);

  const [allDept, setAllDept] = useState(preConfigDeptData);

  useEffect(() => {
    if (
      userData?.permission_type !== PERMISSION_TYPE.TYPE_PROD_OWNER &&
      userData?.crew_member_profile?.company_department?.length > 0
    ) {
      userData?.crew_member_profile?.company_department?.map((item: any) => {
        item.label = item?.dep;
        item.value = item?.dep;
      })
      setAllDept(userData?.crew_member_profile?.company_department);
    }
  }, [userData]);

  const roles =
    setDropdownSelectedValue(allDept, props?.value?.[apiConstants.department])
      ?.roles?.length > 0
      ? setDropdownSelectedValue(
          allDept,
          props?.value?.[apiConstants.department]
        )?.roles
      : [];

  return (
    <div className="table-filter-setup mb-3 mb-lg-0">
      <div className="row align-items-center d-flex justify-content-between">
        {props?.isOwner && 
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3 order-1 order-xxl-1">
          <div className="liket-active-tab mb-4">
            <ul className="list-unstyled d-flex align-item-center mb-0">
            <li onClick={() => props?.changeScheduleType(SCHEDULE_TYPE.CREW_SCH)} className={props?.type === SCHEDULE_TYPE.CREW_SCH ? "active" : ''}>Crew Schedule</li>
            <li onClick={() => props?.changeScheduleType(SCHEDULE_TYPE.EVENT_SCH)} className={props?.type === SCHEDULE_TYPE.EVENT_SCH ? "active" : ''}>Event Schedule</li>
            </ul>
          </div>
        </div>}
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 order-2 order-sm-3 order-xxl-2">
          <div className="select-fileter d-grid d-sm-flex align-items-center gap-4 mb-4">
          {props.scheduleType === SCHEDULE_TYPE.CREW_SCH && props?.isOwner? '':
            <DropDown
            className="arrow-fill mb-0 w-100 drop-down-over-vis"
            data={eventTypeList}
            placeholder="All events"
            label="Filter by event type"
            selectedItems={setDropdownSelectedValue(
              eventTypeList,
              props?.value?.[apiConstants.event_type]
            )}
            onSelect={(data) =>
              props?.onChange?.(apiConstants.event_type, data?.value)
            }
          />
          }
            <DropDown
              className="arrow-fill mb-0 w-100 drop-down-over-vis"
              data={allDept}
              placeholder="All departments"
              label="Filter by department"
              selectedItems={setDropdownSelectedValue(
                allDept,
                props?.value?.[apiConstants.department]
              )}
              onSelect={(data) =>
                props?.onChange?.(apiConstants.department, data?.label)
              }
            />
            {props.scheduleType === SCHEDULE_TYPE.CREW_SCH && props?.isOwner? 
            <DropDown
              className="arrow-fill  mb-0 w-100 drop-down-over-vis"
              data={roles}
              onSelect={(data) =>
                props?.onChange?.(apiConstants.role, data?.label)
              }
              placeholder={placeholder.allRole}
              selectedItems={setDropdownSelectedValue(
                roles,
                props?.value?.[apiConstants.role]
              )}
              label={labels.filterByRole}
            />
              :''}
            {props.scheduleType === SCHEDULE_TYPE.CREW_SCH && props?.isOwner && <UserFilter {...props.userFilterData}  />}
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3 order-3 order-sm-2 order-xxl-3">
          <div className="right-side-showing d-flex align-items-center justify-content-center justify-content-lg-end gap-3 mb-4 mt-3 mt-sm-0">
            <div className="clear-all" onClick={props?.clearAll}
              >
              Clear all</div>
            {props?.isOwner && <button
              className="btn btn-light-with-icon shadow-none"
              onClick={props?.onExport}
            >
              <img src={"/assets/images/arrow-download-black.svg"} alt="" />
              Export Data
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleFilters;
