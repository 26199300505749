
interface CurrencyItemProps {
    item: any;
    onClick?: (value: any) => void;
    selectedCurrency?: any
}

const CurrencyItem = (props: CurrencyItemProps) => {
    return (
        <div onClick={() => props?.onClick?.(props.item?.value)} className="form-check text-start mb-0">
            <input
                className="form-check-input"
                type="radio"
                name='select_currency'
                id={props.item?.id}
                checked={props.item?.value === props?.selectedCurrency}
                onChange={(evt) => props?.onClick?.(props.item?.value)}
            />
            <label className="form-check-label" htmlFor={props.item?.id}>
                {props.item?.label || ""}
            </label>
        </div>
    )
}

export default CurrencyItem