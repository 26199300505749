import axios, { AxiosError, AxiosResponse } from "axios";
import { isUserAuthenticated, showToast } from ".";
import { cookieService } from "../services";
import { cookieServiceParam, errorMessage } from "./constants";

const instance: any = axios.create({
    // baseURL: endpoints.API_BASE_URL,
    baseURL: process.env.REACT_APP_LIVE_APIURL,
    timeout: 60000,
});

const _instance: any = axios.create({
    baseURL: process.env.REACT_APP_LIVE_APIURL,
    timeout: 60000,
});

//this method allow to update api base url
const updateBaseUrl = (protocol: any,) => {
    let baseURL = process.env.REACT_APP_LIVE_APIURL;
    return baseURL;
};

instance.interceptors.request.use(
    function (config: any) {
        //const accessToken: any = getCookie("access_token");
        const accessToken: any = cookieService.getCookie(cookieServiceParam.token)

        if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
        }


        const element: any = document.getElementById("loader");

        if (!config?.options?.hideGlobalLoader) {
            element.style.display = "flex";
        }
        // element.style.display = "none";
        // Do something before request is sent

        //Updating api base url
        config.baseURL = updateBaseUrl(
            window.location.protocol
        );
        return config;
    },
    function (error: AxiosError) {
        const element: any = document.getElementById("loader");
        element.style.display = "none";

        // Do something with request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    async function (response: AxiosResponse) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // console.log("RESPONSE===>", response);

        const element: any = document.getElementById("loader");
        element.style.display = "none";

        const data: any = response?.data;
        const statusCode = response?.data?.status?.code;
        const detail = data?.status?.detail;
        if (statusCode === 401 && isUserAuthenticated()) {
            cookieService.removeAllCookies();
        } else if (statusCode === 403 || statusCode === 400) {
            if (detail) {
                for (var key in detail) {
                    if (detail.hasOwnProperty(key)) {
                        if (!detail[0]) {
                            showToast(detail[key][0], "error", detail[key][0]);
                        } else {
                            showToast(detail[0], "error", detail[0]);
                        }
                    }
                }
            } else {
                const message = data.status?.message;
                showToast(message, "error", message);
            }
        }
        else if (statusCode === 401) {
            const message = data.status?.message;
            showToast(message, "error", message);
        }
        else if (statusCode === 200) {
            // const message = data.status?.message;
            // showToast(message, "success", message);
        }
        return data;
    },
    async function (error: AxiosError) {
        const element: any = document.getElementById("loader");
        element.style.display = "none";
        const response: any = error?.response;
        if (response) {
            const errorData = response?.data?.errors;
            const status: any = response?.status;
            if (status === 401 && isUserAuthenticated()) {
                cookieService.removeAllCookies();
            } else if (status === 400) {
                if (errorData) {
                    for (const key in errorData) {
                        if (errorData.hasOwnProperty(key)) {
                            if (!errorData[0]) {
                                showToast(errorData[key][0], "error", errorData[key][0]);
                            } else {
                                showToast(errorData[0], "error", errorData[0]);
                            }
                        }
                    }
                }
            } else if (status >= 500) {
                showToast(
                    errorMessage.server_error,
                    "error",
                    errorMessage.server_error
                );
            }
        }
        return Promise.reject(error);
    }
);

export { instance, _instance };
