
const FullViewImage = (props: any) => {
  return (

    <>
      {props.show ?
        <div className="message-image-popup-bg">
          <div className="top-img d-flex justify-content-between align-items-center mb-5">
            <img src="/assets/images/white-arrow-left-side.svg" onClick={props.closePopup} alt="" />
            {props.showDownload && <a href={props.enlargeImage} ><img src="/assets/images/arrow-download-white.svg" alt="" /></a>}
          </div>
          <div className="message-img-size text-center">
            <img src={props.enlargeImage} className=" rounded" />
          </div>
        </div>
        : ''
      }
    </>
  )
}
export default FullViewImage; 