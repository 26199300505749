import React from 'react';
interface ProfileImageProps {
  data: any;
  style?: React.CSSProperties
}
const Logo = (props:ProfileImageProps) => {
  const initials = props?.data?.toUpperCase();
  return <div style={props?.style} className="logoStyle">{initials}</div>;
};

export default Logo;
