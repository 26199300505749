import { BUTTON_TYPE } from "../../types";
import Modal from 'react-bootstrap/Modal';
import Button from "../../global/ui/Button";
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ModalTitle from 'react-bootstrap/ModalTitle'
import { makeGetCall } from "../../effects/requests";
import { endPoints } from "../../utils/endpoints";
import { MyContext } from "../../context";
import { useContext } from "react";
import { showToast } from "../../utils";


const VerifyUpdatedEmail = (props: any) => {

  const { userData } = useContext(MyContext)
  const verifyEmail = () => {
    const params = {
      email:userData.email
    }
    makeGetCall(endPoints.VERIFYEMAIL,params)
      .then((res) => {
        if (res?.status?.code === 200) {
          showToast(res?.status?.message, "success",)
          props.closePopup();
        }
      })
      .catch((err) => {

      })
  }
  return (
    <Modal
      centered
      scrollable
      show={props.show}
      onHide={props.closePopup}
      className="corss-setup-top-left">
      <ModalHeader className="p-0 border-0 text-center mb-3">
        <button className="close-btn-de shadow-none" onClick={props.closePopup}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
        <ModalTitle className="fw-bold mx-auto">
          <img src="/assets/images/email-notification.svg" className="mb-4" style={{ opacity: '20%' }} alt="" /> <br />
          Verify Email Address</ModalTitle>

      </ModalHeader>
      <ModalBody className="p-0 text-center mb-5 pb-3">
        <p className="mb-0">We have sent a verification link to your email</p>
      </ModalBody>
      <ModalFooter className="p-0 border-0">
        <Button
          type={BUTTON_TYPE.TYPE_BUTTON}
          btnTxt="Continue"
          style={{ marginTop: "0px", width: "auto" }}
          onClick={verifyEmail}
          className={'btn btn-warning w-100 with-after-shadow shadow-none'}
        />
      </ModalFooter>
    </Modal>
  )
}
export default VerifyUpdatedEmail; 