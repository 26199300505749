import { useNavigate } from 'react-router-dom';

interface LegalHeaderProps {
    onBack?: () => void;
    title: string;
    backIcons?: any
}

export const LegalHeader = (props: LegalHeaderProps) => {

    const navigate = useNavigate();

    return (
        <div className='top-heading text-start'>
            <h3 onClick={() => {
                if (props?.onBack) {
                    props?.onBack?.();
                    return
                }
                navigate(-1)
            }}
            ><img src={props?.backIcons || "/assets/images/left-arrow-yellow.svg"} alt="" /> <span>{props?.title}</span></h3>
        </div>
    )
}
