import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ReactNotificationComponent = ( title:any, body:any ) => {

  return(
  toast.success(
    <div>
        <p>{title}</p>
        <p>{body}</p>
      </div>
  ,{
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false
}))

};
export default ReactNotificationComponent;