import React from 'react'

interface SupplierMediaProps {
    data: Array<any>
}

const SupplierMedia: React.FC<SupplierMediaProps> = (props) => {
    const extensions = [".jpg", ".jpeg", ".png", ".JPG", ".JPEG", ".PNG"];
    return (
        <div className='rate-section mb-3 text-center text-sm-start'>
            <h3>Gallery</h3>
            <div className="showing-img row row-cols-2 gy-4">
                {props.data?.map((item, idx) => {
                    return (
                        <div key={idx} className="col">
                            
                            {extensions.some(extension => item?.image?.includes(extension)) ? <img className="w-100" src={item?.image} alt="" /> :
                            <video width="25%" 
                            height="75%"
                            src={item?.image}
                            className="w-100"
                            />
                        }
                            {/* <img src={item?.image} className="w-100" alt="Image" /> */}
                        </div>
                    )
                })}
            </div>
            <br/>
        </div>
    )
}

export default SupplierMedia