import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CrewListItems from "./CrewListItems";


type crewMemberList ={
  searchName:any,
  tempData:any,
  selectCrewMembers:any,
  deleteMember?:any
}
interface CrewListItem {
  id: number;
  first_name: string;
  last_name: string;
}
const CrewMemberList = (props:any) => {
  const renderList = props?.tempData.map( (item:any) => {
    //const temp = item.first_name.toLowerCase().includes(props?.searchName.toLowerCase()) || item.last_name.toLowerCase().includes(props?.searchName.toLowerCase())
    return item && <CrewListItems item={item}  key={item.id} selectCrewMembers={props?.selectCrewMembers} deleteMember={props?.deleteMember}/>   
  })
  
  return (
    <div>
      <InfiniteScroll
        dataLength={props?.tempData?.length}
        next={props?.fetchMoreData()}
        hasMore={props?.nextUrl ? true : false}
        loader={null}
        scrollableTarget="scrollableDiv11"
      >
      {renderList}
      {renderList.every((item:any) => item === false) && <div style={{textAlign:'center'}}>No result found</div>}
    </InfiniteScroll>
    </div>
  );
};

export default CrewMemberList;
