import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import { AddUserType } from '../commonComponent/AddUserType'

const AddUser = (props: any) => {
    // const selectTypeButton = (manual: boolean, bulk:boolean, buttonText: string , type: string) => {
    //     return(
    //     <div className='add-user-desing dotted-border text-center' onClick={()=>{props?.setIsShowManualBulk({isShowManual:manual , isShowBulk:bulk})}}>
    //         <img src="/assets/images/user-icon-fill-dark.svg" alt="" />
    //             <h3>{buttonText}</h3>
    //         <p className='mb-0'>Best for adding {type} users</p>
    //     </div>)
    // }
    return (
        <Modal
            centered
            scrollable
            className='max-width-496 addProductionCrew modal-body-scroll-part'
            show={props.isShow}
            onHide={props.onClose}>
            <ModalHeader className="p-0 pb-4 border-0 mb-4 align-items-start">
                <button className="close-btn-de shadow-none" onClick={props.onClose}><img src={"/assets/images/black-arrow-left-side.svg"} alt="" /></button>
                <ModalTitle className="fw-bold me-auto ps-3 text-start">
                    <h4 className='h4 text-start mb-0 mt-0'>Add User </h4>
                    <p className="mb-0 text-start">Choose one of the following options to add team</p>
                </ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-start">
                {
                <div className='add-user-type-list d-grid gap-4'>
                    {/* {selectTypeButton(true, false, 'Add Manually' ,'individual')}
                    {selectTypeButton(false, true, 'Bulk Import' ,'multiple')} */}
                    <AddUserType onClick={()=>{props?.onClick({isShowManual:true , isShowBulk:false})}} buttonText={'Add Manually'} type={'individual'}/>
                    <AddUserType onClick={()=>{props?.onClick({isShowManual:false , isShowBulk:true})}} buttonText={'Bulk Import'} type={'multiple'}/>
                </div>}

            </ModalBody>

        </Modal>
    )
}

export default AddUser
