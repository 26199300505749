import { useLocation, } from "react-router-dom";

const AuthSection = (props: any) => {
  const { children } = props;
  const location = useLocation()

  return (
    <div>
      <div className='main-section'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='middel-part d-grid d-md-flex align-items-center justify-content-center text-white'>
              <div className='main-contnet w-100'>
                <div className='side-setup'>
                  <div style={{ cursor: 'pointer' }}  className='top-logo'>
                    <a href='https://www.sixthdegree.io/' target="_blank" rel="noopener noreferrer">
                    <img src='/assets/images/logo.png' className="w-100" alt='loader' />
                    </a>
                  </div>
                  {children}
                </div>
              </div>
              <div className={`main-img w-100 d-none d-md-block position-relative ${props?.isProductionSignUp ? 'radientcalss' : ''}`}>
                <img src='/assets/images/Signup-bg.png' className="mw-100 w-100 backimg" alt='loader' />
                {(props?.isProductionSignUp && location.pathname !== '/subscription')
                  ?
                  <div className="overlay-qr">
                    <h3>Don’t have a crew account?</h3>
                    <p>Scan the below QR code to download the Sixth Degree crew app to create your crew profile</p>
                    <img style={{ width: '220px', height: '220px' }} src="/assets/images/qr-code.svg" alt="Overlay Image" />
                  </div>
                  :
                  ''
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AuthSection;