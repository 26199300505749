import React, { useContext, useEffect, useState } from 'react'
import RouteStack from './routes'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalLoader from './global/ui/GlobalLoader';
import { cookieService } from './services';
import { cookieServiceParam } from './utils/constants';
import { makeGetCall } from './effects/requests';
import { ApiResponseType } from './types';
import { endPoints } from './utils/endpoints';
import { MyContext } from './context';
import { onMessageListener } from './../src/global/FCM/FCM'
import Notifications from './global/FCM/notificationToken';
import ReactNotificationComponent from './global/FCM/toast-notify'
import useWebSocket from './hooks/useWebSocket';

const App: React.FC = () => {

  const { updateUserData, userData, updateGlobalSocketNotification } = useContext(MyContext)
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isRoomOpen, errorMessage, onSendMessage, onClose] = useWebSocket({
    roomID: userData?.id,
    initializeWebSocket: true,
    superWebSocket: true,
    onMessage: (data) => {
      updateGlobalSocketNotification(true)
    }
  });
  window.addEventListener('beforeunload', function (e) {
    e.preventDefault();
    e.returnValue = '';
    onClose()
  });
  useEffect(() => {
    if (!cookieService.getCookie(cookieServiceParam.token)) {
      //alert("closed socket")
      onClose()
    }
  }, [cookieService.getCookie(cookieServiceParam.token)])
  onMessageListener()
    .then((payload: any) => {
      setShow(true);
      getUserDetails();
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      ReactNotificationComponent(payload.notification.title, payload.notification.body)

    })
    .catch((err: any) => console.log("failed: ", err));

  const getUserDetails = () => {
    const accessToken: any = cookieService.getCookie(cookieServiceParam.token);
    if (!accessToken) return;
    makeGetCall(endPoints.PROFILE, null, false, true).then((res: ApiResponseType<any>) => {
      if (res?.data?.crew_member_profile?.company_department?.length > 0) {
        res?.data?.crew_member_profile?.company_department?.map((item: any) => {
          item.label = item?.dep;
          item.value = item?.dep;
        })
      }
      updateUserData(res)
    }).catch((err: any) => console.log(err))
  }

  useEffect(getUserDetails, [])

  return (
    <>
      <GlobalLoader />
      <RouteStack />
      <Notifications />
      <ToastContainer />
    </>
  )
}

export default App