import React from 'react';
import { ROLE_TYPE } from '../../types';

interface Props {
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
  checked: boolean;
  label: string;
  id: string;
  name: string;
  type: string;
}

const RadioButton = (props: Props) => {
  return (
    <div className="form-check text-start">
      <input className="form-check-input" type="radio" name={props?.name} id={props?.id} defaultChecked={props?.checked} onClick={props?.onClick} />
      <label className="form-check-label" htmlFor={props?.id}>
        <h5>{props?.label}</h5>
        <p>{props?.type === ROLE_TYPE.TYPE_CREW ? 'Sign up as a production company to manage crew schedules, workflows, messages and reporting.' : 'Sign up as a supplier to create a detailed profile and manage messages and schedules with production partners.'}</p>
      </label>
    </div>
  )
}

export default RadioButton;