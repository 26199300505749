import React from 'react'

interface DepartmentsProps {
    data: Array<any>
}

const Departments: React.FC<DepartmentsProps> = (props) => {
    let allData = props?.data?.join(',').split(',');
    let count = null

    if (allData?.length > 2) {
        count = allData.length - 1
        allData = allData.slice(0, 1);
    }

    return (
        <div className='button-cont d-flex flex-wrap gap-2 mb-3 mt-2'>
            {allData?.map((dep: any, idx: number) => <button key={idx} className='perple-button'>{dep}</button>)}
            {count && <button className='perple-button'>+{count}</button>}
        </div>
    )
}

export default Departments