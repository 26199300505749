import React, { useContext } from 'react'
import Search from './Search';
import { buttonsText } from '../../utils/constants';
import { MyContext } from '../../context';
import { PERMISSION_TYPE, ROLE_TYPE } from '../../types';

interface SearchWithHeadingProps {
    heading?: string;
    placeholder?: string;
    onSearch: (value: any) => void;
    value?: any;
    onAdd?: () => void;
    newEvent?: () => void;
    addText?: any
}

const SearchWithHeading: React.FC<SearchWithHeadingProps> = (props) => {
    const {userData} = useContext(MyContext)

    return (
        <div className="main-top-bar-heading px-4  d-md-flex align-items-center justify-content-center justify-content-md-between gap-3">
            <h3 className="fw-bold text-center text-md-start mb-3 mb-md-0">{props?.heading || ''}</h3>
            <div className="search-button d-flex align-items-center gap-3 justify-content-center">
                <Search
                    placeholder={props?.placeholder}
                    value={props?.value}
                    onChange={props.onSearch}
                />
                {(props.addText&& (userData?.user_type === ROLE_TYPE.TYPE_CREW && userData?.permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER)) && <button className="btn btn-warning btn-sm shadow-none" onClick={props.onAdd}><img src={"/assets/images/plus-icon.svg"} alt="" /> {props.addText}</button>}
            </div >
        </div >
    )
}

export default SearchWithHeading