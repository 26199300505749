import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import { BUTTON_TYPE } from '../../types'
import Button from "../../global/ui/Button";
import { makeGetCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { useEffect, useState } from 'react';

const DownloadCSV = (props: any) => {
  const [downloadLink, setDownloadLink] = useState('');
  const downloadTemplate = () => {
    makeGetCall(endPoints.DOWNLOAD_TEMPLATE)
      .then((res) => {
        setDownloadLink(res?.data?.file_url)
      })
      .catch((err) => {

      })
  }
  useEffect(() => {
    downloadTemplate()
  }, [])
  return (
    <Modal
      centered
      scrollable
      className='max-width-496 addProductionCrew modal-body-scroll-part'
      show={props.show}
      onHide={props.closePopup}>
      <ModalHeader className="p-0 pb-4 border-0 mb-4 align-items-start">
        <button className="close-btn-de shadow-none" onClick={() => { props.closePopup(); setDownloadLink(''); }}><img src={"/assets/images/black-arrow-left-side.svg"} alt="" /></button>
        <ModalTitle className="fw-bold me-auto ps-3 text-start">
          <h4 className='h4 text-start mb-0 mt-0'>Download CSV </h4>
          <p className="mb-0 text-start">Download the file below to populate your users data</p>
        </ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0 text-start">
        <a href={downloadLink} download={downloadLink} className="text-decoration-none"><div className='add-user-type-list d-grid gap-4' onClick={downloadTemplate}>
          <div className='add-user-desing dotted-border text-center'>
            <img src="/assets/images/download-csv-fill-dark.svg" alt="" />
            <h3>Download CSV</h3>
            <p className='mb-0'>Download CSV to populate data</p>
          </div>
        </div></a>
      </ModalBody>
      <ModalFooter className="p-0 border-0" style={{ marginTop: '125px' }}>
        <Button
          type={BUTTON_TYPE.TYPE_BUTTON}
          btnTxt='Continue'
          style={{ marginTop: "0px", width: "auto" }}
          onClick={() => { props.onContinue(); setDownloadLink(''); }}
          className={'btn btn-warning w-100 with-after-shadow shadow-none m-0'}
        />
      </ModalFooter>
    </Modal>
  )
}

export default DownloadCSV
