import React, { useContext } from "react";
import { DateUtils } from "../../utils/dateUtils";
import { Table } from "react-bootstrap";
import { EVENT_STATUS, EVENT_TYPE, ROLE_TYPE, SCHEDULE_TYPE } from "../../types";
import { MyContext } from "../../context";

const EventScheduleWeek = (props: any) => {
  //To know whether its a superuser or not
  const { userData } = useContext(MyContext)

  const dateData = props?.allEventListData?.date_data;
  const eventList = userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? props?.allEventListData?.event : props?.allEventListData?.data;
  return (
    <div className="main-collender event-scadule "  >
      <div className={`event-scadule-mintabsl ${!props?.isOwner ? 'mh-100' : null}`} >
        <Table responsive borderless className="fix-height mb-0" cellSpacing={"0"} cellPadding={"0"} >
          <thead>
            <tr>
              {props.data?.map((item: any, idx: any) => {
                const headerDate = `${DateUtils.formatDateTime(item, "DD")}`
                return (
                  <th className="tablehead-week" key={idx} style={{ width: "12%", minWidth: "162px", padding: '18px 10px' }}>
                    <div className="top-part-collender d-flex justify-content-between align-items-center">
                      <div className="d-grid ">
                        <p className="mb-0">{`${DateUtils.formatDateTime(
                          item,
                          "ddd"
                        )}`}</p>
                        <h6 className="mb-0">{headerDate} </h6>
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {props.data?.map((item: any, idx: any) => {
                const headerDate = `${DateUtils.formatDateTime(item, "DD")}`
                return (
                  <td className="tablehead-week" key={idx} style={{ width: "12%", minWidth: "162px" }}>
                    {eventList?.map((event: any, idx: any) => {

                      const startTime = userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? event?.event?.start_datetime : event?.start_datetime;
                      const eventDate = `${DateUtils.formatDateTime(startTime, "DD")}`;

                      const eventType = userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? event?.event?.type : event?.type;
                      if (headerDate === eventDate) {
                        if (eventType === EVENT_TYPE.SHOOT) {

                          //For Supplier Login IMPORTANT EVENT
                          if (userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER) {
                            // console.log(event, event?.event?.type,'here1', event?.status)
                            const eventStatus = event?.status;
                            if (eventStatus === EVENT_STATUS.ACCEPTED) {
                              event = event?.event;
                              return <div className='imp-event' style={{ height: '60px' }} key={event?.id}>
                                <div className='outer'>
                                  <div className='event-name' onClick={() => props.onEventView(event?.id, eventStatus)} >{event?.name}</div>
                                </div>
                                <div className='event-address' onClick={() => props.onEventView(event.id, eventStatus)}>{event?.address}</div>
                              </div>
                            } else if (event?.status === EVENT_STATUS.PENDING) {
                              const supplierID = event?.id;
                              event = event?.event;
                              return <div className='imp-event event-request ' style={{ height: '60px' }} key={event?.id}>
                                <div className='outer'>
                                  <div className='event-name event-request-text' onClick={() => props.onEventView(event?.id, eventStatus, supplierID)} >{event?.name}</div>
                                </div>
                                <div className='event-address event-request-sub-text text-break' onClick={() => props.onEventView(event.id, eventStatus, supplierID)}>{event?.address}</div>
                              </div>
                            }
                          } else {

                            //For Owner or Crew Login IMPORTANT EVENT
                            return <div className='imp-event' style={{ height: '60px' }}>
                              <div className='outer'>
                                <div className='event-name' onClick={() => props.onEventView(event?.id)} >{event?.name}</div>
                                {props?.isOwner ? <img src="/assets/images/schedule-white.svg" alt="" onClick={() => props?.onAdd(event?.id)} /> : ''}
                              </div>
                              <div className='event-address' onClick={() => props.onEventView(event.id)}>{event?.address}</div>
                            </div>
                          }
                        }
                      }
                    })
                    }
                    {eventList?.map((event: any, idx: any) => {
                      const startTime = userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? event?.event?.start_datetime : event?.start_datetime;
                      const eventDate = `${DateUtils.formatDateTime(startTime, "DD")}`;

                      const eventType = userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? event?.event?.type : event?.type;
                      if (headerDate === eventDate) {
                        if (eventType !== EVENT_TYPE.SHOOT) {
                          //For Supplier Login - Other Event
                          if (userData?.user_type === ROLE_TYPE.TYPE_SUPPLIER) {
                            const eventStatus = event?.status;
                            if (eventStatus === EVENT_STATUS.ACCEPTED) {
                              event = event?.event;
                              return <div className='imp-event event-scadu' style={{ padding: '0.5rem 1rem', height: '60px' }}>
                                <div className='outer'>
                                  <div className='other-title' onClick={() => props.onEventView(event?.id, eventStatus)} >{event?.name}</div>
                                  <img style={{ cursor: "pointer" }} src="/assets/images/play-icon-dark.svg" className="right-side-mo" alt="" />
                                </div>
                                <div className="event-address" style={{ color: 'black' }} onClick={() => props.onEventView(event.id, eventStatus)}>{event?.address}</div>
                              </div>
                            } else if (eventStatus === EVENT_STATUS.PENDING) {
                              // console.log(event, event?.event?.type,'here2')
                              const supplierID = event?.id;
                              event = event?.event;
                              return <div className='imp-event other-event-request ' style={{ height: '60px' }}>
                                <div className='outer'>
                                  <div className='event-name event-request-text ' onClick={() => props.onEventView(event?.id, eventStatus, supplierID)} >{event?.name}</div>
                                  <img style={{ cursor: "pointer" }} src="/assets/images/play-icon-dark.svg" className="right-side-mo" alt="" />
                                </div>
                                <div className='event-address event-request-sub-text ' onClick={() => props.onEventView(event?.id, eventStatus, supplierID)}>{event?.address}</div>
                              </div>
                            }
                          } else {

                            //For Owner or Crew Login - Other Event                 
                            return <div className="imp-event event-scadu" style={{ padding: '0.5rem 1rem' }} >
                              <div className="d-flex justify-content-between align-items-center" >
                                <p className="mb-0 fw-bold other-event-title" onClick={() => props.onEventView(event.id)}>{event?.name}</p>
                                <img style={{ cursor: "pointer" }}
                                  // onClick={() => setSelectedDate(DateUtils.getNextWeek(selectedDate))}
                                  src="/assets/images/play-icon-dark.svg"
                                  className="right-side-mo" alt="" />
                              </div>
                              <p className="mb-0" style={{ fontWeight: '500' }} onClick={() => props.onEventView(event.id)}>{event?.start_datetime.slice(11, 16)} - {event?.end_datetime.slice(11, 16)}</p>
                            </div>
                          }
                        }
                      }
                    })
                    }
                  </td>
                );
              })}
            </tr>
          </tbody>
        </Table>
      </div>
      {props?.isOwner && <Table responsive borderless className="event-scadule-bottom-fisx" cellSpacing={"0"} >
        <tbody>
          <tr>
            {props.data?.map((item: any, idx: any) => {
              const key: any = DateUtils.formatDateTime(item, 'YYYY-MM-DD')
              // console.log(props?.allEventListData?.date_data[key]?.total_crew)
              return (
                <th key={idx}>
                  <div className="bottom-wwekse">
                    <div className="top-part-collender d-flex justify-content-between align-items-center">
                      <div className="d-grid bottom-week">
                        <div className="add-schedule d-flex align-items-center justify-content-between">
                          Add Event
                          <img className="cursor-pointer" src="/assets/images/schedule-plus.svg" alt="" onClick={() => props?.newEventShow()} />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center gap-2 fw-normal">
                      <div className="fw-bold d-flex align-items-center gap-1">
                        <img src="/assets/images/crew-member.svg" alt="" />
                        Crew
                      </div>
                      {dateData !== undefined && dateData[key]?.total_crew}
                    </div>
                    <div className="d-flex justify-content-between align-items-center gap-2 fw-normal">
                      <div className="fw-bold d-flex align-items-center gap-1">
                        <img src="/assets/images/supplier-mem.svg" alt="" />
                        Supplier
                      </div>
                      {dateData !== undefined && dateData[key]?.total_supplier}
                    </div>
                  </div>
                </th>
              );
            })}
          </tr>
        </tbody>
      </Table>}
    </div>
  );
};

export default EventScheduleWeek;
