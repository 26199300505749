import { showToast } from "../../utils";

interface UploadAndDisplayImageProps {
  images: Array<any>;
  onChange: (data: any) => void;
  onRemove: (idx: any , id?:any) => void;
  size?: any;
}

const UploadAndDisplayImage = (props: UploadAndDisplayImageProps) => {

  const fileSize = props?.size || 10
  const extensions = [".jpg", ".jpeg", ".png", ".JPG", ".JPEG", ".PNG"];
  
  const selectMultipleFile = (evt: any) => {
    var imgCount = 0, propsImgCount = 0 , videoCount = 0, propsVideoCount = 0;
    var file = evt.target.files
    if(props?.images?.length>0){
      props?.images?.map((item: any, idx: number) => (  
      extensions.some(extension => item?.image?.includes(extension)) ? propsImgCount++ : propsVideoCount++))
    }
    for (var j = 0; j < file.length; j++)
    {
      if ((file[j].type.split('/')[0]) === 'image') {
        imgCount++;
      }
      else if((file[j].type.split('/')[0]) === 'video') {
        videoCount++;
      }
    }
    if (imgCount +propsImgCount > 50) {
      showToast('Max allowed photos are 50', 'error');
      return
    }
    else if (videoCount + propsVideoCount> 10){
      showToast('Max allowed video are 10', 'error');
      return
    }

    const data: any = []
    var  acceptType = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG", "gif", "GIF", "mp4", "m4v", "mpg", "x-aiff", "video", "video/mp4", "video/x-msvideo"];
    for (var i = 0; i < file.length; i++) {
      if (acceptType.indexOf(file[i].type.split('/')[1]) !== -1) {
        if (((file[i].size / 1000) / 1000).toFixed(4) <= fileSize) {
          const img = URL.createObjectURL(file[i])
          data.push({ image: img, file: file[i] })
        }
        else {
          //show error msg if file size in more than 10 mb
          showToast('file size in more than 10 mb not supported', 'error')
          document.getElementById("fileuplaod")!.innerHTML = "";
        }
      }
      else {
        //show error msg if file type is supported
        showToast('File type is not supported', 'error')
        document.getElementById("fileuplaod")!.innerHTML = "";
      }
    }
    props.onChange(data)
  }
  return (
    <div>
      <label htmlFor="fileuplaod">
        <img src={"/assets/images/circle-black-plus-icon.svg"} alt="" />
        <input type="file" multiple accept="image/*,video/*" onChange={(e)=>selectMultipleFile(e)} id="fileuplaod" />
      </label>
      {props?.images?.length > 0 &&
        <div className="row">
          <div className="col-md-12">
            <div className="show-img-setup d-flex flex-wrap gap-2 mt-3">
              {props?.images?.map((item: any, idx: number) => (  
                <div key={idx} className="cataloguppedelet">
                  <p>{item?.type?.split('/')[0]}</p>
                  {item?.file?.type?.split('/')[0] === 'image' || extensions.some(extension => item?.image?.includes(extension))? <img src={item?.image} alt="not fount" className="box-img" width={"100px"} /> : 
                  <video width="50%" 
                height="50%"
                src={item?.image}
                className="box-video"
                >
                </video> }
                  <img className="deleticon" src="/assets/images/delete_profile_white.svg" alt="" onClick={() => props.onRemove(idx, item?.id)} />
                </div> 
              ))}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default UploadAndDisplayImage;
