import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import React, { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

interface ViewPdfProps {
    onClose: () => void;
    file: any
}

const ViewPdf: React.FC<ViewPdfProps> = (props) => {

    const [allPageNumbers, setAllPageNumbers] = useState<number[]>()

    const onLoadSuccess = (pdf: PDFDocumentProxy) => {

        const allPageNumbers: number[] = []; // array of numbers
        for (let p = 1; p < pdf.numPages + 1; p++) {
            allPageNumbers.push(p);
        }
        setAllPageNumbers(allPageNumbers);
    }

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, [])

    return <div className="message-image-popup-bg">
        <div className="top-img d-flex justify-content-between align-items-center mb-5">
            <img src="/assets/images/white-arrow-left-side.svg" onClick={props.onClose} alt="" />
            <a href={props?.file} ><img src="/assets/images/arrow-download-white.svg" alt="" /></a>
        </div>
        <div
            style={{
                border: 'none',
                display: 'flex',
                width: '100%',
                paddingLeft: '30%',
                maxHeight: '100%',
            }}>
            <Document
                file={props?.file} // example PDF file
                onLoadSuccess={onLoadSuccess}>
                <div
                    style={{
                        maxHeight: '80%', // needed for scroll
                        overflowY: 'scroll', // yes vertical scroll
                        overflowX: 'hidden', // no horizontal scroll
                    }}>
                    {allPageNumbers?.length as never > 0 ? allPageNumbers?.map((pn) => (<Page
                        key={`page-${pn}`}
                        pageNumber={pn}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                    />)) : undefined}
                </div>
            </Document>
        </div>
    </div>
}

export default ViewPdf