import moment from 'moment';
import React from 'react'
import { ROLE_TYPE } from '../../types';
import Logo from './ProfileImage';
import { DateUtils } from '../../utils/dateUtils';

interface ContactTileProps {
  contact: any;
  onClick: () => void;
}
export const ContactTile = (props:ContactTileProps) => {
  return (
    <div className='message-chat-box' key={props?.contact._id} onClick={props?.onClick}>
      <p className='mb-0 text-end time-date'>{props?.contact?.last_message_on ? DateUtils.getTime(props?.contact?.last_message_on) : props?.contact.created_at ? DateUtils.getTime(props?.contact.created_at) : ''}</p>
        <div className='d-flex align-items-center justify-content-between mt-n1'>
          <div className='message-box-left align-items-center d-flex gap-3'>
            <div className='message-box-logo'>
              {props?.contact?.other_user?.supplier_profile?.company_logo || props?.contact?.other_user?.owner_profile?.profile_image || props?.contact?.other_user?.profile_image ? <img src={props?.contact?.other_user?.supplier_profile?.company_logo || props?.contact?.other_user?.owner_profile?.profile_image || props?.contact?.other_user?.profile_image } alt="" />
              : <Logo style={{ width: '49px', height: '49px'}} data={props?.contact?.other_user?.supplier_profile?.company_name?.[0] || props?.contact?.other_user?.first_name?.[0] || props?.contact?.other_user?.last_name?.[0]}/> }
            </div>
          <div className='heading-data'>
          <h3>{props?.contact?.other_user?.user_type === ROLE_TYPE.TYPE_SUPPLIER ? props?.contact?.other_user?.supplier_profile?.company_name  : props?.contact?.other_user?.first_name + " " + props?.contact?.other_user?.last_name}</h3>
          <p className='last-message'>{props?.contact?.attachment_type ? `Sent ${props?.contact?.attachment_type}` : props?.contact?.last_message}</p>
        </div>
      </div>
        {props?.contact?.unread_messages > 0 ?
        <p className='mb-0 unread-message-number'>{props?.contact?.unread_messages}</p> : ""}
      </div>
  </div>
  )
}
