/* eslint-disable import/no-anonymous-default-export */
const apiVersion = process.env.REACT_APP_API_VERSION
const chatApiVersion = process.env.REACT_APP_CHAT_API_VERSION
export const endPoints = {
    API_BASE_URL: "https://example.com/api/main/",

    //OUR DATABASE API
    LOGIN: `${apiVersion}login/`,
    PP: `${apiVersion}privacy-policy/`,
    TERMS: `${apiVersion}terms/`,
    CHECKEMAIL: `${apiVersion}check-email/`,
    PASSWORD: `${apiVersion}password/`,
    SIGNUP: `${apiVersion}register/`,
    LOGOUT: `${apiVersion}device-token/`,
    VERIFYEMAIL: `${apiVersion}email-verify/`,
    UPDATEPROFILE: `${apiVersion}profile/`,
    PAYMENTDATA: `${apiVersion}payment-methods/`,
    SUBSCRIPTION: `${apiVersion}subscription/`,
    PROFILE: `${apiVersion}profile/`,
    CREWLIST: `${apiVersion}users-list/`,
    USERLIST: `${apiVersion}users-list/`,
    JOBLIST: `${apiVersion}job/`,
    USER: `${apiVersion}user/`,
    BROADCAST: `${apiVersion}broadcast/`,
    JOB_REQUEST: `${apiVersion}job-request/`,
    CREATE_USER: `${apiVersion}create-user/`,
    COMPANY_INVITE: `${apiVersion}company-invite/`,
    USER_NOTIFICATION: `${apiVersion}user-notifications/`,
    JOB_INVITE: `${apiVersion}job-invite/`,
    SCHEDULE: `${apiVersion}schedule/`,
    USER_SCHEDULE: `${apiVersion}user-schedule/`,
    EVENT_EXPORT: `${apiVersion}event-export/`,
    DEVICE_TOKEN: `${apiVersion}device-token/`,
    ADMIN_CONTACT: `${apiVersion}admin-contact/`,
    FLAG_USER: `${apiVersion}flag/`,
    DELETE_MESSAGE: `${chatApiVersion}delete-chat/`,
    FAQ: `${apiVersion}frequently-asked-question/`,
    TRANSACTION_HISTORY: `${apiVersion}transaction/`,
    VERIFY_OTP: `${apiVersion}otp/`,
    DOWNLOAD_TEMPLATE: `${apiVersion}bulk-import/`,
    ROOM_LIST: `${chatApiVersion}rooms/`,
    chat_messages_url: `${chatApiVersion}messages/`,
    accept_chat_invite_url: `${chatApiVersion}accept-invite/`,
    ATTACHEMENT_URL: `${chatApiVersion}attachment/`,
    SUPPLIER_MEDIA: `${apiVersion}supplier-media/`,
    EVENT: `${apiVersion}event/`,
    CREATE_EVENT: `${apiVersion}event/`,
    NOTE: `${apiVersion}note/`,
    MEDIA: `${apiVersion}media/`,
    CREATE_EVENT_ASSIGNEE: `${apiVersion}event-request/`,
    ACCEPT_EVENT: `${apiVersion}event-request/`,
    USER_PRODUCTION: `${apiVersion}user-production/`,
    SETUP_INTENT: `${apiVersion}setup-intent/`,
};
