import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import { BUTTON_TYPE } from '../../types'
import Button from "../../global/ui/Button";
import InputField from '../../lib/input';
import { FormProvider, useForm } from 'react-hook-form';
import { verifyPin } from '../../data/formData';
import { apiConstants } from '../../utils/apiConstants';
import { makeGetCall, makePostCall } from '../../effects/requests';
import { endPoints } from '../../utils/endpoints';
import { showToast } from '../../utils';
import { labels, placeholder } from '../../utils/constants';
import React from 'react';
import { PIN_VALIDATION, REQUIRED_VALIDATION } from '../../lib/input/validation-rules';
import { useEffect } from 'react';

interface VerifyPhoneNumber {
    show: boolean;
    closePopup: () => void;
}
const VerifyPhoneNumber: React.FC<VerifyPhoneNumber> = (props) => {

    const { ...methods } = useForm({
        mode: 'all',
        defaultValues: { ...verifyPin() },
        reValidateMode: 'onBlur',
    });
    useEffect(() => {
        if (props.show) {
          methods.reset()
        }
      }, [props.show])
    const verifyOtp = (values: any) => {
        const apiPayload = { ...values }
        makeGetCall(endPoints.VERIFY_OTP)
            .then((res) => {
                makePostCall(endPoints.VERIFY_OTP, {
                    otp: apiPayload[apiConstants.pin],
                    token: res?.data?.token
                })
                    .then((res) => {
                        if (res?.status.code === 200) {
                            showToast(res?.status?.message, "success", 23)
                            props.closePopup()
                        }
                    })
                    .catch((err) => {
                        showToast("Mobile number not verified", "error", 25)
                    })
            })
            .catch((err) => {

            })
    }
    return (
        <Modal
            centered
            scrollable
            className='exportdata updaterole'
            show={props.show}
            onHide={props.closePopup}>
            <ModalHeader className="p-0 border-0 mb-3">
                <button className="close-btn-de shadow-none" onClick={props.closePopup}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
                <ModalTitle className="fw-bold me-auto ps-3">Verify Mobile Number</ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-start overflow-hidden">
                <FormProvider {...methods}>
                    <p className="mb-5 text-start">We have sent you a verification code to your new mobile number</p>
                    <div className='ms-5 ps-4 '>
                        <InputField type={'password'} name={apiConstants.pin} label={labels.verify_pin} placeholder={placeholder.verify_pin}
                            className="black-input right-icon-center"
                            rules={PIN_VALIDATION} />
                        <Button
                            type={BUTTON_TYPE.TYPE_BUTTON}
                            btnTxt='Continue'
                            style={{ marginTop: "0px", width: "auto" }}
                            onClick={methods.handleSubmit(verifyOtp)}
                            className={'btn btn-warning w-100 with-after-shadow mb-3  shadow-none'}
                        />
                    </div>
                </FormProvider>
            </ModalBody>

        </Modal>
    )
}

export default VerifyPhoneNumber;
