import React from 'react'

const GlobalLoader: React.FC = () => {

    const loaderStyle: React.CSSProperties = {
        position: 'fixed',
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
    }

    return (
        <div style={loaderStyle} id="loader">
            <img style={{ width: '100px', height: 'auto' }} src='/assets/images/spinner.gif' alt='loader' />
        </div>
    )
}

export default GlobalLoader