import React from 'react'
import { labels } from '../../utils/constants';
import ImageCrop from './ImageCrop';

interface UserSnapProps {
    value: any;
    onChange: (value: any) => void;
    label?: any;
    showImg?: boolean
}

const UserSnap: React.FC<UserSnapProps> = (props) => {
    return (
        <div className="upload-image">
            <ImageCrop
                ratio={1 / 1}
                croppedImage={(imgSrc: any, file: any) => props.onChange(imgSrc)}
                size={15}
            />
            <label htmlFor="image_id" className="update-image">
                <span className="img-wrapper">
                    <img
                        alt=""
                        className="image-show bussiness-logo"
                        src={props.value || "/assets/images/upload-image.svg"}
                    />
                    {!props.value &&
                        <>
                            {props?.showImg
                                ?
                                <p className="add-contact-logo"><img src={"/assets/images/add-logo.svg"} alt="" /></p>
                                :
                                <p className="add-logo">{props.label || labels.addLogo}</p>

                            }
                        </>
                    }
                    <div
                        onClick={(evt) => {
                            if (props.value) {
                                evt.preventDefault();
                                props.onChange('')
                                return
                            }
                        }}
                        style={{ backgroundColor: props.value ? 'red' : '' }}
                        className="upload-icon"
                    >
                        <img src={props.value ? "/assets/images/delete_profile_white.svg" : "/assets/images/plus-icon.svg"} alt="" />
                    </div>
                </span>
            </label>
        </div>
    )
}

export default UserSnap