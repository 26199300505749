import React, { useContext, useEffect, useState } from "react";
import AuthSection from "../../global/layout/authSection";
import Stripe from "../../components/stripe";
import { currencyData } from "../../data/data";
import { makeGetCall, makePatchCall, makePostCall } from "../../effects/requests";
import Button from "../../global/ui/Button";
import { ApiResponseType, BUTTON_TYPE, CURRENCY_TYPE, INTERVAL_TYPE, ROLE_TYPE } from "../../types";
import { showToast } from "../../utils";
import { buttonsText, cookieServiceParam, errorMessage } from "../../utils/constants";
import CurrencyItem from "./CurrencyItem";
import SubscriptionItem from "./SubscriptionItem";
import { cookieService } from '../../services';
import { useNavigate } from "react-router-dom";
import NoData from "../../global/ui/NoData";
import { endPoints } from "../../utils/endpoints";
import { apiConstants } from "../../utils/apiConstants";
import ConfirmSubscription from "../../components/modal/confirmSubscription";
import { MyContext } from "../../context";

const Subscription: React.FC = () => {

    const naviagte = useNavigate();
    const { updateSelectedSubscription } = useContext(MyContext)
    const [isShowCardModal, setIsShowCardModal] = useState(false);
    const [subscriptionListData, setSubscriptionListData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_TYPE.TYPE_GBP);
    const [selectedSubs, setSelectedSubs] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [isShow, setIsShow] = useState(false);
    //const userType = cookieService.getCookie(cookieServiceParam.user)?.[apiConstants.user_type] || ROLE_TYPE.TYPE_SUPPLIER
    const userType = cookieService.getCookie(cookieServiceParam.userType)
    const getSubsData = () => {
        setIsLoading(true)
        makeGetCall(endPoints.SUBSCRIPTION).then((res: ApiResponseType<any>) => {
            setIsLoading(false)
            if (res.status?.code == 200) {
                const filterArr = filterListAsPerCurrency(res.data?.data?.length > 0 ? res.data?.data : [], selectedCurrency);
                setAllData(res.data?.data)
                setSubscriptionListData(filterArr as never)

                // //pre select first element by default
                // if (filterArr?.length > 0) {
                //     const supFilterArr = filterArr.filter((item: any) => item?.recurring?.interval != INTERVAL_TYPE.TYPE_WEEK);
                //     setSelectedSubs(supFilterArr?.length > 0 ? supFilterArr?.[0] : filterArr?.[0])
                // }
            }
        }).catch((err) => setIsLoading(false))
    }

    const filterListAsPerCurrency = (data: Array<any>, currentCurrency: any) => data.filter((item) => item?.currency === currentCurrency)

    useEffect(getSubsData, []);

    const updatePlan = (paymentId: any) => {
        makePostCall(endPoints.SUBSCRIPTION, { price_id: selectedSubs?.id, payment_id: paymentId }, true).then((res: ApiResponseType<any>) => {
            if (res.status?.code == 200) {
                //const urls = userType === ROLE_TYPE.TYPE_SUPPLIER ? '/manage-profile/supplier/add/1' : '/manage-profile/owner/add/1'
                //naviagte(urls);
                //naviagte('/schedule')
                setIsShow(true);
            }
        })
    }

    const updateUserData = (paymentId: any) => {
        setIsShowCardModal(false);
        makePatchCall(endPoints.UPDATEPROFILE, {
            subscription_tier: selectedSubs?.recurring?.interval?.substring(0, 1)?.toUpperCase(),
            subscription_currency: selectedSubs?.currency?.toUpperCase()
        }).then((res: ApiResponseType<any>) => {
            if (res.status?.code == 200) {
                updatePlan(paymentId)
            }
        })
    }

    const getSymbol = () => {
        let sym = '£';
        if (selectedCurrency === CURRENCY_TYPE.TYPE_EUR) {
            sym = '€'
        }
        if (selectedCurrency === CURRENCY_TYPE.TYPE_USD) {
            sym = '$'
        }
        return sym;
    }

    return (
        <AuthSection>
            <div>
                <div className='inner-content text-center ms-auto'>
                    <div className='top-content'>
                        <h3>Subscription Package</h3>
                        <p>Choose your preferred currency</p>
                    </div>
                    <form>
                        <div className='slect-radio-like-tab dark-mode ms-auto'>
                            {currencyData.map((item, index) => <CurrencyItem
                                key={index}
                                item={item}
                                selectedCurrency={selectedCurrency}
                                onClick={(value: any) => {
                                    const temp = filterListAsPerCurrency(allData, value);
                                    setSubscriptionListData(temp as never);
                                    setSelectedCurrency(value);
                                    setSelectedSubs(null)

                                    // pre select first element by default
                                    // if (temp?.length > 0 && index === 0) {
                                    //     const supFilterArr = temp.filter((item: any) => item?.recurring?.interval != INTERVAL_TYPE.TYPE_WEEK);
                                    //     setSelectedSubs(supFilterArr?.length > 0 ? supFilterArr?.[0] : temp?.[0])
                                    // } else {
                                    //     setSelectedSubs(null)
                                    // }
                                }}
                            />)}
                        </div>
                        <div className='full-radio-buttons mb-5 pb-3'>
                            {subscriptionListData?.length > 0 ?
                                subscriptionListData.map((item: any, index) => {
                                    if (userType === ROLE_TYPE.TYPE_SUPPLIER && item?.recurring?.interval !== INTERVAL_TYPE.TYPE_YEAR) return null
                                    if (userType === ROLE_TYPE.TYPE_CREW && item?.recurring?.interval !== INTERVAL_TYPE.TYPE_WEEK) return null
                                    //if (item?.recurring?.interval === INTERVAL_TYPE.TYPE_MONTH) return null
                                    return (
                                        <SubscriptionItem
                                            item={item}
                                            key={index}
                                            selectedId={selectedSubs?.id}
                                            onClick={(data: any) => {
                                                // updateSelectedSubscription(data);
                                                cookieService.saveCookie(cookieServiceParam.subscription_id, data?.id);
                                                setSelectedSubs(data)
                                            }}
                                            currencyIcon={getSymbol()}
                                            userType={userType}
                                        />
                                    )
                                })
                                :
                                <>
                                    {isLoading ? 'Loading...' : <NoData />}
                                </>
                            }
                        </div>
                        <Button
                            type={BUTTON_TYPE.TYPE_BUTTON}
                            btnTxt={buttonsText.continue}
                            style={{ marginTop: "20px", width: "100%" }}
                            onClick={() => {
                                if (!selectedSubs?.id) {
                                    showToast(errorMessage.subs_not_selected, 'error');
                                    return
                                }
                                if (userType === ROLE_TYPE.TYPE_CREW) {
                                    naviagte(`/manage-profile/owner/add/1`)
                                    return
                                }
                                if (userType === ROLE_TYPE.TYPE_SUPPLIER) {
                                    naviagte(`/manage-profile/supplier/add/1`)
                                    return
                                }
                                //setIsShowCardModal(true)
                                //setIsShow(true)   -> For free subscription case
                            }}
                            className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                        />
                    </form>
                </div>
                {/* <Stripe
                    isShow={isShowCardModal}
                    onClose={() => setIsShowCardModal(false)}
                    //onDone={updateUserData}
                /> */}
                <ConfirmSubscription isShow={isShow} onClose={() => setIsShow(false)} />
            </div>
        </AuthSection>
    )
}
export default Subscription;