import SelectedMemberItem from './SelectedMemberItem'

import React from 'react';
import moment from 'moment';
import { endPoints } from '../../utils/endpoints';
import { makePatchCall } from '../../effects/requests';
import { showToast } from '../../utils';


const SelectedMembers = (props: any) => {

  const updateObject = (editUserId: any, updateValue: any, item: any, idx:any) => {

    if (item?.event_created_already ) {
      if(updateValue.start_time<updateValue.end_time){
      var param = {
        //event_id: props?.editEventId,
        //assigned_member: item?.id,
        start_time: props?.date + " " + moment(updateValue.start_time, 'h:mm A').format('HH:mm'),
        end_time: props?.date + " " + moment(updateValue.end_time, 'h:mm A').format('HH:mm')
      }
      makePatchCall(`${endPoints.CREATE_EVENT_ASSIGNEE}${item.id}/`, param)
        .then((res) => {
          showToast("Time updated successfully", "success")
          props?.timeupdate(updateValue, idx)
        })
        .catch((err) => {

        })
      }
    }
    else {
      props.setSelectedList((prevArray: any) => prevArray.map((obj: any) => {
        if (obj.id === editUserId) {
          return { ...obj, ...updateValue };
        }
        return obj;
      }));
    }
  };
  const renderList = props?.tempData.map((item: any, idx:any) => {
    return <SelectedMemberItem item={item} updateObject={(editUserId: any, updateValue: any, item: any) => updateObject(editUserId, updateValue,item, idx)} key={item.id} deleteMember={props.deleteMember} data={props?.data}
      startTime={item?.start_time} endTime={item?.end_time}
      date={props?.date}
      editEvent={props?.editEvent}
      editEventId={props?.editEventId}
      selectedTab={props?.selectedTab}
      getScheduleData={props?.getScheduleData}
    />

  })

  return (
    <div>
      {renderList}
    </div>
  );
}

export default SelectedMembers

