import moment from "moment"
import { useEffect, useState } from "react"
import { makeGetCall } from "../../effects/requests"
import NoData from "../../global/ui/NoData"
import { ApiResponseType, CURRENCY_TYPE } from "../../types"
import { labels } from "../../utils/constants"
import { endPoints } from "../../utils/endpoints"

const TransactionHistory = () => {

  const [allData, setAllData] = useState([])

  const fetchTransactionHistory = () => {
    makeGetCall(endPoints.TRANSACTION_HISTORY).then((res: ApiResponseType<any>) => {
      setAllData(res?.data || [])
    })
  }

  useEffect(fetchTransactionHistory, [])

  return (
    <div className="tab-pane active" id="payment_info">
      {allData?.length > 0
        ?
        <div className='transaction-history d-grid gap-4'>
          {allData.map((item: any, idx) => {
            return (
              <div key={idx} className='transaction-history-design d-flex justify-content-between align-items-center gap-3'>
                <div className='text-left'>
                  <h5 className='m-0'>{`Transaction #${item?.id }`}</h5>
                  <p className='mb-0'>{`${moment(new Date(item?.created * 1000)).format('Do MMMM YYYY - HH:MM')}`}</p>
                </div>
                <h6 className='m-0'><b>{`${item?.currency === CURRENCY_TYPE.TYPE_GBP ? '£' : item?.currency === CURRENCY_TYPE.TYPE_EUR ? '€' : '$'}${item?.amount/100 || ''}`}</b></h6>
              </div>
            )
          })}
        </div>
        :
        <NoData message='Transaction history data not found!' />
      }
    </div>
  )
}
export default TransactionHistory;