import React, { useState } from "react";
import { DateUtils } from "../../utils/dateUtils";
import MutipleEvent from "./MultipleEvent";
import MultipleEvent from "./MultipleEvent";

interface TableHeaderDataProps {
  data: Array<any>;
  impEventData?: any;
  editFlow:any;
  onAdd:any;
  onEventView:any;
}

const TableHeaderData: React.FC<TableHeaderDataProps> = (props) => {

    // console.log('Header', props?.impEventData, DateUtils.formatDateTime( props?.impEventData[0]?.start_datetime,"DD"))
  return (
    <thead>
      <tr>
        <th style={{ width: "10%", minWidth: "187px"}}></th>
        {props.data?.map((item, idx) => {
          const headerDate = `${DateUtils.formatDateTime( item,"DD")}`
          return (
            <th key={idx} style={{ width: "12%", minWidth: "162px" ,verticalAlign: 'baseline'}}>
              <div className="top-part-collender d-flex justify-content-between align-items-center">
                <div className="d-grid ">
                  <p className="mb-0">{`${DateUtils.formatDateTime(
                    item,
                    "ddd"
                  )}`}</p>
                  <h6 className="mb-0">{headerDate}</h6>
                </div>
                {/* not in current scope */}
                {/* <img src="/assets/images/notebook-add.svg" className='right-sidtop' alt="" /> */}
              </div>
              {props?.impEventData && props?.impEventData.map((eventData:any,idx:any) => {
                return <div>{`${DateUtils.formatDateTime( eventData.start_datetime,"DD")}`=== headerDate && 
                <div className="imp-event" style={{height: '60px' }}>
                  <div className="outer">
                    <div className="event-name" onClick={() => props.onEventView(eventData?.id )} >{eventData?.name}</div>
                    <img src="/assets/images/schedule-white.svg" alt="" onClick={()=> props?.onAdd(eventData?.id)}/>
                  </div>
                  <div className="event-address" onClick={() => props.onEventView(eventData?.id )}>
                  {eventData?.address}
                  </div>
                </div>}
              </div> })}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeaderData;
