import Button from '../../global/ui/Button';
import AuthSection from '../../global/layout/authSection';
import { labels, pageHeading, placeholder } from '../../utils/constants';
import { ApiResponseType, BUTTON_TYPE } from '../../types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { apiConstants } from '../../utils/apiConstants';
import InputField from '../../lib/input';
import { EMAIL_VALIDATION } from '../../lib/input/validation-rules';
import { checkEmailFormData } from '../../data/formData';
import { makeGetCall } from '../../effects/requests';
import React from 'react';
import { showToast } from '../../utils';
import { endPoints } from '../../utils/endpoints';

const ForgotPassword: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const { ...methods } = useForm({
    mode: 'all',
    defaultValues: { ...checkEmailFormData({ email: location?.state?.email || '' }) },
    reValidateMode: 'onBlur',
  });

  const onSubmit: SubmitHandler<any> = (values: any) => {
    const params = {
      [apiConstants.email]: values?.[apiConstants.email]
    }
    makeGetCall(endPoints.PASSWORD, params, true).then((res: ApiResponseType<any>) => {
      navigate('/sign-in');
    }).catch((err) => {
      showToast("Email didn't exist", "error");
    })
  };

  return (
    <AuthSection>
      <div className='inner-content signup-page text-center ms-auto'>
        <div className='top-content'>
          <img src={"/assets/images/reset-password.svg"} alt="" />
          <h3>{pageHeading.forgotPassword}</h3>
          <p>{pageHeading.forgotPasswordContent}</p>
        </div>
        <FormProvider {...methods} >
          <form className='text-start' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <InputField
              label={labels.email}
              placeholder={placeholder.email}
              name={apiConstants.email}
              type='email'
              rules={EMAIL_VALIDATION}
              containerStyle={{ marginTop: '25px', }}
            />
            <Button
              type={BUTTON_TYPE.TYPE_SUBMIT}
              btnTxt='Reset Password'
              style={{ marginTop: "80px", width: "100%" }}
              className={'btn btn-warning w-100 shadow-none'}
              disabled={!methods?.formState?.isValid}
            />
          </form>
        </FormProvider>
      </div>
    </AuthSection>
  )
}
export default ForgotPassword;