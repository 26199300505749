import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap"
import { FormProvider } from "react-hook-form";
import Button from "../../global/ui/Button";
import InputField from "../../lib/input";
import { REQUIRED_VALIDATION } from "../../lib/input/validation-rules";
import { BUTTON_TYPE } from "../../types";
import { buttonsText } from "../../utils/constants";

interface ActionableModalProps {
    isShow: boolean;
    onClose?: () => void;
    closeIcon?: any;
    onDone: (data: any) => void;
    btnText?: string | null;
    config?: any
    name: any;
    label?: any;
    placeholder?: any;
    title?: string | null
}

const ActionableModal = (props: ActionableModalProps) => {

    return (
        <Modal
            centered
            scrollable
            show={props.isShow}
            onHide={props?.onClose}>
            <ModalHeader className="p-0 border-0">
                <ModalTitle className="fw-bold">{props?.title}</ModalTitle>
                <button className="close-btn-de shadow-none" onClick={props?.onClose}><img src={props?.closeIcon || "/assets/images/dismiss-cross-black.svg"} alt="" /></button>
            </ModalHeader>
            <FormProvider {...props?.config} >
                <form className='text-start' onSubmit={props?.config?.handleSubmit(props.onDone)} noValidate>
                    <ModalBody className="p-0">
                        <InputField
                            label={props?.label}
                            placeholder={props?.placeholder}
                            name={props?.name}
                            type='text'
                            rules={REQUIRED_VALIDATION(props?.label)}
                            className="mb-5 black-input"
                            maxLength={200}
                        />
                    </ModalBody>
                    <ModalFooter className="p-0 border-0">
                        <Button
                            type={BUTTON_TYPE.TYPE_SUBMIT}
                            btnTxt={props?.btnText || buttonsText.saveAndAdd}
                            style={{ marginTop: "0px", width: "auto" }}
                            className={'btn btn-warning w-100 with-after-shadow shadow-none'}
                        />
                    </ModalFooter>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default ActionableModal