import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./stripeCustom.css";
import AddNewCardDetails from "./addNewCardDetails";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as never);

interface Props {
  isShow: boolean;
  onClose: () => void;
  onDone?: (paymentId?: any) => void;
  onSubscribe: () => void;
  redirectType?: any
}

const Stripe = (props: Props) => (
  <Elements stripe={stripePromise} >
    <AddNewCardDetails {...props} />
  </Elements>
);

export default Stripe;