import { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { makeGetCall, makeNextPageCall } from '../../effects/requests'
import NoData from '../../global/ui/NoData'
import { ApiResponseType } from '../../types'
import { endPoints } from '../../utils/endpoints'

const Faq = (props: any) => {

  const [faqList, setFaqList] = useState([])
  const [nextUrl, setNextUrl] = useState(null)

  const getFaq = () => {
    makeGetCall(endPoints.FAQ).then((res: ApiResponseType<any>) => {
      setFaqList(res.results);
      setNextUrl(res?.next || null);
    })
  }

  useEffect(() => {
    if (props?.show) {
      getFaq()
    }
  }, [props?.show]);

  const fetchMoreData = () => {
    if (!nextUrl) return;
    makeNextPageCall(nextUrl).then((res: ApiResponseType<any>) => {
      const data = [...faqList, ...res.results];
      setNextUrl(res?.next);
      setFaqList(data as never);
    })
  }

  return (
    <Modal
      centered
      scrollable
      className='updaterole modal-corner-spacing'
      show={props.show}
      onHide={props.closePopup}>
      <ModalHeader className="p-0 pb-5 mb-2 border-0 mb-0 align-items-center">
        <button className="close-btn-de shadow-none" onClick={props.closePopup}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
        <ModalTitle className="fw-bold me-auto ps-3 text-start">
          <h4 className='h4 text-start mb-0 mt-0'>Frequently Asked Questions</h4>
        </ModalTitle>
      </ModalHeader>
      <ModalBody id='scrollableDiv' className="p-0 text-start">
        {faqList?.length > 0
          ?
          <div className='accordion-colleps' id="accordion">
            <InfiniteScroll
              dataLength={faqList?.length}
              next={fetchMoreData}
              hasMore={nextUrl ? true : false}
              loader={null}
              scrollableTarget="scrollableDiv"
            >
              {faqList?.map((item: any, idx) => {
                return (
                  <div key={idx} className="card border-0">
                    <div className="card-header bg-transparent p-0">
                      <a className="btn bg-transparent shadow-none w-100 d-flex justify-content-between align-items-center" data-bs-toggle="collapse" aria-expanded="true" href={`#collapse${idx + 1}`}>
                        {item?.question || ''}
                        <img src="/assets/images/play-icon-dark.svg" alt="" />
                      </a>
                    </div>
                    <div id={`collapse${idx + 1}`} className="collapse w-100 show" 
                     data-bs-parent="#accordion"
                    >
                      <div className="card-body" dangerouslySetInnerHTML={{ __html: item?.answer?.replace(/\n/g, "<br/>") || '' }} />
                        {/* {item?.answer || ''} */}
                    </div>
                  </div>
                )
              })}
            </InfiniteScroll>
          </div>
          :
          <NoData
            message='Data Not Found'
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default Faq;
