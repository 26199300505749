import React from 'react'
import { ModalBody, ModalFooter } from 'react-bootstrap'
import { FormProvider } from 'react-hook-form';
import Button from '../../../global/ui/Button';
import { BUTTON_TYPE } from '../../../types';
import InputField from '../../../lib/input';
import { apiConstants } from '../../../utils/apiConstants';
import { REQUIRED_VALIDATION } from '../../../lib/input/validation-rules';

interface AddBroadCastMsgProps {
    formData: any;
    onSubmit: (values: any) => void;
    activeStep: Number;
    isLoading: boolean
}

const AddBroadCastMsg: React.FC<AddBroadCastMsgProps> = (props) => {

    const { formData, isLoading } = props;

    return (
        <FormProvider {...formData} >
            <form className='text-start' onSubmit={formData.handleSubmit(props.onSubmit)} noValidate>
                <ModalBody className="p-0 text-start pb-0">
                    <div className="form_group black-input mb-4">
                        <div className="input_box">
                            <label htmlFor="supplier_bio">Description</label>
                            <InputField
                                isTextArea
                                label='Description'
                                placeholder='Type message'
                                name={apiConstants.message}
                                type='text'
                                rules={REQUIRED_VALIDATION('Message')}
                                className="no-resize"
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="p-0 border-0 row align-items-center" style={{ marginTop: '45px' }}>
                    <div className='col-4  m-0'>
                        <p className='mb-0'>{`${props.activeStep} of 2`}</p>
                    </div>
                    <div className='col-8 m-0'>
                        <Button
                            type={BUTTON_TYPE.TYPE_SUBMIT}
                            btnTxt={'Send Broadcast'}
                            style={{ marginTop: "0px", width: "auto" }}
                            className={'btn btn-warning w-100 with-after-shadow shadow-none m-0'}
                            isLoading={isLoading}
                        />
                    </div>
                </ModalFooter>
            </form>
        </FormProvider>
    )
}

export default AddBroadCastMsg