import React from 'react'
import { DateUtils } from '../../utils/dateUtils';

interface IDayItems {
    data: any;
    onView: () => void;
}

const DayItems: React.FC<IDayItems> = (props) => {
    return (
        <div className='event-day-tdbody' onClick={props.onView} style={{ cursor: 'pointer' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h6 style={{ fontWeight: '700', marginTop: '15px' }}>{props.data?.name || ''}</h6>
                {props.data?.meeting_link && <a href={props.data?.meeting_link} target='_blank' className='join-button'>Join</a>}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'flex-end' }}>
                <div>
                    <p style={{ marginBottom: '0' }}>{`${DateUtils.formatDateTime(props.data?.start_datetime, 'HH:mm')} - ${DateUtils.formatDateTime(props.data?.end_datetime, 'HH:mm')}`}</p>
                    <p>{props.data?.address || ''}</p>
                </div>
                <div style={{ padding: '5px  5px ' }}>
                    <div style={{ padding: '5px 3px', display: 'flex', justifyContent: 'space-between', gap: '30px' }}>
                        <div >
                            <img src="/assets/images/crew-member.svg" style={{ width: '30px', height: '30px' }} alt="" />
                        </div>
                        <div style={{ textDecoration: 'underline' }}>
                            <img src="/assets/images/schedule-profile.png" style={{ width: '30px', height: '30px' }} alt="" />
                            {`${props.data?.total_members?.total_crew} Crew Assigned`}
                        </div>
                        <div><img src="/assets/images/play-icon-dark.svg" /></div>
                    </div>
                    <div style={{ padding: '5px 3px', display: 'flex', justifyContent: 'space-between', gap: '30px' }}>
                        <div>
                            <img src="/assets/images/supplier-mem.svg" style={{ width: '30px', height: '30px' }} alt="" />
                        </div>
                        <div style={{ textDecoration: 'underline' }}>
                            <img src="/assets/images/schedule-profile.png" style={{ width: '30px', height: '30px' }} alt="" />
                            {`${props.data?.total_members?.total_supplier} Suppliers Assigned`}
                        </div>
                        <div><img src="/assets/images/play-icon-dark.svg" /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DayItems