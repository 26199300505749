import React, { useEffect, useState } from 'react'
import useDebounce from '../../hooks/useDebounce';

interface SearchProps {
    placeholder?: string;
    value: string | any;
    onChange: (value: any) => void;
    onClear?: () => void;
    debounceInterval?: any;
    imgStyle?: React.CSSProperties;
    id?: any
}

const Search: React.FC<SearchProps> = (props) => {

    const [inputValue, setInputValue] = useState<any>(props?.value || '');

    const debouncedValue = useDebounce(inputValue, props?.debounceInterval || 800)

    useEffect(() => {
        props.onChange(debouncedValue)
    }, [debouncedValue])
    useEffect(()=> {
        setInputValue(props.value || '')
    },[props?.value])
    return (
        <div className="input-group custom-search-width">
            <input
                id={props?.id || 'search'}
                type="text"
                className="form-control border-0 shadow-none"
                placeholder={props?.placeholder}
                value={inputValue || ''}
                onChange={(evt) => { setInputValue(evt.target.value) }}
            />
            <span className="input-group-text bg-transparent border-0"><img style={props?.imgStyle} src={"/assets/images/search-black.svg"} alt="" /></span>
        </div>
    )
}

export default Search