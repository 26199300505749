import SideBarLayout from '../../global/layout/SideBarLayout';
import ProfileInfo from './ProfileInfo';
import Tabs from '../../global/ui/Tabs';
import { crewProfileTabs, profileTabs, supplierProfileTabs } from '../../data/data';
import queryString from 'query-string';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { apiConstants } from '../../utils/apiConstants';
import PersonalInformation from './personalInformation';
import Overtime from './overtime';
import Services from './services';
import Setting from './setting';
import Payment from './payment';
import TransactionHistory from './transactionHistory';
import { viewProfileInfoTab } from '../../utils/constants';
import { useContext, useEffect, useState } from 'react';
import { PERMISSION_TYPE, ROLE_TYPE } from '../../types';
import { MyContext } from '../../context';

const ViewProfile = (props: any) => {

  const { userData } = useContext(MyContext)
  const location = useLocation();
  const navigate = useNavigate();

  const parsed = queryString.parse(location.search);

  const [selectedIdx, setSelectedIdx] = useState(0)

  const updateUrls = (value: any) => {
    const params = parsed;
    params[apiConstants.view_type] = value;
    navigate(`/view-profile/?${createSearchParams(params as never)}`);
  }

  const renderMainView = (type: any) => {

    switch (type) {
      case viewProfileInfoTab.tab2:
        return <Overtime userData={userData} />
      case viewProfileInfoTab.tab5:
        return <Services userData={userData} />
      case viewProfileInfoTab.tab3:
        return <Setting userData={userData} />
      case viewProfileInfoTab.tab4:
        return <Payment />
      case viewProfileInfoTab.tab6:
        return <TransactionHistory />
      default:
        return <PersonalInformation userData={userData} />
    }

  }

  useEffect(() => {
    const idx = profileTabs.findIndex((item) => item === parsed?.[apiConstants.view_type]);
    if (idx > 0) {
      setSelectedIdx(idx)
    }
  }, [])


  const getTabData = (type: any, permission_type: any) => {
    switch (type) {
      case ROLE_TYPE.TYPE_SUPPLIER:
        return supplierProfileTabs;

      case ROLE_TYPE.TYPE_CREW:
        return permission_type === PERMISSION_TYPE.TYPE_PROD_OWNER ? profileTabs : crewProfileTabs;


      default:
        return profileTabs;
    }
  }

  return (
    <SideBarLayout>
      <div className="right-content">
        <div className="viewprofile-right-cont">
          <div className="card">
            <ProfileInfo userData={userData} />
            <div className="card-body p-0">
              <div className="bottom-tabs-wo">
                <Tabs
                  data={getTabData(userData?.user_type, userData?.permission_type)}
                  onClick={updateUrls}
                  selectedIdx={selectedIdx}
                />
                <div className="tab-content">
                  {renderMainView(parsed?.[apiConstants.view_type])}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideBarLayout>
  )
}
export default ViewProfile;