import { useContext, useEffect, useState } from 'react';
import BasicInfo from './basicInfo';
import Info from './info';
import CreateProfileLayout from '../../../global/layout/createProfileLayout'
import ProductServices from './productServices';
import { useNavigate, useParams } from 'react-router-dom';
import { makePatchCall } from '../../../effects/requests';
import { endPoints } from '../../../utils/endpoints';
import HeaderTab from '../../../global/ui/HearderTab';
import { supplierHeader, supplierUpdateHeader } from '../../../data/data';
import { ACTION_TYPE, ApiResponseType, USER_ACTION_TYPE } from '../../../types';
import Address from '../createProductionOwnerProfile/address';
import { MyContext } from '../../../context';
import Tabs from '../../../global/ui/Tabs';
import ProfileTabs from '../../../global/ui/ProfileTabs';
import { showToast } from '../../../utils';

import React from 'react';
import Stripe from '../../../components/stripe';
import ConfirmSubscription from '../../../components/modal/confirmSubscription';


const CreateSupplierProfile = (props: any) => {

  const { updateUserData, userData } = useContext(MyContext)
  const [editedSuccessfully, setEditedSuccessfully] = useState(false);
  const [isShowCardModal, setIsShowCardModal] = useState(false);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);


  const params = useParams();
  const navigation = useNavigate()
  const currentStep = Number(params?.step);
  const actionType = params?.type || USER_ACTION_TYPE.TYPE_ADD


  const updateUrls = (step: Number) => {
    const routeUrl = `/manage-profile/supplier/${actionType}/${step}`;
    navigation(routeUrl);
  }


  const onSubmit = (value: any, isLast: boolean) => {
    makePatchCall(endPoints.PROFILE, value).then((res: ApiResponseType<any>) => {
      if (res.status?.code == 200) {
        updateUserData(res)
        if (actionType === ACTION_TYPE.ADD) {
          if (isLast) {
            if (userData?.subscription_id) {
              navigation('/schedule')
            } else {
              setIsShowCardModal(true);
            }
          } else {
            updateUrls(currentStep + 1)
          }
        }
        else {
          showToast(res?.status?.message, "success")
          setEditedSuccessfully(true);
        }
      }
    }).catch((error) => {
      setEditedSuccessfully(false)
    })
  }

  //this method allow to render user actionable content
  const renderMainContent = (value: number | any) => {

    switch (value) {

      case 2:
        return <Info
          onSubmit={(data) => { onSubmit(data, false) }}
          userData={userData}
          actionType={actionType}
          editedSuccessfully={editedSuccessfully}
          setEditedSuccessfully={() => setEditedSuccessfully(false)}
        />

      case 3:
        return <Address
          onSubmit={(data) => { onSubmit(data, false) }}
          userData={userData}
          actionType={actionType}
        />

      case 4:
        return <ProductServices
          onSubmit={(data) => { onSubmit(data, true) }}
          userData={userData}
          actionType={actionType}
        />

      default:
        return <BasicInfo
          onSubmit={(data) => { onSubmit(data, false) }}
          userData={userData}
          actionType={actionType}
          editedSuccessfully={editedSuccessfully}
          setEditedSuccessfully={() => setEditedSuccessfully(false)}
        />
    }
  }

  const heading = currentStep == 2 ? actionType === 'update' ? 'Contact Information' : 'Let’s get some info about you!' : currentStep == 3 ? actionType === 'update' ? 'Location Information' : 'Where are you located' : currentStep == 4 ? actionType === 'update' ? 'Services & Products' : 'What services do you offer?' : actionType === 'update' ? 'Profile Information' : 'Welcome to Sixth Degree';

  return (
    <CreateProfileLayout onBack={() => navigation('/view-profile')} actionType={actionType}>
      <div className="top-part">
        {actionType === USER_ACTION_TYPE.TYPE_UPDATE &&
          <button onClick={() => navigation('/view-profile')} className="edit-profile-close-btn position-absolute top-0 start-100 translate-middle">
            <img src={"/assets/images/dismiss-cross-black.svg"} alt="" />
          </button>
        }
        <div className="form-steps pb-3 mb-3">
          {actionType === USER_ACTION_TYPE.TYPE_UPDATE
            ?
            <ProfileTabs
              data={supplierUpdateHeader}
              onClick={(idx) => updateUrls(idx + 1)}
              selectedIdx={currentStep - 1}
            />
            :
            <HeaderTab
              data={supplierHeader}
              onClick={(step) => {
                if (step <= currentStep) {
                  updateUrls(step)
                }
              }}
              selectedTab={currentStep}
            />
          }
        </div>
        <div className="mid-head text-center">
          <h3 className="fw-bold">{heading}</h3>
        </div>
        {renderMainContent(currentStep)}
      </div>
      <Stripe
        isShow={isShowCardModal}
        onClose={() => setIsShowCardModal(false)}
        //onDone={updateUserData}
        onSubscribe={() => setIsShow(true)}
        redirectType='subs'
      />
      <ConfirmSubscription isShow={isShow} onClose={() => setIsShow(false)} />
    </CreateProfileLayout>
  )
}
export default CreateSupplierProfile;