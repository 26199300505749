import React from 'react'

interface NoDataProps {
    message?: any;
    isLoading?: boolean;
    style?: React.CSSProperties
}

const NoData: React.FC<NoDataProps> = (props) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className="no-results-to-show d-flex align-items-center justify-content-center">
                    <div className="center-part text-center">
                        <img src="/assets/images/no-results-to-show.png" alt="" style={{ maxWidth: "253px", width: "100%" }} />
                        <h4>{props?.isLoading ? "Loading..." : props?.message || 'No results to show'}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoData