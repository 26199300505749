import Button from "../../../global/ui/Button";
import { BUTTON_TYPE, USER_ACTION_TYPE } from "../../../types";
import { buttonsText, defaultCountryOptions, errorMessage, labels, placeholder } from "../../../utils/constants";
import MobileNumber from "../../../global/ui/mobileNumber";
import InputField from '../../../lib/input';
import { useForm, FormProvider } from "react-hook-form";
import { EMAIL_VALIDATION, MOBILE_VALIDATION, NAME_VALIDATION, REQUIRED_VALIDATION } from "../../../lib/input/validation-rules";
import { ownerFormData } from "../../../data/formData";
import { apiConstants } from "../../../utils/apiConstants";
import { useEffect, useState } from "react";
import { getNumberWithIsd, getSelectedIsd, showToast, validatePhoneNumber } from "../../../utils";
import UserSnap from "../../../global/ui/UserSnap";
import VerifyPassword from '../../../components/modal/VerifyPassword';
import VerifyPhoneNumber from "../../../components/modal/VerifyMobNumber";
import VerifyUpdatedEmail from "../../../components/modal/VerifyUpdatedEmail";

interface basicInfo {
  userData: any;
  onSubmit: (value: any) => void;
  actionType?: any
  editedSuccessfully?: boolean;
  setEditedSuccessfully: () => void;
}

const BasicInfo = (props: basicInfo) => {

  const { ...methods } = useForm({
    mode: 'all',
    defaultValues: { ...ownerFormData(props.userData) },
    reValidateMode: 'onBlur',
  });

  const [countryOptions, setCountryOptions] = useState(defaultCountryOptions);
  const [password, setPassword] = useState('');
  const [showPwdPopUp, setShowPwdPopUp] = useState(false);
  const [numberVerifyPopup, setNumberVerifyPopup] = useState(false);
  const [emailVerifyPopup, setEmailVerifyPopup] = useState(false);
  const [fieldData, setFieldData] = useState({})
  const [updatedField, setUpdatedField] = useState({
    numberUpdated: false,
    emailUpdated: false
  })

  useEffect(() => {
    if (props.userData?.mobile_number) {
      const [isdCode]: any = getNumberWithIsd(props.userData?.mobile_number);
      setCountryOptions(getSelectedIsd(isdCode))
    }
  }, [props.userData?.mobile_number])
  useEffect(() => {
    methods.setValue(apiConstants.company_logo, props.userData?.owner_profile?.company_logo, { shouldValidate: true })
  }, [props?.userData])

  useEffect(() => {
    if (updatedField.numberUpdated && props.editedSuccessfully) {
      setNumberVerifyPopup(true);
    }
    if (updatedField.emailUpdated && props.editedSuccessfully) {
      setEmailVerifyPopup(true);
    }
  }, [props.editedSuccessfully])
  const verifyPwd = (data: any) => {
    const pwdData = { ...fieldData, 'password': data.password }
    onSubmit(pwdData)
    setShowPwdPopUp(false);
    setFieldData({});
    setPassword('');
  }
  const onSubmit = (values: any) => {
    props.setEditedSuccessfully();
    var apiPayload = { ...values };
    if (methods.getValues(apiConstants.company_logo) == props.userData?.owner_profile?.company_logo) {
      delete apiPayload[apiConstants.company_logo]
    }
    if (values?.[apiConstants.mobile_number]) {
      if (values?.[apiConstants.mobile_number][0] !== '+') {
        apiPayload[apiConstants.mobile_number] = `${countryOptions?.dial_code}${values?.[apiConstants.mobile_number]}`
        if (!validatePhoneNumber(apiPayload?.[apiConstants.mobile_number])) {
          showToast(errorMessage.invalid_mobile_msg, 'error', 'mobile')
          return
        }
      }
    }
    if (apiPayload[apiConstants.mobile_number] == props?.userData?.[apiConstants.mobile_number]) {
      delete apiPayload[apiConstants.mobile_number]
    }
    else if (props?.actionType === 'update' && Object.keys(fieldData).length === 0) {
      if (apiPayload[apiConstants.email] !== props?.userData?.[apiConstants.email]) {
        setUpdatedField({
          numberUpdated: true,
          emailUpdated: true
        })
      }
      else {
        setUpdatedField({
          numberUpdated: true,
          emailUpdated: false
        })
      }
      setFieldData(apiPayload as never);
      setPassword('')
      setShowPwdPopUp(true);
      return;
    }
    if (apiPayload[apiConstants.email] == props?.userData?.[apiConstants.email]) {
      delete apiPayload[apiConstants.email]
    }
    else if (props?.actionType === 'update' && Object.keys(fieldData).length === 0) {
      setFieldData(apiPayload as never);
      setUpdatedField({
        numberUpdated: false,
        emailUpdated: true
      })
      setShowPwdPopUp(true);
      return;
    }

    //const fd = generateFormData(apiPayload);
    props.onSubmit(apiPayload)
  }
  return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <div>
          <div className="text-center">
            <UserSnap
              onChange={(value) => methods.setValue(apiConstants.company_logo, value, { shouldValidate: true })}
              value={methods.getValues(apiConstants.company_logo)}
              label={labels.addLogo}
            />
          </div>
          <div className="all-form">
            {props.actionType === 'update' ?
              <InputField
                label={labels.email}
                placeholder={placeholder.email}
                name={apiConstants.email}
                type='text'
                rules={EMAIL_VALIDATION}
                className='black-input'
              />
              : ""}
            <InputField
              label={labels.companyName}
              placeholder={placeholder.companyName}
              name={apiConstants.company_name}
              type='text'
              rules={REQUIRED_VALIDATION(labels.companyName)}
              className='black-input'
              containerStyle={{ marginTop: '25px', }}
            />
            <InputField
              label={labels.contactName}
              placeholder={placeholder.contactName}
              name={apiConstants.contact_person}
              type='text'
              rules={NAME_VALIDATION(labels.contactName)}
              containerStyle={{ marginTop: '25px', }}
              className='black-input'
            />
            <MobileNumber
              onSelect={(data: any) => setCountryOptions(data)}
              selectedOption={countryOptions}
              name={apiConstants.mobile_number}
              label={labels.contactNumber}
              placeholder={placeholder.contactNumber}
              type='number'
              rules={MOBILE_VALIDATION}
              containerStyle={{ marginTop: '25px', }}
              className='black-input'
              selectLabel="Select"
            />
          </div>
        </div>
        <div className="main-button">
          <Button
            type={BUTTON_TYPE.TYPE_SUBMIT}
            btnTxt={props?.actionType === USER_ACTION_TYPE.TYPE_UPDATE ? buttonsText.saveAndUpdate : buttonsText.continue}
            className={"btn btn-warning w-100 with-after-shadow shadow-none"}
          />
        </div>
        <VerifyPassword pwd={password} show={showPwdPopUp} closePopup={() => { setShowPwdPopUp(false); props.setEditedSuccessfully() }} onSubmit={verifyPwd} />
        <VerifyPhoneNumber show={numberVerifyPopup} closePopup={() => { setNumberVerifyPopup(false); props.setEditedSuccessfully() }} />
        <VerifyUpdatedEmail show={emailVerifyPopup} closePopup={() => { setEmailVerifyPopup(false); props.setEditedSuccessfully() }} />
      </form>

    </FormProvider>
  );
};

export default BasicInfo;
