import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { BUTTON_TYPE } from '../../types'
import Button from "../../global/ui/Button";
import InputField from '../../lib/input';
import { FormProvider, useForm } from 'react-hook-form';
import { verifyPwdData } from '../../data/formData';
import { apiConstants } from '../../utils/apiConstants';
import { REQUIRED_VALIDATION } from '../../lib/input/validation-rules';
import { labels, placeholder } from '../../utils/constants';
import { useEffect, useState } from 'react';

import React from 'react';

interface VerifyPasswordProps {
    show: boolean;
    closePopup: () => void;
    onSubmit: (data: any) => void;
    pwd: string;
}
const VerifyPassword: React.FC<VerifyPasswordProps> = (props) => {

    const [isSecure, setIsSecure] = useState<any>(true);
    const { ...methods } = useForm({
        mode: 'all',
        defaultValues: { ...verifyPwdData() },
        reValidateMode: 'onBlur',
    });
    useEffect(() => {
        if (props.show) {
          methods.reset()
        }
      }, [props.show])

    return (
        <Modal
            centered
            scrollable
            className='exportdata updaterole'
            show={props.show}
            onHide={props.closePopup}>
            <ModalHeader className="p-0 border-0 mb-3">
                <button className="close-btn-de shadow-none" onClick={props.closePopup}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
                <ModalTitle className="fw-bold me-auto ps-3">Enter Password</ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-start overflow-hidden">
                <FormProvider {...methods}>
                    <p className="mb-5 text-start">Enter your password to update your personal information</p>
                    <div className='ms-5 ps-4 '>
                        <InputField
                            label={labels.password}
                            placeholder={placeholder.password}
                            name={apiConstants.password}
                            className="black-input right-icon-center"
                            type={isSecure ? 'password' : 'text'}
                            rules={REQUIRED_VALIDATION(labels.password)}
                            rightIconPress={() => setIsSecure((prvState: any) => !prvState)}
                            rightIcon={isSecure ? '/assets/images/eye-off-black.svg' : '/assets/images/eye-on-black.svg'}
                            containerStyle={{ marginTop: '25px', }}
                        />
                        <Button
                            type={BUTTON_TYPE.TYPE_BUTTON}
                            btnTxt='Continue'
                            style={{ marginTop: "0px", width: "auto" }}
                            onClick={methods.handleSubmit(props.onSubmit)}
                            className={'btn btn-warning w-100 with-after-shadow mb-3  shadow-none'}
                        />
                    </div>
                </FormProvider>
            </ModalBody>
        </Modal>
    )
}

export default VerifyPassword;
