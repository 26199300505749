import { Table } from "react-bootstrap";
import { DAYS_OF_WEEK } from "../../../data/data";
import MonthViewHeader from "./MonthViewHeader";
import { DateUtils } from "../../../utils/dateUtils";
import EventCard from "./EventCard";

interface IEventMonthViewProps {
    selectedDate: Date;
    allData: Array<any>;
    onView: (item: any) => void;
    onDaySelect: (date: Date) => void;
}

const EventMonthView = (props: IEventMonthViewProps) => {

    return (
        <div className="main-collender1" >
            <Table responsive borderless className="" cellSpacing={"0"}>
                <MonthViewHeader data={DAYS_OF_WEEK} />
                <tbody id="scrollableDiv">
                    {props?.allData?.map((monthItem: any, monthIdx: number) => {
                        return (
                            <tr key={monthIdx}>
                                {monthItem?.weeks?.map((weekItem: any, weekIdx: number) => {
                                    let totalEvents = [...weekItem?.shoot_events, ...weekItem?.events];
                                    const totalEventLength = totalEvents?.length;
                                    if (totalEventLength > 3) {
                                        totalEvents = totalEvents?.slice(0, 3);
                                    }
                                    return (
                                        <td key={weekIdx} className='top-part-collender month-view-calendar-td'>
                                            <div className='d-flex justify-content-between'>
                                                <h6 onClick={() => {
                                                    if (props?.selectedDate?.getMonth() === weekItem?.dateString?.getMonth()) props?.onDaySelect(weekItem?.dateString);
                                                }}
                                                    className="mb-0 "
                                                    style={{ fontSize: '16px', cursor: props?.selectedDate?.getMonth() === weekItem?.dateString?.getMonth() ? 'pointer' : 'default', fontWeight: '600', color: props?.selectedDate?.getMonth() === weekItem?.dateString?.getMonth() ? '#000000' : '#BDBDBD' }}
                                                >{`${DateUtils.formatDateTime(weekItem?.dateString, 'DD')}`}</h6>
                                                {totalEventLength > 3 &&
                                                    <div onClick={() => props?.onDaySelect(weekItem?.dateString)} className="evt-count">{`+${totalEventLength - 3}`}</div>
                                                }
                                            </div>
                                            {weekItem?.hasEvent &&
                                                <>
                                                    {totalEvents.map((evtItem, evtIdx) => <EventCard onView={props.onView} item={evtItem} key={evtIdx} totalEventLength={totalEventLength} />)}
                                                </>
                                            }
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    );
}

export default EventMonthView
