import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { createFullName, emptyFunction } from '../../utils';
import { DateUtils } from '../../utils/dateUtils';

interface ScheduleDetailsProps {
    isShow: boolean;
    onClose: () => void;
    scheduleDetails: any
}

const ScheduleDetails = (props: ScheduleDetailsProps) => {
    const navigate = useNavigate();
    const viewProfile = (userType: string , userId: any) => {
        if(userType === 'C'){
            navigate('/explore' , {state:{userId:userId}});
        }
        else{
            navigate('/explore' , {state:{userId:userId , detail_id:''}});
        }
    }
    return (
        <Modal
            centered
            scrollable
            className=''
            show={props.isShow}
            onHide={emptyFunction}>
            <ModalHeader className="p-0 pb-3 border-0 mb-3">
                <button className="close-btn-de shadow-none" onClick={props.onClose}><img src={"/assets/images/dismiss-cross-black.svg"} alt="" /></button>
                <ModalTitle className="fw-bold me-auto ps-3">{props?.scheduleDetails?.job_data?.job_title || ''}</ModalTitle>
            </ModalHeader>
            <ModalBody className="p-0 text-start">
                <div className='top-profile-setup d-flex gap-3 align-items-center'>
                    <img src={props.scheduleDetails?.profile_image || "/assets/images/user-icon-perple.svg"} alt="Image" className='profile-img rounded-pill' />
                    <div className='profile-cont'>
                        <h6>{createFullName(props.scheduleDetails?.first_name, props.scheduleDetails?.last_name) || ''}</h6>
                        <p className='mb-0' onClick={()=>viewProfile(props?.scheduleDetails?.user_type , props?.scheduleDetails?.id)}>View Profile <img src={"/assets/images/link-icon.svg"} alt="" className='rediect-img' /></p>
                    </div>
                </div>
                <ul className="profile-information-list pt-3 mt-3 mb-0">
                    <li className='align-items-center'>
                        <img src={"/assets/images/Calendar-empty-icon-perple.svg"} alt="" />
                        {`${DateUtils.formatDateTime(props?.scheduleDetails?.job_data?.job_start_date, 'DD MMM, YY')} - ${DateUtils.formatDateTime(props?.scheduleDetails?.job_data?.job_end_date, 'DD MMM, YY')}`}
                    </li>
                    <li className='align-items-center'>
                        <img src={"/assets/images/Location-icon-perple.svg"} alt="" />
                        {props?.scheduleDetails?.job_data?.job_location || ''}
                    </li>
                </ul>
            </ModalBody>
            {/* <ModalFooter className='border-0 p-0 pt-4 mt-3'>
                <ul className="d-flex list-unstyled gap-3 justify-content-between align-item-center w-100 m-0">
                    <li className="w-100">
                        <button className="btn btn-white w-100">Remove Schedule</button>
                    </li>
                    <li className="w-100">
                        <button className="common_btn btn btn-warning w-100 with-after-shadow shadow-none">Edit Schedule</button>
                    </li>
                </ul>
            </ModalFooter> */}
        </Modal>
    )
}

export default ScheduleDetails
