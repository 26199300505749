import {initializeApp} from "firebase/app";
import {getMessaging,onMessage,getToken} from  "firebase/messaging";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

var app = initializeApp(firebaseConfig);

const messaging = getMessaging(app)//messaging(firebase)
const publicKey = process.env.REACT_APP_VAPID_KEY;

export const setToken = async (setTokenFound:any) => {
  let currentToken = "";

  try {
    currentToken = await getToken(messaging,{ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
    
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging,(payload : any) => {
      resolve(payload);
    });
  });