
export interface IMonthViewHeaderProps {
    data: Array<any>
}

const MonthViewHeader = (props: IMonthViewHeaderProps) => {
    return (
        <thead>
            <tr>
                {props.data?.map((item, idx) => {
                    return (
                        <th key={idx} style={{ width: "12%", minWidth: "162px" }}>
                            <div className="top-part-collender d-flex justify-content-between align-items-center" >
                                <div className="d-grid ">
                                    <p className="mb-0"> {item}</p>
                                </div>
                            </div>
                        </th>
                    )
                })}
            </tr>
        </thead>
    );
}

export default MonthViewHeader
