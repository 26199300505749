import React from 'react'
import Departments from '../../global/ui/Departments';
import { createFullName, hasAddressData, renderAddressDetail } from '../../utils';
import Logo from '../../components/commonComponent/ProfileImage';

interface SupplierItemProps {
    item: any;
    onClick?: () => void
}

const SupplierItem: React.FC<SupplierItemProps> = (props) => {

    const supplierProfile = props?.item?.supplier_profile

    return (
        <div style={{ cursor: 'pointer' }} onClick={props.onClick} className="col-md-6 col-lg-6 col-xl-4">
            <div className="card explore_crew_card card-second">
                <div className="card-body d-flex align-items-start">
                    <div className='round-profile' >
                        {supplierProfile?.company_logo ?
                            <img src={supplierProfile?.company_logo || "/assets/images/user-icon-perple.svg"} className="user-img" alt="" style={{ borderRadius: '50%' }} />
                            : <Logo style={{ width: '70px', height: '70px' }} data={supplierProfile?.company_name[0]} />}
                    </div>
                    <div className="cloud-data">
                        <h4 style={{ textTransform: 'capitalize' }}>{supplierProfile?.company_name || ''}</h4>
                        {supplierProfile?.departments?.length > 0 &&
                            <Departments
                                data={supplierProfile?.departments}
                            />
                        }
                        {supplierProfile?.bio &&
                            <p className='mb-0'>{supplierProfile?.bio || ''}</p>
                        }
                        <ul className="profile-information-list mt-3">
                            {(props.item?.first_name || props.item?.last_name) &&
                                <li className="align-items-center"><img src={"/assets/images/user-icon-perple.svg"} alt="" />{createFullName(props.item?.first_name, props.item?.last_name)}</li>
                            }
                            {hasAddressData(props?.item) &&
                                <li><img src={"/assets/images/Location-icon-perple.svg"} alt="" />{renderAddressDetail(props?.item, true)}</li>
                            }
                        </ul>
                    </div>
                </div>
                <p className="play-icon"><img src={"/assets/images/play-icon-dark.svg"} alt="dropdown" /></p>
            </div>
        </div>
    )
}

export default SupplierItem