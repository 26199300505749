import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ChatRequest from '../../components/modal/ChatRequest';
import { makeGetCall, makePostCall } from '../../effects/requests';
import Tabs from '../../global/ui/Tabs';
import UserBasicInfo from '../../global/ui/UserBasicInfo';
import { EXPLORE_TYPE, REQ_STATUS, USER_PRESS_ACTION_TYPE } from '../../types';
import { createFullName, showToast } from '../../utils';
import { endPoints } from '../../utils/endpoints';
import AboutCrew from './AboutCrew';
import CrewWork from './CrewWork';
import References from './References';

interface ViewCrewDetailsProps {
    crewDetails: any;
    visible: any;
    onClose: () => void;
    onAction: (type: any) => void;
    type?: any;
    onRefView?: (value: any) => void;
    hideBtn?: boolean
}

const ViewCrewDetails: React.FC<ViewCrewDetailsProps> = (props) => {

    const crewProfile = props?.crewDetails?.crew_member_profile;
    const [showChatReqModal, setShowChatReqModal] = useState(false);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [selectedDeptIdx, setSelectedDeptIdx] = useState(null);

    const createRoom = (msg?: string, attachement_url?: string, attachement_type?: string) => {
        makePostCall(endPoints.ROOM_LIST, {
            'other_user': props?.crewDetails?.id,
            'message': msg ? msg : '',
            'attachment_url': attachement_url ? attachement_url : '',
            'attachment_type': attachement_type ? attachement_type : ''
        })
            .then((res) => {
                setShowChatReqModal(false);
                if (props?.crewDetails?.chat_room?.chat_room_created) {
                    makeGetCall(endPoints.ROOM_LIST + res?.data?.id + '/')
                        .then((response) => {
                            if (response?.data?.status === REQ_STATUS.PENDING) {
                                showToast("Chat request is pending with user", "info")
                            }
                            else if (response?.data?.status === REQ_STATUS.DELETED) {
                                showToast("User account is deleted", "error")
                            }
                            else if (response?.data?.status === REQ_STATUS.REJECTED) {
                                showToast("Chat request is rejected by user", "info")
                            }
                            else {
                                navigate('/messages/?search=&type=C', { state: { data: res?.data } })
                            }
                        })
                        .catch((err) => {

                        })

                }
                else {
                    showToast("Chat request sent successfully", "success")
                }
                setShowChatReqModal(false);
            })
            .catch((err) => {

            })

    }
    const flagUser = () => {
        const param = {
            flagged_user: props?.crewDetails?.id
        }
        makePostCall(endPoints.FLAG_USER, param)
            .then((res) => {
                if (res?.status?.code === 200) {
                    showToast(res?.status?.message, "success")
                }
                else {
                    showToast(res?.message, "error")
                }
                props?.onClose();
            })
            .catch((err) => {
                props?.onClose();
            })
    }

    const navigate = useNavigate();

    return (
        <div className={`right-side-popup-background ${props?.visible ? "" : "d-none"}`}>
            <div className="right-side-popup-fixed d-grid align-content-between gap-4">
                <div className="right-side-popup-main-top">
                    <UserBasicInfo
                        userData={{
                            name: createFullName(props.crewDetails?.first_name, props.crewDetails?.last_name),
                            linkTitle: 'IMDB Profile',
                            logo: props.crewDetails?.profile_image,
                            dep: null,
                            account_completed: props?.crewDetails?.account_completed,
                            website: crewProfile?.imdb_link
                        }}
                        onClose={() => {
                            props.onClose();
                            setSelectedIdx(0);
                            setSelectedDeptIdx(null);
                        }}
                        onAction={(type) => {
                            switch (type) {
                                case USER_PRESS_ACTION_TYPE.TYPE_MESSAGE:
                                    { props?.crewDetails?.chat_room?.chat_room_created ? createRoom() : setShowChatReqModal(true) }
                                    break;

                                case USER_PRESS_ACTION_TYPE.TYPE_WEBSITE:
                                    window.open(crewProfile?.imdb_link)
                                    break;

                                case USER_PRESS_ACTION_TYPE.TYPE_FLAG:
                                    flagUser()
                                    break;

                                default:
                                    props.onAction(type)
                                    break;
                            }
                        }}
                        showMoreMenu={props.type === EXPLORE_TYPE.MY_PROD ? true : false}
                        isActive={props?.crewDetails?.active_at_company}
                    />
                    <div className="bottom-tabs-wo">
                        <Tabs
                            data={['About', 'Work', 'References']}
                            selectedIdx={selectedIdx}
                            onClick={(item: any, idx: any) => setSelectedIdx(idx)}
                        />
                        <div className="tab-content pt-4 tab-overflow crew-tab">
                            <AboutCrew dept={crewProfile?.role} type={props?.type} data={props?.crewDetails} selectedIdx={selectedIdx} selectedDeptIdx={selectedDeptIdx} setSelectedDeptIdx={setSelectedDeptIdx}/>
                            <CrewWork name={props.crewDetails?.first_name} data={props?.crewDetails?.job_request_data?.length > 0 ? props?.crewDetails?.job_request_data : []} selectedIdx={selectedIdx} />
                            <References
                                data={props?.crewDetails?.reference_data?.length > 0 ? props?.crewDetails?.reference_data : []}
                                onClick={props?.onRefView}
                                selectedIdx={selectedIdx}
                                changeIndex={(idx: any) => setSelectedIdx(idx)}
                            />
                        </div>
                    </div>
                </div>
                {!props.hideBtn &&
                    <div className="right-side-popup-button">
                        <ul className="d-flex list-unstyled gap-3 justify-content-between align-item-center w-100 mb-0">
                            <li className="w-100">
                                <button type='button' onClick={() => navigate('/schedule')} className={`btn btn-white w-100 ${!props?.crewDetails?.my_production ? 'disabled-button' : ''}`} disabled={!props?.crewDetails?.my_production}>View Schedule</button>
                            </li>
                            <li className="w-100">
                                <button type='button' onClick={() => props.onAction(props?.type === EXPLORE_TYPE.MY_PROD ? USER_PRESS_ACTION_TYPE.TYPE_ASIGN_JOB : USER_PRESS_ACTION_TYPE.TYPE_IP)} className={`common_btn btn btn-warning w-100 with-after-shadow shadow-none ${props?.crewDetails?.my_production && props?.type !== EXPLORE_TYPE.MY_PROD ? 'disabled-button' : ''}`} disabled={props?.crewDetails?.my_production && props?.type !== EXPLORE_TYPE.MY_PROD}>{props?.type === EXPLORE_TYPE.MY_PROD ? 'Assign Job' : 'Invite to Production'}</button>
                            </li>
                        </ul>
                    </div>
                }
                <ChatRequest show={showChatReqModal} closePopup={() => setShowChatReqModal(false)} onSubmit={createRoom} />
            </div>
        </div>
    )
}

export default ViewCrewDetails