import React, { useEffect, useState } from 'react'
import { jobTab, jobTab1 } from '../../data/data';
import { makeGetCall, makeNextPageCall, makePostCall } from '../../effects/requests';
import Tabs from '../../global/ui/Tabs';
import { ApiResponseType, JOB_STATUS, USER_PRESS_ACTION_TYPE, NUMBER_STATUS } from '../../types';
import { endPoints } from '../../utils/endpoints';
import JobInfo from './JobInfo';
import RelevantCrew from './RelevantCrew';

interface ViewJobDetailProps {
    visible: any;
    onClose: () => void;
    onDone: () => void;
    jobDetails: any
    onAction: (type: any) => void
}

const ViewJobDetail: React.FC<ViewJobDetailProps> = (props) => {

    const [showMoreMenu, setShowMoreMenu] = useState(false);
    const [crewList, setCrewList] = useState([]);
    const [selectedCrew, setSelectedCrew] = useState<any>([]);
    const [jobStatus, setJobStatus] = useState<JOB_STATUS>(props.jobDetails?.completed ? 'Accepted' : 'Relevant');
    const [nextUrlList, setNextUrlList] = useState('');
    const [selectedIdx, setSelectedIdx] = useState(0);


    useEffect(() => {
        if (props.jobDetails?.id) {
            if (props.jobDetails?.completed) {
                getInvitedCrews(props.jobDetails?.id, NUMBER_STATUS.STATUS_2)
            } else {
                getCrewMem(props.jobDetails?.id);
            }
            setShowMoreMenu(false)
        }
    }, [props.jobDetails?.id]);

    const getCrewMem = (id: string) => {
        makeGetCall(endPoints.JOB_REQUEST, { job: id }).then((res: ApiResponseType<any>) => {
            setCrewList(res?.results);
            setNextUrlList(res?.next);
            setSelectedCrew([])
        })
    }

    const fetchMoreDetails = () => {
        if (!setNextUrlList) return;
        makeNextPageCall(nextUrlList).then((res: ApiResponseType<any>) => {
            const crewInfoList = [...crewList, ...res.results];
            setNextUrlList(res?.next);
            setCrewList(crewInfoList as never)
        })
    }
    const getInvitedCrews = (id: string, status: any) => {
        makeGetCall(endPoints.JOB_INVITE, { job: id, status: status }).then((res: ApiResponseType<any>) => {
            if (res?.results?.length > 0) {
                const tempData = res.results.map((item: any) => {
                    item = { ...item, ...item?.crew_member };
                    return item
                })
                setCrewList(tempData);
                setSelectedCrew([])
            } else {
                setCrewList([]);
                setSelectedCrew([])
            }
        })
    }

    const handleAssignJob = () => {
        selectedCrew.map((id: any, idx: any) => {
            const payload = {
                job: props.jobDetails?.id || '',
                crew_member: id || ''
            }
            makePostCall(endPoints.JOB_REQUEST, payload).then((res: ApiResponseType<any>) => {
                if (idx === selectedCrew?.length - 1) {
                    props?.onDone?.()
                }
            })
        })
    }

    const renderCrew = (type: JOB_STATUS) => {
        switch (type) {
            case 'Relevant':
                return <RelevantCrew
                    data={crewList}
                    selectedCrew={selectedCrew}
                    fetchMoreDetails={fetchMoreDetails}
                    nextUrlList={nextUrlList}
                    onChange={(data) => {
                        const tempCrew = [...selectedCrew];
                        const index = tempCrew.findIndex(id => id === data?.id)
                        if (index === -1) {
                            tempCrew.push(data?.id as never)
                        } else {
                            tempCrew.splice(index, 1)
                        }
                        setSelectedCrew(tempCrew)
                    }}
                />

            case 'Accepted':
            case 'Invited':
            case 'Rejected':
                return <RelevantCrew
                    data={crewList}
                    hideCheckbox
                    fetchMoreDetails={fetchMoreDetails}
                    nextUrlList={nextUrlList}
                />
        }
    }

    useEffect(() => {
        if (props.jobDetails?.completed) setJobStatus('Accepted')
        else setJobStatus('Relevant')
    }, [props.jobDetails?.completed])


    return (
        <div className={`right-side-popup-background  ${props.visible ? "" : "d-none"} `}>
            <div className={selectedCrew?.length > 0 ? "right-side-popup-fixed d-grid align-content-between gap-4 relevant-members-hegiht" : "right-side-popup-fixed d-grid align-content-between gap-4"}>
                <div className="right-side-popup-main-top mh-100">
                    <div className="popup-right-side-top">
                        <div className='cross-and-dropdonw-selct d-flex justify-content-between align-items-center mb-0'>
                            <img src={"/assets/images/dismiss-cross-black.svg"} alt="" className="img-cross-icon" onClick={() =>{props.onClose(); setSelectedIdx(0)}} />
                            {!props.jobDetails?.completed &&
                                <div className='filter-select'>
                                    <img src={"/assets/images/more-three-dots.svg"} className="main-drop-donwe" onClick={() => setShowMoreMenu((prvState) => !prvState)} alt="" />
                                    <div className={`drop-down-dtost  ${showMoreMenu ? "d-block" : "d-none"} `}>
                                        <ul className="d-grid list-unstyled pl-0 mb-0">
                                            <li onClick={() => props.onAction(USER_PRESS_ACTION_TYPE.TYPE_UPDATE)}><img src={"/assets/images/edit-pencil-black.svg"} alt="" /> Edit Job Post</li>
                                            <li onClick={() => props.onAction(USER_PRESS_ACTION_TYPE.TYPE_COMPLETE)}><img src={"/assets/images/Checkmark-Circle.svg"} alt="" /> Complete Job</li>
                                            <li onClick={() => props.onAction(USER_PRESS_ACTION_TYPE.TYPE_DELETE)}><img src={"/assets/images/Delete-icon-red.svg"} alt="" /> Remove Job</li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        <JobInfo jobDetails={props.jobDetails} />
                    </div>
                    <div className="bottom-tabs-wo">
                        <Tabs
                            data={props.jobDetails?.completed ? jobTab1 : jobTab}
                            selectedIdx={selectedIdx}
                            onClick={(value: JOB_STATUS, idx:any) => {
                                setSelectedIdx(idx)
                                setJobStatus(value)
                                if (value === 'Accepted' || value === 'Rejected' || value === 'Invited') {
                                    getInvitedCrews(props?.jobDetails?.id, value === 'Accepted' ? NUMBER_STATUS.STATUS_2 : value === 'Invited' ? NUMBER_STATUS.STATUS_1 : NUMBER_STATUS.STATUS_3)
                                } else {
                                    getCrewMem(props.jobDetails?.id);
                                }
                            }}
                        />
                        {renderCrew(jobStatus)}
                    </div>
                </div>
                {selectedCrew?.length > 0 &&
                    <div className="right-side-popup-button">
                        <ul className="d-flex list-unstyled gap-3 justify-content-between align-item-center w-100 mb-0">
                            <li className="w-100">
                                <button type='button' onClick={handleAssignJob} className="common_btn btn btn-warning w-100 with-after-shadow shadow-none">Invite</button>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewJobDetail