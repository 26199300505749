// "use strict";
import cookie from 'react-cookies';
import { cookieServiceParam } from '../utils/constants';

export const cookieService = {
    saveCookie,
    getCookie,
    removeAllCookies,
    removeCookie
};


function saveCookie(cookieName: string, value: any, options = null) {
    const cookieOptions = options ? options : {
        path: '/',
        sameSite: true,
        // secure: true // If we enable secure true then site must runs on https. otherwise it didn't work with http request.
    };
    cookie.save(cookieName, value, cookieOptions);
}

function getCookie(cookieName: string) {
    const value = cookie.load(cookieName)
    return value ? value : null
}

function removeAllCookies(options = null) {
    const cookieOptions = options ? options : {
        path: '/',
        sameSite: true,
        secure: true
    };
    const cookieItems = [cookieServiceParam.user, cookieServiceParam.token, cookieServiceParam.userType, cookieServiceParam.email, cookieServiceParam.browserToken ,cookieServiceParam.payment_data, cookieServiceParam.subscription_id, cookieServiceParam.account_completed , cookieServiceParam.refresh];

    for (let i = 0; i < cookieItems.length; i++) {
        const cookieName = cookieItems[i] + '';
        cookie.remove(cookieName, cookieOptions);
    }
    return true;
}
function removeCookie(cookieName: string){
    cookie.remove(cookieName);
}