import { prodSubsData, supplierSubsData } from "../../data/data";
import { INTERVAL_TYPE, ROLE_TYPE } from "../../types";

interface SubscriptionItemProps {
    item: any;
    selectedId?: string | null;
    roleType?: string | null;
    onClick?: (value: any) => void;
    currencyIcon?: any
    className?: any
    userType?: any
}

const SubscriptionItem = (props: SubscriptionItemProps) => {

    const descData = props?.userType === ROLE_TYPE.TYPE_SUPPLIER ? supplierSubsData : prodSubsData;

    const getPrices = (price: string) => {
        const amt = (parseInt(price)) / 100;
        const actualVal = amt / 1.2;
        const vatVal = amt - actualVal
        return [actualVal, vatVal];
    }

    const isInt = (n: any) => {
        return Number(n) === n && n % 1 === 0;
    }

    const [actualVal, vatVal] = getPrices(props.item?.unit_amount);

    const toFixedActualVal = isInt(actualVal) ? actualVal : actualVal?.toFixed(2)
    const toFixedVatVal = isInt(vatVal) ? vatVal : vatVal?.toFixed(2)

    return (
        <div onClick={() => props?.onClick?.(props.item)} className={`input-radio-select-with-radio-button `}>
            <div className={`form-check text-start ${props?.className ? props?.className : ''}`}>
                <input
                    className={`form-check-input`}
                    type="radio"
                    name='subscription'
                    id={props.item?.id}
                    checked={props.item?.id == props?.selectedId}
                    onChange={(evt) => props?.onClick?.(props.item)}
                />
                <label className="form-check-label mb-0 d-grid justify-content-between" htmlFor={props.item?.id}>
                    <div className='tesxt-work d-flex gap-3 justify-content-between'>
                        <h5 className={`${props?.className ? 'color-tab' : ''}`}>{props.item?.nickname || ""}</h5>
                        <h3 className={`${props?.className ? 'color-tab' : ''}`}>{props?.currencyIcon}{toFixedActualVal} + VAT/{props?.item?.recurring?.interval === INTERVAL_TYPE.TYPE_WEEK ? 'wk.' : 'yr.'}</h3>
                        {/* <p className={`${props?.className ? 'color-tab' : ''}`}>{props.item?.metadata?.discription || ""}</p> */}
                    </div>
                    <ul className="ps-3">
                            {descData.map((item, index) => <li key={index}>{item}</li>)}
                        </ul>
                    
                </label>
            </div>
        </div>
    )
}

export default SubscriptionItem