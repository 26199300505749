import Button from '../../global/ui/Button';
import { BUTTON_TYPE, ROLE_TYPE } from '../../types';
import { signupText, buttonsText, cookieServiceParam } from '../../utils/constants';
import { useNavigate } from "react-router-dom";
import React from 'react';
import AuthSection from '../../global/layout/authSection';
import { cookieService } from '../../services';
import { apiConstants } from '../../utils/apiConstants';

const VerifyEmail: React.FC = () => {

  const navigate = useNavigate();

  const verifyEmail = () => {
    //navigate('/subscription');
    navigate(userType === ROLE_TYPE.TYPE_SUPPLIER ? '/subscription' : '/manage-profile/owner/add/1')
  }
  const userType = cookieService.getCookie(cookieServiceParam.user)?.[apiConstants.user_type] || ROLE_TYPE.TYPE_SUPPLIER
  return (
    <AuthSection>
      <div className='inner-content text-center ms-auto'>
        <div className='top-content'>
          <img src={"/assets/images/bell-mail-icon.svg"} alt="" />
          <h3>{signupText.verify_email}</h3>
          <p>{signupText.send_verification_email}</p>
        </div>
        <form>
          <Button
            type={BUTTON_TYPE.TYPE_BUTTON}
            btnTxt={buttonsText.continue}
            style={{ marginTop: "20px", width: "100%" }}
            onClick={verifyEmail}
            className={'btn btn-warning w-100 with-after-shadow shadow-none'}
          />
        </form>
        <div className='move-login-signup' onClick={() => {
          //navigate('/subscription')
          navigate(userType === ROLE_TYPE.TYPE_SUPPLIER ? '/subscription' : '/manage-profile/owner/add/1')
        }}>
        </div>
      </div>
    </AuthSection>
  )
}
export default VerifyEmail;